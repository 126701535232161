import react from 'react';
import './Style/Style.css';
import contentimg from '../../pages/LandingPage/images/contentimg.png';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { Typography, Button, Card } from '@mui/material';
import watchvideo from '../../pages/LandingPage/images/watchvideo.png';
import vectorline from '../../pages/LandingPage/images/vectorline.png';
import takeassessment from '../../pages/LandingPage/images/takeassesment.png';
import planet from '../../pages/LandingPage/images/planet.svg';
import assignedvideo from '../../pages/LandingPage/images/assignedvideo.png';
import vectoreline2 from '../../pages/LandingPage/images/Vectorline2.svg';
import vectoreline3 from '../../pages/LandingPage/images/Vectorline3.svg';
import improvescore from '../../pages/LandingPage/images/improvescore.png';
import ourmotoborder from '../../pages/LandingPage/images/ourmotoborder.svg';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LandingAppBar from '../../common/components/LandingAppBar/LandingAppBar';
import { useNavigate } from 'react-router-dom';
import images from './images/images.jpg';
import {
  content,
  watchvideocontent,
  takeassesmentcontent,
  assignedvideos,
  improvescorecontent,
} from './constants';
import { borderRadius } from '@mui/system';
export default function Landingpage() {
  const navigate = useNavigate();
  var date = new Date();

  return (
    <div>
      <LandingAppBar />
      <div className='firstcontainerDiv'>
        <div className='containerDiv'>
          <div className='getstarted'>
            <div className='getstartedletter'>
              <p className='lettersize'>
                Enhancing Your Learning And Scoring Skills With Our Assessment Tool
              </p>

              <Button
                elevation={2}
                variant='contained'
                sx={{
                  bgcolor: '#C03CFF',
                  borderRadius: '85px',
                  ':hover': { bgcolor: '#C03CFF' },
                }}
                className='btncolor'
                onClick={() => navigate('/login')}
              >
                <div className='btnarrow'>
                  Get Started <ArrowCircleRightIcon fontSize='large' />
                </div>
              </Button>
            </div>
            <div className='conttentimgDiv'>
              <img className='contentimg' src={contentimg} />
            </div>
          </div>

          <div className='about' id='about'>
            <div className='abouts'>
              <h1 className='h1font'>About</h1>
            </div>
            <p className='aboutcontent'>{content}</p>
          </div>

          <div className='planetend' id='features'>
            <div className='planetimg'>
              <h1 className='featurehead'>Features</h1>
              <img src={planet} className='planetimage' />
            </div>
          </div>

          <div className='featurescard'>
            <Card class='card1'>
              <div className='cardmargin'>
                <div className='card1watchvideo'>
                  <img className='wathvideo' src={watchvideo} />
                </div>
                <div className='watchvideotext'>
                  <Typography class='watchevideotext1'>Watch videos</Typography>
                </div>
              </div>
              <div className='cardwatchvideostext'>{watchvideocontent}</div>
            </Card>
          </div>

          <div className='vectorlines'>
            {' '}
            <img className='vectorline' src={vectorline} />{' '}
          </div>

          <div className='featurescard'>
            <Card class='card2'>
              {' '}
              <div className='card2takeassessmenttext'>{takeassesmentcontent}</div>
              <div className='card2margin'>
                <div className='card2takeassessment'>
                  {' '}
                  <img className='takeassessment' src={takeassessment} />
                </div>
                <div className='takeassessmenttext'>
                  {' '}
                  <Typography class='takeassessmenttext1'>Take Assessment </Typography>{' '}
                </div>{' '}
              </div>{' '}
            </Card>
          </div>

          <div className='vectorlines'>
            {' '}
            <img className='vectorline' src={vectoreline2} />
          </div>

          <div className='featurescard'>
            <Card class='card1'>
              {' '}
              <div className='cardmargin'>
                {' '}
                <div className='card1watchvideo'>
                  {' '}
                  <img className='wathvideo' src={assignedvideo} />
                </div>
                <div className='watchvideotext'>
                  <Typography class='watchevideotext1'>Assigned Videos</Typography>
                </div>{' '}
              </div>
              <div className='cardwatchvideostext'>{assignedvideos}</div>{' '}
            </Card>
          </div>

          <div className='vectorlines'>
            {' '}
            <img className='vectorline' src={vectoreline3} />{' '}
          </div>

          <div className='featurescard'>
            <Card class='card2'>
              {' '}
              <div className='card2takeassessmenttext'>{improvescorecontent}</div>
              <div className='card2margin'>
                <div className='card2takeassessment'>
                  {' '}
                  <img className='takeassessment' src={improvescore} />
                </div>
                <div className='takeassessmenttext'>
                  {' '}
                  <Typography class='takeassessmenttext1'>Improve Scores </Typography>{' '}
                </div>{' '}
              </div>{' '}
            </Card>
          </div>

          <div className='ourmotoimg'>
            <h1>Our Moto</h1>
            <img className='ourmotoimgs' src={ourmotoborder} />
          </div>
          <div id='video' className='demovideohead'>
            {' '}
            <h1>Demo Video</h1>
          </div>
          <div className='playerdiv'>
            <video style={{ width: '100%' }} controls controlsList='nodownload'>
              <source
                src={'https://gillgall-mobile-app.s3.ap-south-1.amazonaws.com/Assessment+Tool.mp4'}
                className='reactPlayer'
              />
            </video>
          </div>
        </div>
      </div>
      <div className='footerholeDiv'>
        <div className='footerSecondHoleDiv'>
          <div className='socialfoot'>
            <div className='firstsocialicons'>
              <div className='socialicon'>
                <Typography class='connect'> Connect With Us </Typography>
              </div>
              <div className='socialmedia'>
                <FacebookIcon
                  className='socialicons'
                  onClick={() =>
                    window.open(
                      'https://www.facebook.com/people/Netcom-Computers-Pvt-Ltd/100083369047697/',
                    )
                  }
                />
                <InstagramIcon
                  className='socialicons'
                  onClick={() => window.open('https://www.instagram.com/netcomcomputers_official/')}
                />
                <TwitterIcon
                  className='socialicons'
                  onClick={() =>
                    window.open(
                      'https://twitter.com/i/flow/login?redirect_after_login=%2FNetcomTvl',
                    )
                  }
                />
                <LinkedInIcon
                  className='socialicons'
                  onClick={() =>
                    window.open('https://www.linkedin.com/company/netcom-computers-pvt-ltd/')
                  }
                />
              </div>
            </div>

            <div className='address1'>
              <span className='addresshead'> Head Office </span>
              <br />
              <span className='addresssize'>
                {' '}
                No. 1/1, Nathan Street, Adikalapuram, <br />
                Murugankurichi, Palayamkottai, <br />
                Tirunelveli - 627 002, <br />
                info@ncpli.com <br />
                +91 753 886 2862{' '}
              </span>
            </div>
            <div className='address2'>
              <span className='addresshead'> Branch Office </span> <br />
              <span className='addresssize'>
                {' '}
                No. 5/3, Second Floor, Kush Kumar Road,
                <br />
                Nungambakkam,
                <br />
                Chennai - 600 034. <br />
                info@ncpli.com
                <br />
                044-421 253 69{' '}
              </span>
            </div>
          </div>
        </div>
        <div className='netcomweb' style={{ background: `rgba(255,255,255,0.1)` }}>
          <Typography class='netcom'>
            {' '}
            © {date.getFullYear()} Designed & Developed by Netcom Computers Pvt. Ltd.
          </Typography>
        </div>
      </div>
    </div>
  );
}
