import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import Box from '@mui/material/Box';
function Studentswholeinformation(props) {
  const { viewSingleStudentDetail, datasendtowolestud } = props;
  console.log(datasendtowolestud);
  const [inputValue, setInputValue] = useState('');
  const [studentDetail, setStudenDetail] = useState('');

  const handleClearInput = () => {
    setInputValue('');
    setStudenDetail(datasendtowolestud);
  };
  const entervalue = (e) => {
    setInputValue(e);

    setStudenDetail(
      e === ''
        ? datasendtowolestud
        : datasendtowolestud.filter((val) =>
            val.first_name.toLowerCase().includes(e.toLowerCase()),
          ),
    );
  };
  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <TextField
        sx={{
          // width: { xs: '263px', sm: '490px' },
          width: '100%',
          marginTop: '15px',
          '& .MuiOutlinedInput-root': {
            borderRadius: '10px !important',
          },
        }}
        placeholder='Search Name'
        size='small'
        value={inputValue}
        onChange={(e) => entervalue(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment>
              {inputValue && (
                <IconButton onClick={handleClearInput}>
                  <ClearIcon />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
      />

      {datasendtowolestud &&
      (studentDetail ? studentDetail.length : datasendtowolestud.length) !== 0 ? (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          {(studentDetail ? studentDetail : datasendtowolestud).map((student, index) => (
            <Box
              key={index}
              sx={{
                border: '2px solid #9ca8d3',
                borderRadius: '10px',
                margin: '20px 0',
                display: 'flex',
                padding: { xs: '5px', sm: '10px' },
                justifyContent: 'space-between',
                // width: { xs: '250px', sm: '460px' },
                // flexDirection: { xs: 'column', sm: 'row' },
                // alignItems: { xs: 'flex-start', sm: 'center' },
                alignItems: 'center',
                width: '95%',
              }}
            >
              <Box>
                <Typography
                  sx={{
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    maxWidth: '200px',
                    display: 'flex',
                    alignItems: 'center',
                    // gap: '22px',
                    gap: { xs: '13px', sm: '22px' },
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: '400',
                      color: '#808080',
                      fontSize: { xs: '14px', sm: '15px' },
                    }}
                  >
                    Name:
                  </Typography>
                  <Typography
                    sx={{
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      // maxWidth: '200px',
                      maxWidth: { xs: '100px', sm: '200px' },
                      fontSize: { xs: '15px', sm: '16px' },
                      fontWeight: 'bold',
                    }}
                  >
                    {student.first_name}
                  </Typography>
                </Typography>
                <Typography
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    // gap: '13px'
                    gap: { xs: '5px', sm: '13px' },
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: '400',
                      color: '#808080',
                      fontSize: { xs: '14px', sm: '15px' },
                    }}
                  >
                    Roll No:
                  </Typography>
                  <Typography sx={{ fontWeight: 'bold', fontSize: { xs: '15px', sm: '16px' } }}>
                    {student.student_reg_no}
                  </Typography>
                </Typography>
                <Typography
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    // gap: '19px',
                    gap: { xs: '10px', sm: '19px' },
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: '400',
                      color: '#808080',
                      fontSize: { xs: '14px', sm: '15px' },
                    }}
                  >
                    Phone:
                  </Typography>
                  <Typography sx={{ fontWeight: 'bold', fontSize: { xs: '15px', sm: '16px' } }}>
                    {' '}
                    {student.mob_no}
                  </Typography>
                </Typography>
              </Box>
              <Button
                variant='contained'
                sx={{
                  backgroundColor: '#7b8bc4',
                  width: { xs: '5px', sm: '80px' },
                  height: { xs: '30px', sm: '35px' },
                  fontSize: { xs: '13px', sm: '16px' },
                  textTransform: 'none',
                }}
                onClick={() => viewSingleStudentDetail(student)}
              >
                View
              </Button>
            </Box>
          ))}
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            marginTop: '40px',
          }}
        >
          <SearchOffIcon sx={{ fontSize: '100px', color: '#6b4696' }} className='icone' />
          <Typography style={{ fontSize: '18px', color: '#6b4696' }}>No data Found</Typography>
        </Box>
      )}
    </Box>
  );
}

export default Studentswholeinformation;
