import { Box, Button, Paper, Stack, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ExamOverviewNavbar from './ExamOverviewNavbar/ExamOverviewNavbar';
import './style.css';
import Colors from '../../../utils/Colors';

const ExamOverview = (props) => {
  const { loading, qaSet, startExamFn, studentProfileData, setMissingExam, beforeUnloadListener } =
    props;
  const { startDateTime, subject, testName } = qaSet;
  const futureDate = new Date(startDateTime).getTime();
  const [currentTime, setCurrentTime] = useState(new Date().getTime());
  const [timeDifference, setTimeDifference] = useState(futureDate - currentTime);
  const [graceTimeStart, setGraceTimeStart] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newCurrentTime = new Date().getTime();
      const newTimeDifference = futureDate - newCurrentTime;
      setCurrentTime(newCurrentTime);
      setTimeDifference(newTimeDifference);
      if (newTimeDifference <= 0) {
        clearInterval(intervalId);
        setGraceTimeStart(true);
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [futureDate]);

  const checkFiveMinutes = () => {
    const newCurrentTime = new Date().getTime();
    const newTimeDifference = newCurrentTime - futureDate;
    return Math.floor((newTimeDifference / (1000 * 60)) % 60) > 5 ? true : false;
  };
  console.log(checkFiveMinutes());

  useEffect(() => {
    const intervalId = setInterval(() => {
      const conditionCheck = checkFiveMinutes();
      if (conditionCheck) {
        clearInterval(intervalId);
        setMissingExam(true);
        console.log('timeout');
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [graceTimeStart]);

  const seconds = Math.floor((timeDifference / 1000) % 60)
    .toString()
    .padStart(2, '0');
  const minutes = Math.floor((timeDifference / (1000 * 60)) % 60)
    .toString()
    .padStart(2, '0');
  const hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24)
    .toString()
    .padStart(2, '0');

  const filterDateTimeForUI = (val, condition) => {
    const a = new Date(val);
    const hours = a.getHours();
    const amOrPm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = (hours % 12 || 12).toString().padStart(2, '0');
    const formattedMinutes = a.getMinutes().toString().padStart(2, '0');
    if (condition === 'date') {
      return `${a.getDate().toString()}-${(a.getMonth() + 1).toString()}-${a
        .getFullYear()
        .toString()}`;
    } else {
      return `${formattedHours}:${formattedMinutes}${amOrPm}`;
    }
  };

  const examStartDateTime = [
    { title: 'Date :', value: filterDateTimeForUI(startDateTime, 'date') },
    { title: 'Time :', value: filterDateTimeForUI(startDateTime, 'time') },
  ];
  const examNameSubjectName = [
    { title: 'Subject', value: subject },
    { title: 'Assessment Topic', value: testName },
  ];

  const counterTitleValue = [
    { title: 'Hours', value: hours },
    { title: 'Minutes', value: minutes },
    { title: 'Seconds', value: seconds },
  ];

  return (
    <>
      <ExamOverviewNavbar
        studentProfileData={studentProfileData}
        beforeUnloadListener={beforeUnloadListener}
      />
      <Stack
        alignItems={'center'}
        justifyContent={'center'}
        sx={{ height: { xs: '100vh', md: '90vh' } }}
      >
        <Stack
          justifyContent={'space-evenly'}
          height={'100%'}
          sx={{ p: { xs: 2, sm: 0 }, mt: { xs: 5, lg: 10 } }}
        >
          <Stack alignItems={'flex-end'}>
            <Box>
              {examStartDateTime.map((items, index) => (
                <Stack key={index} direction={'row'} alignItems={'center'} gap={1}>
                  <Typography
                    sx={{ fontFamily: 'Poppins', fontSize: { xs: 13, sm: 15, md: 18, lg: 20 } }}
                  >
                    {items.title}
                  </Typography>
                  <Typography
                    sx={{ fontFamily: 'Poppins', fontSize: { xs: 13, sm: 15, md: 18, lg: 20 } }}
                  >
                    {items.value}
                  </Typography>
                </Stack>
              ))}
            </Box>
          </Stack>
          <Stack
            direction={'row'}
            alignItems={'center'}
            justifyContent={timeDifference < 0 ? 'space-between' : 'space-evenly'}
            gap={5}
            mt={{ xs: 0.2, lg: 0.5 }}
          >
            {examNameSubjectName.map((items, index) => (
              <Stack key={index}>
                <Typography
                  sx={{
                    fontFamily: 'Poppins',
                    color: 'grey',
                    fontSize: { xs: 13, sm: 15, md: 18, lg: 20 },
                  }}
                >
                  {items.title}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: 'Poppins',
                    fontSize: { xs: 18, sm: 20, md: 23, lg: 34 },
                    color: Colors.globalColorThemeColor,
                    fontWeight: 'bold',
                  }}
                >
                  {items.value}
                </Typography>
              </Stack>
            ))}
          </Stack>
          <Stack mt={0.5}>
            <Typography
              sx={{
                fontWeight: 'bold',
                textAlign: 'center',
                fontFamily: 'Poppins',
                fontSize: { xs: 15, sm: 20, md: 23, lg: 34 },
              }}
            >
              {timeDifference < 0
                ? 'Now, You can start the Exam'
                : 'Your Session Haven’t Started Yet, So Please Wait'}{' '}
            </Typography>
          </Stack>
          <Stack
            direction={'row'}
            gap={5}
            justifyContent={'center'}
            alignItems={'center'}
            sx={{ display: timeDifference < 0 ? 'none' : 'inherit' }}
            mt={0.5}
          >
            {counterTitleValue.map((items, index) => (
              <Box key={index}>
                <Paper
                  elevation={10}
                  sx={{
                    width: { xs: 70, sm: 90, md: 80, lg: 100 },
                    height: { xs: 70, sm: 90, md: 80, lg: 100 },
                    borderRadius: '5px',
                  }}
                >
                  <Stack alignItems={'center'} justifyContent={'center'} height={'100%'}>
                    <Typography
                      sx={{
                        fontFamily: 'Poppins',
                        fontWeight: 'bold',
                        fontSize: { xs: 16, sm: 20, md: 24 },
                        color: Colors.globalColorThemeColor,
                      }}
                    >
                      {items.value}
                    </Typography>
                  </Stack>
                </Paper>
                <Typography variant='body2' sx={{ fontFamily: 'Poppins', mt: 0.5 }}>
                  {items.title}
                </Typography>
              </Box>
            ))}
          </Stack>
          <Stack alignItems={'center'} mt={0.5}>
            <Button
              disabled={
                checkFiveMinutes() ? true : loading ? true : timeDifference < 0 ? false : true
              }
              variant='contained'
              onClick={startExamFn}
              style={{
                backgroundColor: checkFiveMinutes()
                  ? 'initial'
                  : loading
                  ? 'initial'
                  : timeDifference < 0
                  ? '#40128B'
                  : '#E0E0E0',
                borderRadius: '8px',
                width: '180px',
                height: '50px',
                color: checkFiveMinutes()
                  ? '#40128B'
                  : loading
                  ? '#40128B'
                  : timeDifference < 0
                  ? 'white'
                  : '#C4AFA8',
                border: checkFiveMinutes()
                  ? `1px solid ${Colors.globalColorThemeColor}`
                  : loading
                  ? `1px solid ${Colors.globalColorThemeColor}`
                  : timeDifference < 0
                  ? 'initial'
                  : `initial`,
              }}
            >
              {loading ? 'START TEST...' : 'START TEST'}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default ExamOverview;
