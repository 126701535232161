import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Pagination,
  PaginationItem,
  Radio,
  RadioGroup,
  Slide,
  Snackbar,
  Stack,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import '@fontsource/poppins';
import { useNavigate } from 'react-router-dom';
import Colors from '../../../utils/Colors';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import Studentdetail from './StudentProfile/StudentProfile';
import SquareIcon from '@mui/icons-material/Square';
import SquareOutlinedIcon from '@mui/icons-material/SquareOutlined';
import { ArrowForward, TimerOutlined, WidthNormal } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import GlobalLoading from '../../../utils/GlobalLoading';

function ExamSession(props) {
  const {
    shuffledData,
    setShuffledData,
    exitFullScreen,
    setEndTestDialogOpen,
    stuDetailsDialog,
    setStuDetailsDialog,
    studentProfileData,
    qaSet,
    confirmSubmissionFn,
  } = props;
  const { subject, testName, duration } = qaSet;
  const [currentIndex, setCurrentIndex] = useState(0);
  const [viewedIndex, setViewedIndex] = useState([]);
  const [answeredIndex, setAnsweredIndex] = useState([]);
  const [markedIndex, setMarkedIndex] = useState([]);
  const [correctAnswerCount, setCorrectAnswerCount] = useState(0);

  const [hours, setHours] = useState(parseInt(duration.split(':')[0]));
  const [minutes, setMinutes] = useState(parseInt(duration.split(':')[1]));
  const [seconds, setSeconds] = useState(0);
  const [remainingAlert, setRemainingAlert] = useState(false);

  const headings = [
    {
      title: 'Subject',
      content: subject,
    },
    {
      title: 'Topic',
      content: testName,
    },
    {
      title: 'Questions',
      content: shuffledData && shuffledData.length,
    },
  ];

  const matchingKey =
    shuffledData && Object.keys(shuffledData[currentIndex]).find((key) => key.startsWith('qn'));

  const answers = shuffledData && [
    shuffledData[currentIndex]['opt1'],
    shuffledData[currentIndex]['opt2'],
    shuffledData[currentIndex]['opt3'],
    shuffledData[currentIndex]['opt4'],
  ];
  console.log(answers);

  const changeAnswerValue = (e) => {
    console.log(e);
    const updateQueSet = shuffledData.map((items, index) =>
      index === currentIndex ? { ...items, selectedValue: e } : items,
    );
    console.log(updateQueSet);
    setShuffledData(updateQueSet);
    updateQueSet[currentIndex].selectedValue !== null &&
      setAnsweredIndex([...new Set([...answeredIndex, currentIndex])]);
  };

  const nextBtnFn = () => {
    if (shuffledData[currentIndex].selectedValue === null) {
      setViewedIndex([...new Set([...viewedIndex, currentIndex])]);
      setCurrentIndex(currentIndex + 1);
    } else {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const previousBtn = () => {
    if (shuffledData[currentIndex].selectedValue === null) {
      setViewedIndex([...new Set([...viewedIndex, currentIndex])]);
      setCurrentIndex(currentIndex - 1);
    } else {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const markedBtnFn = () => {
    const updateMarkedIndex = markedIndex.filter((items) => items !== currentIndex);
    markedIndex.includes(currentIndex)
      ? setMarkedIndex(updateMarkedIndex)
      : setMarkedIndex([...new Set([...markedIndex, currentIndex])]);
  };

  const clearResponseFn = () => {
    if (shuffledData[currentIndex]) {
      shuffledData[currentIndex].selectedValue = null;
    }
    setShuffledData(shuffledData);
    const updateAnsweredIndex = answeredIndex.filter((items) => items !== currentIndex);
    setAnsweredIndex(updateAnsweredIndex);
  };

  const avatarFn = (index) => {
    if (shuffledData[currentIndex].selectedValue === null) {
      setViewedIndex([...new Set([...viewedIndex, currentIndex])]);
      setCurrentIndex(index);
    } else {
      setCurrentIndex(index);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (hours <= 0 && minutes <= 0 && seconds <= 0) {
        clearInterval(interval);
        console.log('exam end');
        setRemainingAlert(false);
        confirmSubmissionFn();
      } else {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        } else {
          if (minutes > 0) {
            setRemainingAlert(minutes === 1 ? true : false);
            setMinutes(minutes - 1);
            setSeconds(59);
          } else {
            setHours(hours - 1);
            setMinutes(59);
            setSeconds(59);
          }
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [hours, minutes, seconds]);

  const testInstructionsContent = [
    'instruction 1 for the test',
    'need to attend all the questions',
    'there is no negative marks',
    'once you have done test then click submit button',
    'you can able to access the calculator',
    'and can able to access the log tables',
    'separate area given for rough works',
  ];

  const symbolForIdentify = ['Answered', 'Not-Answered', 'Marked For Review', 'Visited'];

  const tempLength = 30;
  return (
    <>
      <Stack alignItems={'center'} height={'100vh'} sx={{ overflow: 'scroll' }}>
        {shuffledData ? (
          <Box width={'98%'} height={'88vh'}>
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              gap={{ xs: 1.3, sm: 3 }}
              mt={{ xs: 0.5, lg: 2 }}
            >
              <Stack
                direction={'row'}
                width={'100%'}
                alignItems={{ xs: 'flex-start', sm: 'center' }}
                gap={{ xs: 1, sm: 3, md: 0 }}
                justifyContent={{ xs: 'space-between', sm: 'initial', md: 'space-between' }}
              >
                <Stack
                  direction={'row'}
                  alignItems={{ md: 'center' }}
                  gap={{ xs: 3, md: 5, lg: 10 }}
                >
                  {headings.map((items, index) => (
                    <Stack key={index}>
                      <Typography
                        sx={{
                          fontFamily: 'Poppins',
                          color: 'grey',
                          fontSize: { xs: 13, md: 12, lg: 18, xl: 20 },
                        }}
                      >
                        {items.title}
                      </Typography>
                      <Typography
                        sx={{
                          color: Colors.globalColorThemeColor,
                          fontFamily: 'Poppins',
                          fontSize: { xs: 15, md: 15, lg: 22, xl: 25 },
                        }}
                      >
                        {items.content}
                      </Typography>
                    </Stack>
                  ))}
                </Stack>
                <Stack>
                  <Typography
                    sx={{
                      fontFamily: 'Poppins',
                      color: 'grey',
                      fontSize: { xs: 13, md: 12, lg: 18, xl: 20 },
                    }}
                  >
                    Time Left
                  </Typography>
                  <Stack direction={'row'} alignItems={'center'}>
                    <TimerOutlined
                      sx={{
                        fontSize: { xs: 15, sm: 25, md: 22, lg: 32, xl: 35 },
                        color: remainingAlert ? 'red' : 'grey',
                      }}
                    />
                    <Typography
                      variant='h6'
                      sx={{
                        color: remainingAlert ? 'red' : '#484848',
                        fontFamily: 'Poppins',
                        fontSize: { xs: 11, md: 15, lg: 22, xl: 25 },
                        width: { xs: 45, sm: 65, lg: 110 },
                        textAlign: 'center',
                        animation: `${remainingAlert ? 'zoom 2s infinite' : 'none'}`,
                        '@keyframes zoom': {
                          '0%': {
                            transform: 'scale(1)',
                          },
                          '50%': {
                            transform: 'scale(1.1)',
                          },
                          '100%': {
                            transform: 'scale(1)',
                          },
                        },
                      }}
                    >
                      {hours.toString().padStart(2, '0')}:{minutes.toString().padStart(2, '0')}:
                      {seconds.toString().padStart(2, '0')}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
              <Stack alignItems={'flex-end'} width={{ xs: 70, sm: 280, md: 550, lg: 600, xl: 655 }}>
                <Studentdetail
                  quizmt={'55px'}
                  examSessionProps={true}
                  exitFullScreen={exitFullScreen}
                  stuDetailsDialog={stuDetailsDialog}
                  setStuDetailsDialog={setStuDetailsDialog}
                  studentProfileData={studentProfileData}
                />
              </Stack>
            </Stack>
            <Stack direction={{ md: 'row' }} gap={3} mt={{ xs: 2, lg: 2 }} height={'100%'}>
              <Stack width={'100%'}>
                <Card
                  elevation={5}
                  sx={{
                    pt: { xs: 1.5, md: 1.5, lg: 3 },
                    pb: 6,
                    pl: 2,
                    pr: 2,
                    bgcolor: '#FFF0D3',
                    border: '1px solid black',
                    borderRadius: '10px',
                  }}
                >
                  <Stack gap={2} mb={{ xs: 0, md: 3, lg: 5 }}>
                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                      <Typography
                        sx={{
                          color: 'black',
                          fontFamily: 'Poppins',
                          fontSize: { xs: 17, md: 15, lg: 22, xl: 25 },
                        }}
                      >
                        Question {currentIndex + 1}/{shuffledData.length}{' '}
                      </Typography>
                      <Button
                        variant='outlined'
                        onClick={markedBtnFn}
                        size='small'
                        startIcon={
                          markedIndex.includes(currentIndex) ? (
                            <BookmarkIcon sx={{ color: '#FFB015' }} />
                          ) : (
                            <BookmarkBorderIcon />
                          )
                        }
                        sx={{
                          borderColor: 'black',
                          textTransform: 'capitalize',
                          color: 'black',
                          ':hover': { borderColor: 'black' },
                          fontSize: { xs: 10, md: 10, lg: 15 },
                        }}
                      >
                        Mark for review
                      </Button>
                    </Stack>
                    <Typography
                      sx={{
                        color: '#424242',
                        fontFamily: 'Poppins',
                        fontSize: { xs: 15, md: 13, lg: 18, xl: 20 },
                      }}
                    >
                      {shuffledData[currentIndex][matchingKey]}
                    </Typography>
                  </Stack>
                </Card>

                <Card
                  elevation={5}
                  sx={{
                    height: '100%',
                    p: { xs: 1.5, lg: 3 },
                    mt: { xs: 2, md: 1.5, lg: 3 },
                    bgcolor: '#FFF0D3',
                    border: '1px solid black',
                    borderRadius: '10px',
                  }}
                >
                  <Stack justifyContent={'space-between'} sx={{ height: '100%' }}>
                    <Box>
                      <Stack
                        direction={'row'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                      >
                        <Typography variant='h6' sx={{ fontFamily: 'Poppins', fontWeight: 'bold' }}>
                          Select the option
                        </Typography>
                        <Button
                          disableFocusRipple
                          disableRipple
                          disableElevation
                          onClick={clearResponseFn}
                          startIcon={<WidthNormal />}
                          sx={{
                            textTransform: 'capitalize',
                            fontSize: { xs: 10, lg: 12, xl: 15 },
                            color: Colors.globalColorThemeColor,
                            textDecoration: 'underline',
                            ':hover': {
                              textDecoration: 'underline',
                            },
                          }}
                        >
                          Clear Response
                        </Button>
                      </Stack>
                      <FormControl>
                        <RadioGroup
                          aria-labelledby='demo-radio-buttons-group-label'
                          name='radio-buttons-group'
                          value={shuffledData[currentIndex].selectedValue - 1}
                          onChange={(e) => changeAnswerValue(parseInt(e.target.value) + 1)}
                        >
                          {answers.map((items, index) => (
                            <FormControlLabel
                              sx={{ mt: { xs: 1, md: 2 } }}
                              key={index}
                              value={index}
                              control={
                                <Radio
                                  size='small'
                                  sx={{
                                    '& .MuiSvgIcon-root': {
                                      fontSize: { xs: 15, md: 13, lg: 18, xl: 20 },
                                    },
                                  }}
                                />
                              }
                              label={
                                <Typography
                                  sx={{
                                    fontSize: { xs: 15, md: 13, lg: 18, xl: 20 },
                                    fontFamily: 'Poppins',
                                    mt: { xs: 0, lg: -0.5 },
                                  }}
                                >
                                  {items}
                                </Typography>
                              }
                            />
                          ))}
                        </RadioGroup>
                      </FormControl>
                    </Box>
                    <Stack
                      direction={'row'}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                      gap={5}
                      mt={{ xs: 2, lg: 3 }}
                    >
                      <Button
                        variant='contained'
                        startIcon={<ArrowBackIcon />}
                        onClick={previousBtn}
                        disabled={currentIndex === 0 ? true : false}
                        sx={{
                          textTransform: 'capitalize',
                          bgcolor: Colors.globalColorThemeColor,
                          width: { xs: 130, md: 155 },
                          fontSize: { xs: 10, lg: 12, xl: 15 },
                          borderRadius: '5px',
                          ':hover': { bgcolor: Colors.globalColorThemeColor },
                        }}
                      >
                        Previous
                      </Button>
                      <Button
                        variant='contained'
                        endIcon={<ArrowForward />}
                        disabled={shuffledData.length === currentIndex + 1 ? true : false}
                        onClick={nextBtnFn}
                        sx={{
                          textTransform: 'capitalize',
                          display: shuffledData.length === currentIndex + 1 ? 'none' : 'inherit',
                          width: { xs: 130, md: 155 },
                          fontSize: { xs: 10, lg: 12, xl: 15 },
                          bgcolor: Colors.globalColorThemeColor,
                          borderRadius: '5px',
                          ':hover': { bgcolor: Colors.globalColorThemeColor },
                        }}
                      >
                        Save & Next
                      </Button>
                    </Stack>
                  </Stack>
                </Card>
              </Stack>

              <Stack
                width={{ xs: '100%', md: 550, lg: 600, xl: 655 }}
                alignItems={{ xs: 'center', md: 'flex-end' }}
              >
                <Card
                  elevation={10}
                  sx={{
                    border: '1px solid black',
                    borderRadius: '10px',
                    height: '100%',
                    width: { xs: '100%', md: '100%' },
                  }}
                >
                  <Stack justifyContent={'space-between'} alignItems={'center'} height={'100%'}>
                    <Box width={'95%'}>
                      <Stack
                        direction={'row'}
                        flexWrap={'wrap'}
                        alignItems={'center'}
                        gap={{ xs: 1, md: 1, lg: 1.2 }}
                        mt={{ xs: 1, md: 0.5, lg: 1 }}
                        height={'100px'}
                        sx={{
                          overflowY: 'scroll',
                          overflowX: 'hidden',
                          '&::-webkit-scrollbar': {
                            width: '2px',
                          },
                          '&::-webkit-scrollbar-thumb': {
                            background: '#888',
                            borderRadius: '2px',
                          },
                        }}
                      >
                        {[...Array(shuffledData.length)].map((_, index) => (
                          <Avatar
                            key={index}
                            onClick={() => avatarFn(index)}
                            sx={{
                              // borderRadius: index === 1 ? 'initial' : '50px',
                              // borderRadius: markedIndex.includes(index)
                              //   ? '50px'
                              //   : answeredIndex.includes(index)
                              //   ? '50px'
                              //   : viewedIndex.includes(index)
                              //   ? 'initial'
                              //   : '50px',
                              cursor: 'pointer',
                              bgcolor:
                                currentIndex === index
                                  ? 'blue'
                                  : markedIndex.includes(index)
                                  ? '#FFB015'
                                  : answeredIndex.includes(index)
                                  ? 'green'
                                  : 'white',
                              width: { xs: 25, md: 20, xl: 30 },
                              height: { xs: 25, md: 20, xl: 30 },
                              fontSize: { xs: 12, md: 11, lg: 13 },
                              border: '1px solid black',
                              color:
                                currentIndex === index
                                  ? 'white'
                                  : markedIndex.includes(index)
                                  ? 'white'
                                  : answeredIndex.includes(index)
                                  ? 'white'
                                  : 'black',
                              fontFamily: 'Poppins',
                              mt: { md: 0.3, lg: 1 },
                            }}
                          >
                            {index + 1}
                          </Avatar>
                        ))}
                      </Stack>
                      <Divider sx={{ mt: { xs: 1, md: 0.5, lg: 1 }, bgcolor: '#a6a6a6' }} />
                      <Stack mt={{ xs: 1, md: 0.5, lg: 1 }}>
                        <Typography
                          sx={{
                            fontFamily: 'Poppins',
                            fontWeight: 'bold',
                            fontSize: { md: 12, lg: 15, xl: 20 },
                          }}
                        >
                          Test Instructions
                        </Typography>
                        {testInstructionsContent.map((items, index) => (
                          <Typography
                            key={items}
                            sx={{
                              mt: { xs: 1, md: 0.5, lg: 1.2 },
                              fontFamily: 'Poppins',
                              fontSize: { md: 10, lg: 12, xl: 15 },
                            }}
                          >
                            {index + 1 + '.' + ' ' + items}
                          </Typography>
                        ))}
                      </Stack>

                      <Stack>
                        <Box>
                          {symbolForIdentify.map((items, index) => (
                            <Stack
                              key={items}
                              direction={'row'}
                              alignItems={'center'}
                              gap={2}
                              mt={{ xs: 1, lg: 2 }}
                            >
                              <SquareIcon
                                sx={{
                                  color: index === 0 ? 'green' : index === 2 ? '#FFB015' : 'blue',
                                  borderColor: 'black',
                                  display: index !== 1 ? 'initial' : 'none',
                                  fontSize: { md: 15, lg: 20, xl: 18 },
                                }}
                              />
                              <SquareOutlinedIcon
                                sx={{
                                  display: index !== 1 ? 'none' : 'initial',
                                  fontSize: { md: 15, lg: 20, xl: 18 },
                                }}
                              />
                              <Typography
                                sx={{ fontFamily: 'Poppins', fontSize: { md: 10, lg: 12, xl: 15 } }}
                              >
                                {items}
                              </Typography>
                            </Stack>
                          ))}
                        </Box>
                      </Stack>
                    </Box>

                    <Button
                      variant='contained'
                      onClick={() => {
                        exitFullScreen();
                        setEndTestDialogOpen(true);
                      }}
                      sx={{
                        mt: { xs: 2, lg: 3 },
                        mb: { xs: 2, lg: 3 },
                        height: { xs: 'initial' },
                        width: '95%',
                        borderRadius: '10px',
                        bgcolor: '#FFB015',
                        ':hover': { bgcolor: '#FFB015' },
                      }}
                    >
                      Submit
                    </Button>
                  </Stack>
                </Card>
              </Stack>
            </Stack>
          </Box>
        ) : (
          <GlobalLoading />
        )}
      </Stack>
    </>
  );
}

export default ExamSession;
