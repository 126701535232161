import { createSlice } from '@reduxjs/toolkit';
export const studentDataSlice = createSlice({
  name: 'studentDatas',
  initialState: {
    username: '',
  },
  reducers: {
    changeUsername: (state, action) => {
      state.username = action.payload;
    },
  },
});

export const { changeUsername } = studentDataSlice.actions;
export const CurrentUserName = (state) => state.studentDatas.username;
export default studentDataSlice.reducer;
