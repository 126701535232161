import React, { useEffect, useState } from 'react';
import './upcomingstyles.css';
import { useDispatch, useSelector } from 'react-redux';
import Edit from '../Images/Vectorrename.png';
import Delete from '../Images/Vectordelt.png';
import { Button, useSelect } from '@mui/base';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Colors from '../../../../utils/Colors';
import { UpcmoingAssessmentData } from '../../constants';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { Pagination } from '@mui/material';
import logo from '../../../NavbarofTeachersPage/img/logo.png';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import CalendarTodayRoundedIcon from '@mui/icons-material/CalendarTodayRounded';
import { Stack, Typography } from '@mui/material';
import CircleLoader from 'react-spinners/CircleLoader';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import {
  APIClient,
  RefreshToken,
  RefreshTokenSwalAlert,
  RemoveAccessToken,
} from '../../../../utils/APIClient';
import Swal from 'sweetalert2';

function Upcomingtest(props) {
  const navigate = useNavigate();
  const {
    loading,
    examDatas,
    getData,
    currentPage,
    setCurrentPage,
    getProfileDataFromApi,
    setLoading,
    setDateFilterValue,
    setSearchValue,
    teacherProfileData,
    sentDataToUploadPage,
  } = props;
  console.log('doubt', examDatas);
  console.log('doubt1', loading);
  console.log('ash', UpcmoingAssessmentData);
  const recordsPerPage = 10;
  const lastIndex = currentPage * recordsPerPage;
  console.log(lastIndex);
  const firstIndex = lastIndex - recordsPerPage;
  console.log(firstIndex);
  const records = getData.slice(firstIndex, lastIndex);
  console.log(records);
  const npage = Math.ceil(getData.length / recordsPerPage);

  function changeCPage(id) {
    setCurrentPage(id);
  }

  const filterDate = (val) => {
    const a = new Date(val);
    const hours = a.getHours();
    const amOrPm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = (hours % 12 || 12).toString().padStart(2, '0');
    const formattedMinutes = a.getMinutes().toString().padStart(2, '0');
    const final = `${a.getDate().toString().padStart(2, '0')}-${(a.getMonth() + 1).toString()}-${a
      .getFullYear()
      .toString()} ${formattedHours}:${formattedMinutes}${amOrPm}`;

    return final;
  };

  const deleteUpcomingfn = async (queID) => {
    setLoading(true);
    try {
      console.log(queID);
      const res = await axios.delete(`${APIClient().API_BASE_URL}/api/deleteQuestionAnswer`, {
        headers: APIClient().API_HEADERS.headers,
        data: { questionSetID: queID },
      });
      console.log(res);
      if (res.data.success) {
        getProfileDataFromApi();
      } else {
        setLoading(false);
        enqueueSnackbar('Try again later', { variant: 'error' });
      }
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        const newTokens = await RefreshToken();
        console.log(newTokens);
        if (newTokens.success) {
          localStorage.setItem('accessToken', newTokens.newAccessToken);
          deleteUpcomingfn(queID);
        } else {
          RefreshTokenSwalAlert(newTokens.error, navigate);
        }
      } else {
        RefreshTokenSwalAlert('catchError', navigate);
      }
    }
  };
  return (
    <div className='upCommingTest'>
      {loading ? (
        <div className='Upcommingloading'>
          <Typography
            variant='h6'
            sx={{
              fontFamily: 'Poppins',
              fontWeight: 'bold',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <CircleLoader color='#6b4696' cssOverride={{}} size={99} speedMultiplier={1} />
            Loading ...
          </Typography>
        </div>
      ) : examDatas.length !== 0 ? (
        <>
          {getData.length !== 0 ? (
            <>
              <table className='tableDiv'>
                <thead>
                  <tr>
                    <th
                      className='tableHeader'
                      style={{ backgroundColor: Colors.globalColorThemeColor }}
                    >
                      Test Name
                    </th>
                    <th
                      className='tableHeader1'
                      style={{ backgroundColor: Colors.globalColorThemeColor }}
                    >
                      No.Of Questions
                    </th>
                    <th
                      className='tableHeader2'
                      style={{ backgroundColor: Colors.globalColorThemeColor }}
                    >
                      Schedule
                    </th>
                    <th
                      className='fourthtableHeader'
                      style={{ backgroundColor: Colors.globalColorThemeColor }}
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {records
                    .reverse()
                    .slice(0, 10)
                    .map((row, index) => (
                      <tr key={index}>
                        <td className='tableData'> {row.testName}</td>
                        <td className='tableData'>{row.questionsCount}</td>
                        <td className='tableData'> {filterDate(row.startDateTime)}</td>
                        <td className='tableDatas'>
                          <div
                            className='editBtns'
                            onClick={() =>
                              navigate('/question-upload', {
                                state: {
                                  conditionCheck: 'edit',
                                  questionSetID: row.questionSetID,
                                  teacherProfileData: teacherProfileData,
                                  ...sentDataToUploadPage,
                                },
                              })
                            }
                          >
                            <BorderColorIcon className='editBtnColor' />
                            <span className='editandDelete'> Edit</span>
                          </div>
                          <div
                            className='deleteBtn'
                            onClick={() => deleteUpcomingfn(row.questionSetID)}
                          >
                            <DeleteOutlineOutlinedIcon className='deleteBtnColor' />
                            <span className='editandDelete'>Delete </span>
                          </div>
                          <div
                            className='viewBtn'
                            onClick={() =>
                              navigate('/question-upload', {
                                state: {
                                  conditionCheck: 'view',
                                  questionSetID: row.questionSetID,
                                  teacherProfileData: teacherProfileData,
                                  ...sentDataToUploadPage,
                                },
                              })
                            }
                          >
                            <RemoveRedEyeIcon className='viewBtnColor' />
                            <span className='editandDelete'>View</span>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <Pagination count={npage} page={currentPage} onChange={(e, i) => changeCPage(i)} />
            </>
          ) : (
            <div className='noDataFound'>
              <SearchOffIcon sx={{ fontSize: '150px', color: '#6b4696' }} className='icone' />
              <span className='dataFoundLetter'>No data Found</span>
            </div>
          )}
        </>
      ) : (
        <div className='Upcommingloading'>
          <Typography
            variant='h6'
            sx={{
              fontFamily: 'Poppins',
              fontWeight: 'bold',
            }}
          >
            No UpComing Exams ...
          </Typography>
        </div>
      )}
    </div>
  );
}
export default Upcomingtest;
