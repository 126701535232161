import {
  Avatar,
  Box,
  Button,
  Card,
  Dialog,
  IconButton,
  MenuItem,
  Skeleton,
  Slide,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import NavbarofTeachersPage from '../NavbarofTeachersPage';
import SearchIcon from '@mui/icons-material/Search';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import ExportLogo from './img/exportlogo.svg';
import ViewLogo from './img/viewlogo.svg';
import '@fontsource/poppins';
import Upcoming from './components/Upcomingtest';
import History from './components/History';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useNavigate } from 'react-router-dom';
import Colors from '../../utils/Colors';
import { RecentlyCompletedData, examHistroyData } from './constants';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import './teacherDashboardStyle.css';
import UploadIcon from '@mui/icons-material/Upload';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import {
  changeTeacherProfileData,
  CurrentTeacherProfileData,
} from '../../reduxStore/teacherProfileDataSlice';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import logo from '../NavbarofTeachersPage/img/logo.png';
import GirlIcon from '@mui/icons-material/Girl';
import BoyIcon from '@mui/icons-material/Boy';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Selectstudentinformation from './components/StudentsInformation/Selectstudentinformation';
import Studentswholeinformation from './components/StudentsInformation/Studentswholeinformation';
import Active from './components/Active/Active';
import {
  APIClient,
  RefreshToken,
  RemoveAccessToken,
  RefreshTokenSwalAlert,
} from '../../utils/APIClient';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />;
});

function TeachersDashboard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const teacherProfileData = useSelector(CurrentTeacherProfileData);
  const [examDatas, setExamDatas] = useState('');
  const [getData, setGetData] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const today = dayjs();
  const threeMonthsFromNow = today.add(3, 'month').toDate();
  const filterDates = (date) => date >= threeMonthsFromNow;

  const [title, setTitle] = useState('Upcoming Assessments');

  const [createTestDialog, setCreateTestDialog] = useState(false);
  const [dateFilterValue, setDateFilterValue] = useState('');

  const [loading, setLoading] = useState(true);
  const [currentSelectionofClass, setCurrentSelectionofClass] = useState(0);
  const [currentSelectionofSubject, setCurrentSelectionofSubject] = useState(0);
  const [currentSelectionofMedium, setCurrentSelectionofMedium] = useState(0);
  const [currentSelectionofSection, setCurrentSelectionofSection] = useState(0);
  const [filterDateValue, setFilterDateValue] = useState(0);
  const [studentsDialogBoxOpen, setStudentsDialogBoxOpen] = useState(false);
  const [studentViewData, setStudentViewData] = useState(false);
  const [studentDetails, setStudentDetails] = useState();
  const [loadingStudentCounts, setLoadingStudentCounts] = useState(true);
  const [male, setMale] = useState();
  const [female, setFemale] = useState();
  const [searchValue, setSearchValue] = useState('');
  const [singleStudentDetails, setSingleStudentDetails] = useState('');

  const filterClass = teacherProfileData && [
    ...new Set(teacherProfileData.classDetails.map((e) => e.class)),
  ];

  const filterMedium = teacherProfileData && [
    ...new Set(
      teacherProfileData.classDetails
        .filter((e) => e.class === filterClass[currentSelectionofClass])
        .map((e) => e.medium),
    ),
  ];

  const filterSection = teacherProfileData && [
    ...new Set(
      teacherProfileData.classDetails
        .filter(
          (e) =>
            e.class === filterClass[currentSelectionofClass] &&
            e.medium === filterMedium[currentSelectionofMedium],
        )
        .map((e) => e.section),
    ),
  ];

  const filterSubjects =
    teacherProfileData &&
    teacherProfileData.classDetails
      .filter(
        (e) =>
          e.class === filterClass[currentSelectionofClass] &&
          e.medium === filterMedium[currentSelectionofMedium] &&
          e.section === filterSection[currentSelectionofSection],
      )
      .map((sub) => sub.subjects[0].name);

  const filterSubjectsID =
    teacherProfileData &&
    teacherProfileData.classDetails
      .filter(
        (e) =>
          e.class === filterClass[currentSelectionofClass] &&
          e.medium === filterMedium[currentSelectionofMedium] &&
          e.section === filterSection[currentSelectionofSection],
      )
      .map((sub) => sub.subjects[0].id);

  const sentDataToUploadPage = {
    class: filterClass[currentSelectionofClass],
    medium: filterMedium[currentSelectionofMedium],
    section: filterSection[currentSelectionofSection],
    subject: filterSubjects[currentSelectionofSubject],
    subjectID: filterSubjectsID[currentSelectionofSubject],
    teacherID: teacherProfileData.teacherID,
    teacherProfileData: teacherProfileData,
  };

  console.log(sentDataToUploadPage);

  const createNewTestFn = () => {
    navigate('/question-upload', {
      state: {
        ...sentDataToUploadPage,
        conditionCheck: 'create new exam',
      },
    });
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };
  const FIlter = (val) => {
    setSearchValue(val);
    if (dateFilterValue) {
      setGetData(
        val === ''
          ? filterDateValue
          : filterDateValue.filter((e) => e.testName.toLowerCase().includes(val.toLowerCase())),
      );
    } else {
      if (val === '') {
        setGetData(examDatas);
        setCurrentPage(1);
      } else {
        setCurrentPage(1);
        setGetData(examDatas.filter((e) => e.testName.toLowerCase().includes(val.toLowerCase())));
      }
    }
  };
  const selectClassFn = (index) => {
    if (index === currentSelectionofClass) {
      return;
    }
    setLoading(true);
    setCurrentSelectionofClass(index);

    setCurrentSelectionofMedium(0);
    setCurrentSelectionofSection(0);
    setCurrentSelectionofSubject(0);
  };

  const getProfileDataFromApi = async () => {
    try {
      const res = await axios.get(
        `${APIClient().API_BASE_URL}/api/getProfileData`,
        APIClient().API_HEADERS,
      );
      console.log(res);
      if (res.data.success) {
        dispatch(changeTeacherProfileData(res.data.profile));
        const dateToSendBackEnd = {
          status:
            title === 'Upcoming Assessments'
              ? 'upcoming'
              : title === 'Active Assessments'
              ? 'activeExams'
              : 'history',
          teacherID: res.data.profile.teacherID,
          section: filterSection
            ? filterSection[currentSelectionofSection]
            : res.data.profile.classDetails[currentSelectionofClass].section,
          subjectID: filterSubjectsID
            ? filterSubjectsID[currentSelectionofSubject]
            : res.data.profile.classDetails[currentSelectionofClass].subjects[
                currentSelectionofSubject
              ].id,
        };
        console.log(dateToSendBackEnd);
        const responseGetTeacherQuestionSet = await axios.post(
          `${APIClient().API_BASE_URL}/api/getTeacherQuestionSet`,
          dateToSendBackEnd,
        );

        console.log(responseGetTeacherQuestionSet);
        if (responseGetTeacherQuestionSet.data.success) {
          setGetData(responseGetTeacherQuestionSet.data.data);
          setExamDatas(responseGetTeacherQuestionSet.data.data);
        } else {
          setLoading(false);
          setGetData([]);
          setExamDatas([]);
        }

        let dataToSend = {
          class: filterClass
            ? parseInt(filterClass[currentSelectionofClass])
            : parseInt(res.data.profile.classDetails[currentSelectionofClass].class),
          medium: filterMedium
            ? filterMedium[currentSelectionofMedium].toLowerCase()
            : res.data.profile.classDetails[currentSelectionofMedium].medium.toLowerCase(),
          section: filterSection
            ? filterSection[currentSelectionofSection]
            : res.data.profile.classDetails[currentSelectionofSection].section,
        };

        const resGetStudentList = await axios.post(
          `${APIClient().API_BASE_URL}/api/getStudentList`,
          dataToSend,
          APIClient().API_HEADERS,
        );

        if (resGetStudentList.data.success) {
          let male = 0;
          let female = 0;
          resGetStudentList.data.message.map((e) => {
            if (e.gender.startsWith('m')) {
              male += 1;
            } else {
              female += 1;
            }
          });
          setMale(male);
          setFemale(female);
          setStudentDetails(resGetStudentList.data.message);
          setLoadingStudentCounts(false);
        }
      } else {
        Swal.fire({
          title: res.data.error,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        }).then(() => {
          RemoveAccessToken();
          navigate('/login');
        });
      }
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        const newTokens = await RefreshToken();
        console.log(newTokens);
        if (newTokens.success) {
          localStorage.setItem('accessToken', newTokens.newAccessToken);
          getProfileDataFromApi();
        } else {
          RefreshTokenSwalAlert(newTokens.error, navigate);
        }
      } else {
        RefreshTokenSwalAlert('catchError', navigate);
      }
    }
  };

  const changeData = (e) => {
    setDateFilterValue(e);
    let date = new Date(e);
    let getDate = date.getDate().toString().padStart(2, 0);
    let month = (date.getMonth() + 1).toString();
    let year = date.getFullYear().toString();
    let showDate = getDate + -+month + -+year;

    let filterDate = examDatas.filter((e) => {
      let splitDate = e.startDateTime.split('T')[0].split('-');
      let condate = `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`;

      if (condate == showDate) {
        return e;
      } else {
        console.log('No Exam');
      }
    });
    if (filterDate.length <= 0) {
      setGetData(filterDate);
    } else {
      if (searchValue) {
        setCurrentPage(1);
        setGetData(
          filterDate.filter((e) => e.testName.toLowerCase().includes(searchValue.toLowerCase())),
        );
      } else {
        setGetData(filterDate);
      }
    }
    setFilterDateValue(filterDate);
  };

  const dateClear = () => {
    setDateFilterValue('');
    if (searchValue === '') {
      setGetData(examDatas);
      setCurrentPage(1);
    } else {
      setCurrentPage(1);

      setGetData(
        examDatas.filter((e) => e.testName.toLowerCase().includes(searchValue.toLowerCase())),
      );
    }
  };

  useEffect(() => {
    if (examDatas === '') {
      setLoading(true);
    } else {
      if (searchValue !== '' && dateFilterValue !== '') {
        changeData(dateFilterValue);
      } else if (searchValue !== '') {
        FIlter(searchValue);
      } else if (dateFilterValue !== '') {
        changeData(dateFilterValue);
      }
      setLoading(false);
    }
  }, [examDatas]);

  useEffect(() => {
    setLoading(true);
    getProfileDataFromApi();
  }, [
    currentSelectionofClass,
    currentSelectionofMedium,
    currentSelectionofSection,
    currentSelectionofSubject,
    title,
  ]);

  useEffect(() => {
    setLoadingStudentCounts(true);
  }, [
    currentSelectionofClass,
    currentSelectionofMedium,
    currentSelectionofSection,
    currentSelectionofSubject,
  ]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (title === 'Active Assessments') {
        getProfileDataFromApi();
      } else {
        clearInterval(intervalId);
      }
    }, 10000);
    return () => {
      clearInterval(intervalId);
    };
  }, [title]);

  const handleClickDialogBoxOpen = () => {
    setStudentsDialogBoxOpen(true);
  };

  const handleDialogBoxClose = () => {
    setStudentsDialogBoxOpen(false);
    setStudentViewData(false);
  };
  const viewSingleStudentDetail = (val) => {
    setSingleStudentDetails(val);
    setStudentViewData(true);
  };
  const backtoWholeStudentDetails = () => {
    setStudentViewData(false);
  };

  const theme = createTheme({
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: 'white',
          },
          arrow: {
            color: 'white',
            marginLeft: '-38px',
          },
        },
      },
    },
  });
  return (
    <>
      <Stack
        alignItems={'center'}
        justifyContent={'center'}
        mt={1}
        sx={{
          overflow: 'hidden',
        }}
      >
        {teacherProfileData ? (
          <Box width={{ xs: '99%', sm: '98%', lg: '98%' }}>
            <NavbarofTeachersPage />
            <Stack mt={3}>
              <Stack>
                <Typography
                  sx={{
                    textAlign: 'start',
                    mb: 2,
                    fontWeight: 'bold',
                    fontFamily: 'Poppins',
                    fontSize: { xs: 20 },
                  }}
                >
                  Recent Test Result
                </Typography>

                <Box sx={{ display: { xs: 'none', sm: 'initial' } }}>
                  <Slider {...settings}>
                    {RecentlyCompletedData.map((data, index) => (
                      <div key={index}>
                        <Card
                          elevation={3}
                          sx={{
                            p: { xs: 1, sm: 2, md: 2, lg: 2 },
                            borderRadius: '25px',
                            background:
                              'linear-gradient(90deg, rgba(79,50,113,1) 44%, rgba(39,123,140,1) 100%)',
                          }}
                        >
                          <Stack direction={'row'} justifyContent={'space-between'}>
                            <Stack justifyContent={'space-between'}>
                              <Stack direction={'row'} gap={1} justifyContent={'space-around'}>
                                {data.testDetails.map((items, index) => (
                                  <Stack key={index}>
                                    <Typography
                                      sx={{
                                        color: 'white',
                                        fontFamily: 'Poppins',
                                        opacity: 0.5,
                                        fontSize: { xs: 10, sm: 12, md: 18, lg: 20 },
                                      }}
                                    >
                                      {(index === 0 || index === 4) && items.title}
                                    </Typography>
                                    <Typography
                                      sx={{
                                        color: 'white',
                                        fontFamily: 'Poppins',
                                        fontWeight: 'bold',
                                        fontSize: { xs: 10, sm: 12, md: 18, lg: 20 },
                                      }}
                                    >
                                      {(index === 0 || index === 4) && items.content}
                                    </Typography>
                                  </Stack>
                                ))}
                              </Stack>
                              {data.testDetails.slice(3, 4).map((items, index) => (
                                <Stack key={index}>
                                  <Typography
                                    sx={{
                                      color: 'white',
                                      fontFamily: 'Poppins',
                                      opacity: 0.5,
                                      fontSize: { xs: 10, sm: 12, md: 18, lg: 20 },
                                    }}
                                  >
                                    {items.title}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      color: 'white',
                                      fontFamily: 'Poppins',
                                      fontWeight: 'bold',
                                      fontSize: { xs: 10, sm: 12, md: 18, lg: 20 },
                                    }}
                                  >
                                    {items.content} Stanard
                                  </Typography>
                                </Stack>
                              ))}
                            </Stack>
                            <Stack
                              direction={'row'}
                              justifyContent={'space-between'}
                              alignItems={'center'}
                              gap={{ xs: 1, sm: 2, md: 6 }}
                            >
                              {data.studentRankings.map((items, index) => (
                                <Stack alignItems={'center'} key={index}>
                                  <Avatar
                                    sx={{ width: { sm: 35, md: 60 }, height: { sm: 35, md: 60 } }}
                                  >
                                    {items.name.charAt(0).toLocaleUpperCase()}
                                  </Avatar>
                                  <Typography
                                    sx={{
                                      fontFamily: 'Poppins',
                                      color: 'white',
                                      mt: 0.5,
                                      fontSize: { xs: 10, sm: 12, md: 18, lg: 20 },
                                    }}
                                  >
                                    {items.name}
                                  </Typography>
                                  <Box
                                    component={'img'}
                                    src={items.logo}
                                    sx={{
                                      width: { xs: 40, sm: 50, md: 68, lg: 78 },
                                      height: { xs: 35, sm: 50, md: 68, lg: 78 },
                                    }}
                                  />
                                </Stack>
                              ))}
                            </Stack>
                            <Stack justifyContent={'space-between'}>
                              <Stack
                                direction={'row'}
                                gap={{ xs: 1, md: 4 }}
                                justifyContent={'space-around'}
                              >
                                {data.testDetails.slice(1, 3).map((items, index) => (
                                  <Stack key={index}>
                                    <Typography
                                      sx={{
                                        color: 'white',
                                        fontFamily: 'Poppins',
                                        opacity: 0.5,
                                        fontSize: { xs: 10, sm: 12, md: 18, lg: 20 },
                                      }}
                                    >
                                      {items.title}
                                    </Typography>
                                    <Typography
                                      sx={{
                                        color: 'white',
                                        fontFamily: 'Poppins',
                                        fontWeight: 'bold',
                                        fontSize: { xs: 10, sm: 12, md: 18, lg: 20 },
                                      }}
                                    >
                                      {items.content}
                                    </Typography>
                                  </Stack>
                                ))}
                              </Stack>
                              <Stack
                                direction={'row'}
                                justifyContent={'space-between'}
                                gap={{ xs: 1, md: 4 }}
                              >
                                <Button
                                  startIcon={<Box component={'img'} src={ExportLogo} />}
                                  variant='outlined'
                                  sx={{
                                    padding: '5px 8px',
                                    fontSize: { xs: 12, md: 14 },
                                    color: 'white',
                                    height: { xs: '80%', md: '100%' },
                                    width: { xs: '60%', md: '100%' },
                                    textTransform: 'capitalize',
                                    textAlign: 'center',
                                    borderColor: 'white',
                                    ':hover': { borderColor: 'white' },
                                  }}
                                >
                                  Export
                                </Button>
                                <Button
                                  startIcon={<Box component={'img'} src={ViewLogo} />}
                                  variant='contained'
                                  sx={{
                                    padding: '5px 8px',
                                    fontSize: { xs: 12, md: 14 },
                                    height: { xs: '80%', md: '100%' },
                                    width: { xs: '60%', md: '100%' },
                                    textTransform: 'capitalize',
                                    textAlign: 'center',
                                    bgcolor: Colors.globalColorThemeColor,
                                    ':hover': { bgcolor: Colors.globalColorThemeColor },
                                  }}
                                >
                                  View
                                </Button>
                              </Stack>
                            </Stack>
                          </Stack>
                        </Card>
                      </div>
                    ))}
                  </Slider>
                </Box>

                {/* ---Mobile Responsive--- */}
                <Box sx={{ display: { xs: 'initial', sm: 'none' } }}>
                  <Slider>
                    {RecentlyCompletedData.map((data, index) => (
                      <div key={index}>
                        <Card
                          elevation={3}
                          sx={{
                            p: { xs: 2, sm: 2, md: 2, lg: 2 },
                            borderRadius: { xs: '0px', sm: '25px' },
                            background:
                              'linear-gradient(90deg, rgba(79,50,113,1) 44%, rgba(39,123,140,1) 100%)',
                          }}
                        >
                          <Stack justifyContent={'space-between'} gap={3}>
                            <Stack justifyContent={'space-between'}>
                              <Stack direction={'row'} justifyContent={'space-between'}>
                                {[
                                  data.testDetails[0],
                                  data.testDetails[4],
                                  data.testDetails[1],
                                  data.testDetails[2],
                                ].map((items, index) => (
                                  <Stack key={index}>
                                    <Typography
                                      sx={{
                                        color: 'white',
                                        fontFamily: 'Poppins',
                                        opacity: 0.5,
                                        fontSize: { xs: 12, sm: 12, md: 18, lg: 20 },
                                      }}
                                    >
                                      {items.title}
                                    </Typography>
                                    <Typography
                                      sx={{
                                        color: 'white',
                                        fontFamily: 'Poppins',
                                        fontWeight: 'bold',
                                        fontSize: { xs: 12, sm: 12, md: 18, lg: 20 },
                                      }}
                                    >
                                      {items.content}
                                    </Typography>
                                  </Stack>
                                ))}
                              </Stack>
                            </Stack>
                            <Stack
                              direction={'row'}
                              justifyContent={'space-between'}
                              alignItems={'center'}
                              gap={{ xs: 1, sm: 2, md: 6 }}
                            >
                              {data.studentRankings.map((items, index) => (
                                <Stack alignItems={'center'} key={index}>
                                  <Avatar
                                    sx={{ width: { sm: 35, md: 60 }, height: { sm: 35, md: 60 } }}
                                  >
                                    {items.name.charAt(0).toLocaleUpperCase()}
                                  </Avatar>
                                  <Typography
                                    sx={{
                                      fontFamily: 'Poppins',
                                      color: 'white',
                                      mt: 0.5,
                                      fontSize: { xs: 12, sm: 12, md: 18, lg: 20 },
                                    }}
                                  >
                                    {items.name}
                                  </Typography>
                                  <Box
                                    component={'img'}
                                    src={items.logo}
                                    sx={{
                                      width: { xs: 40, sm: 50, md: 68, lg: 78 },
                                      height: { xs: 40, sm: 50, md: 68, lg: 78 },
                                    }}
                                  />
                                </Stack>
                              ))}
                            </Stack>
                            <Stack
                              direction={'row'}
                              justifyContent={'space-between'}
                              alignItems={'center'}
                            >
                              {data.testDetails.slice(3, 4).map((items, index) => (
                                <Stack key={index}>
                                  <Typography
                                    sx={{
                                      color: 'white',
                                      fontFamily: 'Poppins',
                                      opacity: 0.5,
                                      fontSize: { xs: 12, sm: 12, md: 18, lg: 20 },
                                    }}
                                  >
                                    {items.title}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      color: 'white',
                                      fontFamily: 'Poppins',
                                      fontWeight: 'bold',
                                      fontSize: { xs: 12, sm: 12, md: 18, lg: 20 },
                                    }}
                                  >
                                    {items.content}
                                  </Typography>
                                </Stack>
                              ))}

                              <Stack
                                direction={'row'}
                                justifyContent={'space-between'}
                                alignItems={'center'}
                                gap={{ xs: 1, md: 4 }}
                              >
                                <Button
                                  startIcon={<Box component={'img'} src={ExportLogo} />}
                                  variant='outlined'
                                  sx={{
                                    padding: '5px 8px',
                                    fontSize: { xs: 12, md: 14 },
                                    color: 'white',
                                    height: { xs: 25, sm: '80%', md: '100%' },
                                    width: { sm: '60%', md: '100%' },
                                    textTransform: 'capitalize',
                                    textAlign: 'center',
                                    borderColor: 'white',
                                    ':hover': { borderColor: 'white' },
                                  }}
                                >
                                  Export
                                </Button>
                                <Button
                                  startIcon={<Box component={'img'} src={ViewLogo} />}
                                  variant='contained'
                                  sx={{
                                    padding: '5px 8px',
                                    fontSize: { xs: 12, md: 14 },
                                    height: { xs: 25, sm: '80%', md: '100%' },
                                    width: { sm: '60%', md: '100%' },
                                    textTransform: 'capitalize',
                                    textAlign: 'center',
                                    bgcolor: Colors.globalColorThemeColor,
                                    ':hover': { bgcolor: Colors.globalColorThemeColor },
                                  }}
                                >
                                  View
                                </Button>
                              </Stack>
                            </Stack>
                          </Stack>
                        </Card>
                      </div>
                    ))}
                  </Slider>
                </Box>
                {/* ---Mobile Responsive--- */}
              </Stack>
              <Box mt={5}>
                <Box
                  sx={{
                    gap: '200px',
                  }}
                >
                  <Stack
                    direction={{ md: 'row' }}
                    justifyContent={{ sm: 'space-between' }}
                    alignItems={{ sm: 'flex-start' }}
                    mt={1}
                    gap={{ sm: 5, md: 2 }}
                  >
                    <Stack
                      direction={'row'}
                      gap={{ xs: 1, md: 1, lg: 1, xl: 2 }}
                      flexWrap={'wrap'}
                      className='classAndStudentDetails'
                    >
                      <Stack>
                        <Typography variant='h6' sx={{ fontFamily: 'Poppins', opacity: 0.6 }}>
                          Class
                        </Typography>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexDirection: 'row',
                          }}
                          className='classButton'
                        >
                          {[...filterClass].map((items, index) => (
                            <Button
                              key={index}
                              variant='outlined'
                              onClick={() => selectClassFn(index)}
                              sx={{
                                height: { sm: 30, md: 40 },
                                width: { xs: 70, md: 100 },
                                fontFamily: 'Poppins',
                                fontWeight: 'bold',
                                marginRight: '10px',
                                textTransform: 'capitalize',
                                fontSize: { xs: 12, sm: 12, md: 13, lg: 15 },
                                p: 0.5,
                                borderColor: Colors.globalColorThemeColor,
                                bgcolor:
                                  index === currentSelectionofClass
                                    ? Colors.globalColorThemeColor
                                    : 'none',
                                color: index === currentSelectionofClass ? 'white' : 'black',
                                ':hover': {
                                  borderColor: Colors.globalColorThemeColor,
                                  bgcolor:
                                    index === currentSelectionofClass
                                      ? Colors.globalColorThemeColor
                                      : 'none',
                                },
                              }}
                            >
                              {items}
                            </Button>
                          ))}
                        </Box>
                      </Stack>
                      <Stack>
                        <Typography variant='h6' sx={{ fontFamily: 'Poppins', opacity: 0.6 }}>
                          Students
                        </Typography>
                        <ThemeProvider theme={theme}>
                          <Tooltip
                            arrow
                            title={
                              <Box
                                sx={{
                                  backgroundColor: 'White',
                                  padding: '0px',
                                  width: '250px',
                                  height: '150px',
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'space-around',
                                  borderRadius: '8px',
                                  border: '2px solid #4f3271',
                                }}
                              >
                                <Box>
                                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography
                                      sx={{
                                        color: 'black',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                      }}
                                    >
                                      {' '}
                                      <GirlIcon sx={{ color: 'black' }} /> Male
                                    </Typography>
                                    <Typography sx={{ color: 'black', marginRight: '5px' }}>
                                      {male}
                                    </Typography>
                                  </Box>
                                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography
                                      sx={{
                                        color: 'black',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                      }}
                                    >
                                      {' '}
                                      <BoyIcon sx={{ color: 'black' }} /> Female
                                    </Typography>
                                    <Typography sx={{ color: 'black', marginRight: '5px' }}>
                                      {female}
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                  <Button
                                    style={{
                                      backgroundColor: '#7b8bc4',
                                      height: '30px',
                                      width: '90%',
                                      color: 'white',
                                    }}
                                    onClick={handleClickDialogBoxOpen}
                                  >
                                    View All
                                  </Button>
                                </Box>
                              </Box>
                            }
                          >
                            {loadingStudentCounts ? (
                              <Skeleton variant='text' sx={{ fontSize: '1rem' }} />
                            ) : (
                              <Typography
                                sx={{
                                  fontWeight: '10px',
                                  textDecoration: 'underline',
                                  cursor: 'pointer',
                                }}
                              >
                                {male + female}
                              </Typography>
                            )}
                          </Tooltip>
                        </ThemeProvider>
                      </Stack>
                    </Stack>

                    <Stack
                      direction={{ sm: 'row' }}
                      alignItems={{ xs: 'flex-start', sm: 'center' }}
                      justifyContent={'space-between'}
                      gap={{ xs: 3, sm: 1, md: 1.5 }}
                      mt={{ xs: 3, sm: 0 }}
                      sx={{ width: { xs: '100%', md: 'initial' } }}
                      className='createNewTest'
                    >
                      <Box className='classandsection'>
                        <TextField
                          size='small'
                          select
                          label='Select Medium'
                          value={filterMedium[currentSelectionofMedium]}
                          sx={{
                            width: { xs: '100%', sm: 150, md: 160, lg: 200 },
                            '& label.Mui-focused': {
                              color: '#321E1E',
                            },
                            '& .MuiOutlinedInput-input': {
                              pt: { xs: 0.8, sm: 0.5, md: 1 },
                              pl: 1,
                              pr: 8,
                              pb: { xs: 0.8, sm: 0.5, md: 1 },
                              fontSize: { xs: 15, sm: 12, md: 13, xl: 15 },
                              fontFamily: 'Poppins',
                            },
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: 'gray',
                              },
                              '&:hover fieldset': {
                                borderColor: 'gray',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#321E1E',
                              },
                            },
                          }}
                        >
                          {filterMedium.map((option, index) => (
                            <MenuItem
                              key={index}
                              value={option}
                              onClick={() => {
                                setCurrentSelectionofSection(0);
                                setCurrentSelectionofSubject(0);
                                setCurrentSelectionofMedium(index);
                              }}
                              sx={{
                                mt: 1,
                                fontFamily: 'Poppins',
                                fontSize: { xs: 15, sm: 12, md: 15, xl: 15 },
                              }}
                            >
                              {option.charAt(0).toUpperCase() + option.slice(1)}
                            </MenuItem>
                          ))}
                        </TextField>
                        <TextField
                          className='sele'
                          size='small'
                          select
                          label='Select Section'
                          value={filterSection[currentSelectionofSection]}
                          sx={{
                            '& label.Mui-focused': {
                              color: '#321E1E',
                            },
                            '& .MuiOutlinedInput-input': {
                              pt: { xs: 0.8, sm: 0.5, md: 1 },
                              pl: 1,
                              pr: 8,
                              pb: { xs: 0.8, sm: 0.5, md: 1 },
                              fontSize: { xs: 15, sm: 12, md: 13, xl: 15 },
                              fontFamily: 'Poppins',
                            },
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: 'gray',
                              },
                              '&:hover fieldset': {
                                borderColor: 'gray',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#321E1E',
                              },
                            },
                          }}
                        >
                          {filterSection.map((option, index) => (
                            <MenuItem
                              key={index}
                              value={option}
                              onClick={() => {
                                setCurrentSelectionofSubject(0);
                                setCurrentSelectionofSection(index);
                              }}
                              sx={{
                                mt: 1,
                                fontFamily: 'Poppins',
                                fontSize: { xs: 15, sm: 12, md: 15, xl: 15 },
                              }}
                            >
                              {option}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Box>
                      <Box className='mathsandcreatetest'>
                        <TextField
                          size='small'
                          select
                          label='Select Subject'
                          value={filterSubjects[currentSelectionofSubject]}
                          sx={{
                            width: { xs: '100%', sm: 150, md: 200, lg: 200 },
                            '& label.Mui-focused': {
                              color: '#321E1E',
                            },
                            '& .MuiOutlinedInput-input': {
                              pt: { xs: 0.8, sm: 0.5, md: 1 },
                              pl: 1,
                              pr: 8,
                              pb: { xs: 0.8, sm: 0.5, md: 1 },
                              fontSize: { xs: 15, sm: 12, md: 13, xl: 15 },
                              fontFamily: 'Poppins',
                            },
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: 'gray',
                              },
                              '&:hover fieldset': {
                                borderColor: 'gray',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#321E1E',
                              },
                            },
                          }}
                        >
                          {filterSubjects.map((option, index) => (
                            <MenuItem
                              key={index}
                              value={option}
                              onClick={() => setCurrentSelectionofSubject(index)}
                              sx={{
                                mt: 1,
                                fontFamily: 'Poppins',
                                fontSize: { xs: 15, sm: 12, md: 15, xl: 15 },
                              }}
                            >
                              {option}
                            </MenuItem>
                          ))}
                        </TextField>
                        <Button
                          variant='contained'
                          startIcon={<AddCircleIcon />}
                          onClick={createNewTestFn}
                          sx={{
                            width: { xs: '100%', sm: 150, md: 200 },
                            height: { xs: 32, sm: 30, md: 40 },
                            textTransform: 'capitalize',
                            fontWeight: 'bold',
                            fontSize: { xs: 12, sm: 12, md: 15, xl: 15 },
                            p: 0.5,
                            borderRadius: '5px',
                            bgcolor: Colors.globalColorThemeColor,
                            ':hover': { bgcolor: Colors.globalColorThemeColor },
                          }}
                        >
                          Create new test
                        </Button>
                      </Box>
                    </Stack>
                  </Stack>

                  <Stack
                    direction={{ md: 'row' }}
                    alignItems={{ sm: 'flex-start', md: 'center' }}
                    justifyContent={'space-between'}
                    mt={5}
                  >
                    <Typography
                      sx={{
                        fontWeight: 'bold',
                        width: { sm: 260 },
                        fontFamily: 'Poppins',
                        fontSize: { xs: 15, sm: 18, lg: 20 },
                      }}
                    >
                      {title}
                    </Typography>

                    <Stack
                      direction={{ sm: 'row' }}
                      mt={{ xs: 2, sm: 2, md: 0 }}
                      mb={{ xs: 3, sm: 0 }}
                      alignItems={'center'}
                      justifyContent={{ sm: 'space-between' }}
                      gap={{ xs: 3, sm: 1.5, md: 1.5, lg: 3 }}
                      sx={{ width: { sm: '100%', md: 'inherit' } }}
                    >
                      <TextField
                        onChange={(e) => FIlter(e.target.value)}
                        size='small'
                        placeholder='Search Assessment'
                        value={searchValue}
                        sx={{
                          width: { xs: '98%', sm: 'initial' },
                          '& .MuiOutlinedInput-input': {
                            fontSize: { sm: 12, lg: 15 },
                            fontFamily: 'Poppins',
                            pt: { xs: 0.8, md: 1.2 },
                            pl: { sm: 1, md: 2 },
                            pb: { xs: 0.8, md: 1.3 },
                          },
                          '& .MuiOutlinedInput-root': {
                            pr: { sm: 0.5, md: 1 },
                            '& fieldset': {
                              borderColor: 'gray',
                            },
                            '&:hover fieldset': {
                              borderColor: 'gray',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#321E1E',
                            },
                          },
                        }}
                        InputProps={{
                          endAdornment: (
                            <IconButton sx={{ p: 0 }}>
                              <SearchIcon />
                            </IconButton>
                          ),
                        }}
                      />

                      <Stack
                        direction={'row'}
                        alignItems={'center'}
                        sx={{ width: { xs: '98%', sm: 150, md: 200 } }}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            onChange={changeData}
                            value={dateFilterValue}
                            format='DD/MM/YYYY'
                            slotProps={{
                              textField: {
                                error: false,
                              },
                            }}
                            sx={{
                              '& .MuiOutlinedInput-input': {
                                pt: { xs: '7px', sm: '7px', md: '9px' },
                                pb: { xs: '7px', sm: '7px', md: '9px' },
                                fontSize: { xs: 15, sm: 12, md: 15, xl: 15 },
                                fontFamily: 'Poppins',
                              },
                            }}
                            disablePast={title === 'Upcoming Assessments' ? true : false}
                            disableFuture={title === 'Assessment History' ? true : false}
                            shouldDisableDate={filterDates}
                          />
                        </LocalizationProvider>

                        <CloseIcon
                          onClick={dateClear}
                          sx={{
                            pt: { xs: '7px', sm: '7px', md: '9px' },
                            pb: { xs: '7px', sm: '7px', md: '9px' },
                            fontSize: { xs: 15, sm: 12, md: 15, xl: 20 },
                            cursor: 'pointer',
                            display: dateFilterValue ? 'initial' : 'none',
                          }}
                        />
                      </Stack>
                      <Stack direction={'row'}>
                        <Button
                          size='small'
                          variant='outlined'
                          onClick={() => {
                            if (title !== 'Upcoming Assessments') {
                              setLoading(true);
                              setTitle('Upcoming Assessments');
                              setCurrentPage(1);
                            }
                          }}
                          sx={{
                            width: 100,
                            borderTopLeftRadius: '5px',
                            borderBottomLeftRadius: '5px',
                            borderBottomRightRadius: '0px',
                            borderTopRightRadius: '0px',
                            height: { sm: 30, md: 40 },
                            textTransform: 'capitalize',
                            fontFamily: 'Poppins',
                            fontSize: { xs: 10, sm: 12, md: 13 },
                            p: 0.5,
                            border: '0.2px solid' + Colors.globalColorThemeColor,
                            bgcolor:
                              title === 'Upcoming Assessments'
                                ? Colors.globalColorThemeColor
                                : 'white',
                            color: title === 'Upcoming Assessments' ? 'white' : 'black',
                            ':hover': {
                              bgcolor:
                                title === 'Upcoming Assessments'
                                  ? Colors.globalColorThemeColor
                                  : 'white',
                              border: '0.2px solid' + Colors.globalColorThemeColor,
                            },
                          }}
                        >
                          Upcoming
                        </Button>
                        <Button
                          size='small'
                          variant='outlined'
                          onClick={() => {
                            if (title !== 'Active Assessments') {
                              setLoading(true);
                              setTitle('Active Assessments');
                              setCurrentPage(1);
                            }
                          }}
                          sx={{
                            width: 100,
                            borderBottomLeftRadius: '0px',
                            borderTopLeftRadius: '0px',
                            borderBottomRightRadius: '0px',
                            borderTopRightRadius: '0px',
                            height: { sm: 30, md: 40 },
                            textTransform: 'capitalize',
                            fontFamily: 'Poppins',
                            fontSize: { xs: 10, sm: 12, md: 13 },
                            p: 0.5,
                            border: '0.2px solid' + Colors.globalColorThemeColor,
                            bgcolor:
                              title === 'Active Assessments'
                                ? Colors.globalColorThemeColor
                                : 'white',
                            color: title === 'Active Assessments' ? 'white' : 'black',
                            ':hover': {
                              bgcolor:
                                title === 'Active Assessments'
                                  ? Colors.globalColorThemeColor
                                  : 'white',
                              border: '0.2px solid' + Colors.globalColorThemeColor,
                            },
                          }}
                        >
                          Active
                        </Button>
                        <Button
                          size='small'
                          variant='outlined'
                          onClick={() => {
                            if (title !== 'Assessment History') {
                              setLoading(true);
                              setTitle('Assessment History');
                              setCurrentPage(1);
                            }
                          }}
                          sx={{
                            width: 100,
                            borderTopRightRadius: '5px',
                            borderBottomRightRadius: '5px',
                            borderBottomLeftRadius: '0px',
                            borderTopLeftRadius: '0px',
                            height: { sm: 30, md: 40 },
                            textTransform: 'capitalize',
                            fontFamily: 'Poppins',
                            fontSize: { xs: 10, sm: 12, md: 13 },
                            p: 0.5,
                            border: '0.2px solid' + Colors.globalColorThemeColor,
                            bgcolor:
                              title === 'Assessment History'
                                ? Colors.globalColorThemeColor
                                : 'white',
                            color: title === 'Assessment History' ? 'white' : 'black',
                            ':hover': {
                              bgcolor:
                                title === 'Assessment History'
                                  ? Colors.globalColorThemeColor
                                  : 'white',
                              border: '0.2px solid' + Colors.globalColorThemeColor,
                            },
                          }}
                        >
                          History
                        </Button>
                      </Stack>
                    </Stack>
                  </Stack>
                </Box>
                <Box mt={3}>
                  {title === 'Upcoming Assessments' ? (
                    <Upcoming
                      loading={loading}
                      examDatas={examDatas}
                      getData={getData}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      setLoading={setLoading}
                      teacherProfileData={teacherProfileData}
                      sentDataToUploadPage={sentDataToUploadPage}
                      getProfileDataFromApi={getProfileDataFromApi}
                      setDateFilterValue={setDateFilterValue}
                      setSearchValue={setSearchValue}
                    />
                  ) : title === 'Active Assessments' ? (
                    <Active
                      loading={loading}
                      examDatas={examDatas}
                      getData={getData}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                    />
                  ) : (
                    <History
                      loading={loading}
                      examDatas={examDatas}
                      getData={getData}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                    />
                  )}
                </Box>
              </Box>
            </Stack>
          </Box>
        ) : (
          <Stack alignItems={'center'} justifyContent={'center'} gap={2} height={'90vh'}>
            <img src={logo} />
            <Typography variant='h6' sx={{ fontFamily: 'Poppins', fontWeight: 'bold' }}>
              Loading ...
            </Typography>
          </Stack>
        )}
      </Stack>

      <Dialog
        open={createTestDialog}
        TransitionComponent={Transition}
        TransitionProps={{ timeout: 1000 }}
        keepMounted
        onClose={() => setCreateTestDialog(false)}
        aria-describedby='alert-dialog-slide-description'
      >
        <Stack
          gap={5}
          sx={{
            background: 'linear-gradient(90deg, rgba(79,50,113,1) 44%, rgba(39,123,140,1) 100%)',
          }}
        >
          <Stack alignItems={'flex-end'} mr={1} mt={1}>
            <IconButton
              onClick={() => setCreateTestDialog(false)}
              sx={{ bgcolor: 'black', p: 0.2, ':hover': { bgcolor: 'black' } }}
            >
              <CloseIcon fontSize='small' sx={{ color: 'white' }} />
            </IconButton>
          </Stack>
          <Stack gap={5} sx={{ padding: '0px 100px', mb: 10 }}>
            <Button
              variant='outlined'
              onClick={createNewTestFn}
              startIcon={<UploadIcon sx={{ color: 'white' }} />}
              sx={{ borderColor: 'white', color: 'white', ':hover': { borderColor: 'white' } }}
            >
              Upload Questions
            </Button>
            <Button
              variant='contained'
              startIcon={<CloudUploadIcon />}
              sx={{
                background:
                  'linear-gradient(90deg, rgba(39,123,140,1) 44%, rgba(79,50,113,1) 100%)',
                border: '1px solid white',
              }}
            >
              Pre Uploaded Questions
            </Button>
          </Stack>
        </Stack>
      </Dialog>

      <Dialog
        open={studentsDialogBoxOpen}
        PaperProps={{ sx: { width: '555px', height: '470px', borderRadius: '10px' } }}
      >
        <DialogTitle
          id='scroll-dialog-title'
          style={{
            backgroundColor: '#7b8bc4',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#ffffff',
            fontSize: '16px',
            fontWeight: 600,
          }}
        >
          Students
        </DialogTitle>
        <IconButton
          aria-label='close'
          onClick={handleDialogBoxClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: '#ffffff',
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              // width: { xs: '263px', sm: '480px' },

              width: '100%',
            }}
          >
            <Box>
              <Typography style={{ fontSize: '16px' }}> Total Student </Typography>
              <Typography
                style={{
                  color: '#000000',
                  fontWeight: 'bolder',
                  fontSize: '18px',
                }}
              >
                {male + female}
              </Typography>
            </Box>
            <Box>
              <Typography style={{ fontSize: '16px' }}> Males </Typography>
              <Typography
                style={{
                  color: '#000000',
                  fontWeight: 'bolder',
                  fontSize: '18px',
                }}
              >
                {' '}
                {male}
              </Typography>
            </Box>
            <Box>
              <Typography style={{ fontSize: '16px' }}> Females </Typography>
              <Typography
                style={{
                  color: '#000000',
                  fontWeight: 'bolder',
                  fontSize: '18px',
                }}
              >
                {' '}
                {female}
              </Typography>
            </Box>
          </Box>

          <Box sx={{ width: '100%' }}>
            <Divider
              sx={{
                color: 'blue',
                fontSize: '29px',
                borderBottomWidth: '3px',
                marginTop: '15px',
                // width: { xs: '263px', sm: '480px' },
                width: '100%',
              }}
            />
          </Box>

          {studentViewData && studentViewData ? (
            <Selectstudentinformation
              singleStudentDetails={singleStudentDetails}
              backtoWholeStudentDetails={backtoWholeStudentDetails}
              datasendtowolestud={studentDetails}
            />
          ) : (
            <Studentswholeinformation
              viewSingleStudentDetail={viewSingleStudentDetail}
              datasendtowolestud={studentDetails}
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}

export default TeachersDashboard;
