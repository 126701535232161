import React, { useState, useEffect } from 'react';
import { Link, Button, AppBar } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import image from './images/assesmentTool.png';
import ntecomlogo from './images/netcomLogo.png';
import './Style/LandingAppBarstyle.css';
function LandingAppBar() {
  const navigate = useNavigate();
  const [clicked, setclicked] = useState(false);
  const handleDrawerOpen = () => {
    setclicked(!clicked);
  };
  useEffect(() => {
    if (clicked) {
      document.body.classList.add('menu-active');
    } else {
      document.body.classList.remove('menu-active');
    }
  }, [clicked]);

  return (
    <div className='appbar'>
      <AppBar position='fixed'>
        <div className='mainAppbar'>
          <nav className='landinappBar'>
            <div className='landingappLogo'>
              <img src={image} className='netcomlogos' />
            </div>
            <div className={clicked ? 'landingappbuttonDiv active' : 'landingappbuttonDiv'}>
              <Link class='linkClas'>HOME</Link>
              <Link class='linkClass' href='#about'>
                ABOUT
              </Link>
              <Link href='#features' class='linkClass'>
                FEATURES
              </Link>
              <Link href='#video' class='linkClass'>
                DEMO
              </Link>
              <Button
                sx={{
                  p: 0,
                  color: '#ffffff',
                  bgcolor: '#9747ff',
                  ':hover': { bgcolor: '#9747ff' },
                  width: { xs: '28px', sm: '80px' },
                  height: { xs: '28px', sm: '40px' },
                  marginLeft: { xs: '4px', sm: '0px' },
                }}
                onClick={() => navigate('/login')}
              >
                LOGIN
              </Button>
            </div>
            <div className='landingappnetcomLogo'>
              <img className='netcomlogos' src={ntecomlogo} />
            </div>

            <div className='tcsmobiles'>
              <i
                className={clicked ? 'fas fa-times' : 'fas fa-bars'}
                onClick={handleDrawerOpen}
              ></i>
            </div>
          </nav>
        </div>
      </AppBar>
    </div>
  );
}
export default LandingAppBar;
