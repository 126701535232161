import { createSlice } from '@reduxjs/toolkit';

export const teacherProfileDataSlice = createSlice({
  name: 'teacherProfileData',
  initialState: {
    profile: '',
  },
  reducers: {
    changeTeacherProfileData: (state, action) => {
      state.profile = action.payload;
    },
  },
});

export const { changeTeacherProfileData } = teacherProfileDataSlice.actions;
export const CurrentTeacherProfileData = (state) => state.teacherProfileData.profile;
export default teacherProfileDataSlice.reducer;
