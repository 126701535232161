import React from 'react';
import eyes from '../Images/Eyes.png';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Colors from '../../../../utils/Colors';
import { examHistroyData } from '../../constants';
import { Pagination, Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import './activestyle.css';
import CircleLoader from 'react-spinners/CircleLoader';
import OnlinePredictionIcon from '@mui/icons-material/OnlinePrediction';

function Active(props) {
  const { examDatas, getData, currentPage, setCurrentPage, loading } = props;
  const header = ['Test Name', 'Schedule', 'Questions', 'Status', 'starttime', 'endtime'];
  const recordsPerPage = 10;
  const lastIndex = currentPage * recordsPerPage;
  console.log('active', lastIndex);
  const firstIndex = lastIndex - recordsPerPage;
  console.log('active', firstIndex);
  const records = getData.slice(firstIndex, lastIndex);
  console.log('active', records);
  const npage = Math.ceil(getData.length / recordsPerPage);

  function changeCPage(id) {
    setCurrentPage(id);
  }

  const filterDate = (val) => {
    const a = new Date(val);
    const hours = a.getHours();
    const amOrPm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = (hours % 12 || 12).toString().padStart(2, '0');
    const formattedMinutes = a.getMinutes().toString().padStart(2, '0');
    const final = `${a.getDate().toString()}-${(a.getMonth() + 1).toString()}-${a
      .getFullYear()
      .toString()} ${formattedHours}:${formattedMinutes}${amOrPm}`;

    return final;
  };

  return (
    <div className='resultOfActive'>
      {loading ? (
        <div className='Activeloading'>
          <Typography
            variant='h6'
            sx={{
              fontFamily: 'Poppins',
              fontWeight: 'bold',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <CircleLoader color='#6b4696' cssOverride={{}} size={99} speedMultiplier={1} />
            Loading ...
          </Typography>
        </div>
      ) : examDatas.length !== 0 ? (
        <>
          {getData.length !== 0 ? (
            <>
              <table className='tableDivs'>
                <thead>
                  <tr>
                    {header.map((head, index) => (
                      <th
                        key={index}
                        style={{ backgroundColor: Colors.globalColorThemeColor }}
                        className={
                          index === 0
                            ? 'activeTableHeaders'
                            : index === 1
                            ? 'activeHeadName'
                            : index === 2
                            ? 'activeHeadNoOfQuestion'
                            : index === 3
                            ? 'activeHeadSchedule'
                            : index === 4
                            ? 'activeHeadAttendees'
                            : 'activeHeadActions'
                        }
                      >
                        {head}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {records.map((row, index) => (
                    <tr key={index}>
                      <td className='activeTestNameTableData'>{row.testName}</td>
                      <td className='activeNoOfQuestionTableData'>
                        {filterDate(row.startDateTime).split(' ')[0]}
                      </td>
                      <td className='activeScheduleTimeTableData'>{row.questionsCount}</td>
                      <td className='activeNoOfSTudentTableData'>
                        <Stack
                          direction='row'
                          alignItems={'center'}
                          justifyContent={'center'}
                          gap={1}
                          sx={{ color: 'green' }}
                        >
                          <OnlinePredictionIcon
                            sx={{
                              mb: 0.3,
                              animation: 'blink 2s infinite',
                              '@keyframes blink': {
                                '0%, 100%': {
                                  opacity: 1,
                                },
                                '25%': {
                                  opacity: 0.8,
                                },
                                '50%': {
                                  opacity: 0,
                                },
                                '75%': {
                                  opacity: 0.8,
                                },
                              },
                            }}
                          />
                          <Typography>Ongoing</Typography>
                        </Stack>
                      </td>
                      <td className='activeAttendeesTableData'>
                        {filterDate(row.startDateTime).split(' ')[1]}{' '}
                      </td>
                      <td className='activeEndTime'>
                        {filterDate(row.endDateTime).split(' ')[1]}{' '}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <Pagination count={npage} page={currentPage} onChange={(e, i) => changeCPage(i)} />
            </>
          ) : (
            <div className='noDataFound'>
              <SearchOffIcon sx={{ fontSize: '150px', color: '#6b4696' }} className='icone' />
              <span className='dataFoundLetter'>No data Found</span>
            </div>
          )}
        </>
      ) : (
        <div className='Activeloading'>
          <Typography
            variant='h6'
            sx={{
              fontFamily: 'Poppins',
              fontWeight: 'bold',
            }}
          >
            No Active Exams ...
          </Typography>
        </div>
      )}
    </div>
  );
}

export default Active;
