import { Box, Button, IconButton, Stack, TextField, Typography } from '@mui/material';
import React from 'react';
import ListAltIcon from '@mui/icons-material/ListAlt';
import Colors from '../../../utils/Colors';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useSnackbar } from 'notistack';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DescriptionIcon from '@mui/icons-material/Description';
import axios from 'axios';
import dayjs from 'dayjs';
import { APIClient, RefreshToken, RefreshTokenSwalAlert } from '../../../utils/APIClient';
import { useNavigate } from 'react-router-dom';

function QuestionSet(props) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const emptyInputErrorMsg = () =>
    enqueueSnackbar('Fill all the fields', { variant: 'error', preventDuplicate: true });

  const {
    queSet,
    setQueSet,
    state,
    examDetails,
    checkAccess,
    setCheckAccess,
    saveEditValidation,
    setOpenBulkQue,
    queCurrentIndexVal,
    setQueCurrentIndexVal,
    greaterThan,
    setOpenFinalConfirmation,
    getQueSetData,
    setGetQueSetData,
    setPreUploadQueDialog,
    setPreUploadQueSet,
    setPreviousQueSet,
    setPreviousQueDialog,
    setSearchTerm,
    setAnchorEl,
    setTextfieldValidation,
  } = props;
  console.log(state);
  const { qn, opt1, opt2, opt3, opt4, ans } = getQueSetData;
  const currentDate = dayjs();
  const CommonStyles2 = {
    fontFamily: 'Poppins',
    fontWeight: 'bold',
    fontSize: { xs: 11.5, sm: 13, md: 15, lg: 12, xl: 15 },
  };

  const typeTamil = (event, index) => {
    console.log(event);
    const checkIndex = {
      0: 'qn',
      1: 'opt1',
      2: 'opt2',
      3: 'opt3',
      4: 'opt4',
    };
    setTextfieldValidation(checkIndex[`${index}`]);
    setAnchorEl(event.currentTarget);
    const getLastLetter = event.target.value;
    setSearchTerm(getLastLetter.charAt(getLastLetter.length - 1));
  };

  const optionInputField = [
    {
      title: '(a)',
      onChangeFn:
        state.medium === 'Tamil'
          ? (e) => typeTamil(e, 1)
          : (e) => setGetQueSetData((pre) => ({ ...pre, opt1: e.target.value })),
      onKeyPressFn: (e) => {
        if (e.key === ' ') {
          e.preventDefault();
          setGetQueSetData((pre) => ({ ...pre, opt1: `${opt1} ` }));
        }
      },
      onKeyDownFn: (e) => {
        if (e.key === 'Backspace') {
          e.preventDefault();
          setGetQueSetData((pre) => ({ ...pre, opt1: opt1.slice(0, -1) }));
        }
      },
      value: opt1,
    },
    {
      title: '(b)',
      onChangeFn:
        state.medium === 'Tamil'
          ? (e) => typeTamil(e, 2)
          : (e) => setGetQueSetData((pre) => ({ ...pre, opt2: e.target.value })),
      onKeyPressFn: (e) => {
        if (e.key === ' ') {
          e.preventDefault();
          setGetQueSetData((pre) => ({ ...pre, opt2: `${opt2} ` }));
        }
      },
      onKeyDownFn: (e) => {
        if (e.key === 'Backspace') {
          e.preventDefault();
          setGetQueSetData((pre) => ({ ...pre, opt2: opt2.slice(0, -1) }));
        }
      },
      value: opt2,
    },
    {
      title: '(c)',
      onChangeFn:
        state.medium === 'Tamil'
          ? (e) => typeTamil(e, 3)
          : (e) => setGetQueSetData((pre) => ({ ...pre, opt3: e.target.value })),
      onKeyPressFn: (e) => {
        if (e.key === ' ') {
          e.preventDefault();
          setGetQueSetData((pre) => ({ ...pre, opt3: `${opt3} ` }));
        }
      },
      onKeyDownFn: (e) => {
        if (e.key === 'Backspace') {
          e.preventDefault();
          setGetQueSetData((pre) => ({ ...pre, opt3: opt3.slice(0, -1) }));
        }
      },
      value: opt3,
    },
    {
      title: '(d)',
      onChangeFn:
        state.medium === 'Tamil'
          ? (e) => typeTamil(e, 4)
          : (e) => setGetQueSetData((pre) => ({ ...pre, opt4: e.target.value })),
      onKeyPressFn: (e) => {
        if (e.key === ' ') {
          e.preventDefault();
          setGetQueSetData((pre) => ({ ...pre, opt4: `${opt4} ` }));
        }
      },
      onKeyDownFn: (e) => {
        if (e.key === 'Backspace') {
          e.preventDefault();
          setGetQueSetData((pre) => ({ ...pre, opt4: opt4.slice(0, -1) }));
        }
      },
      value: opt4,
    },
  ];

  const keysToCheck = ['qn', 'opt1', 'opt2', 'opt3', 'opt4', 'ans'];

  const getQueSetUpdate = (val) => {
    var setOperator =
      val === 'inc' ? queCurrentIndexVal + 1 : val === 'dec' ? queCurrentIndexVal - 1 : val;
    setQueCurrentIndexVal(setOperator);
    setGetQueSetData({
      qn: queSet[setOperator].qn,
      opt1: queSet[setOperator].opt1,
      opt2: queSet[setOperator].opt2,
      opt3: queSet[setOperator].opt3,
      opt4: queSet[setOperator].opt4,
      ans: queSet[setOperator].ans,
    });
  };

  const queSetUpdate = () => {
    const updateQueSet = queSet.map((items, index) =>
      index === queCurrentIndexVal ? { ...items, ...getQueSetData } : items,
    );
    setQueSet(updateQueSet);
  };

  const nextQueBtnFn = () => {
    if (Object.values(getQueSetData).includes('')) {
      emptyInputErrorMsg();
    } else {
      if (keysToCheck.some((key) => Object.keys(queSet[queCurrentIndexVal + 1]).includes(key))) {
        getQueSetUpdate('inc');
        queSetUpdate();
      } else {
        queSetUpdate();
        setQueCurrentIndexVal(queCurrentIndexVal + 1);
        setGetQueSetData({
          qn: '',
          opt1: '',
          opt2: '',
          opt3: '',
          opt4: '',
          ans: '',
        });
        setCheckAccess(0);
      }
    }
  };
  const preQueBtnJumpToQueFn = (val) => {
    if (queCurrentIndexVal === greaterThan - checkAccess) {
      getQueSetUpdate(val);
      queSetUpdate();
      setCheckAccess(1);
    } else if (Object.values(getQueSetData).includes('')) {
      emptyInputErrorMsg();
    } else {
      getQueSetUpdate(val);
      queSetUpdate();
    }
  };

  const sentDataFinishFn = () => {
    const format = 'DD/MM/YYYY HH:mm:ss';
    const { startDateTime, ...newObject } = examDetails;
    if (Object.values(getQueSetData).includes('')) {
      emptyInputErrorMsg();
    } else if (dayjs(startDateTime, format).isBefore(dayjs(currentDate, format))) {
      enqueueSnackbar('Invalid Date and Time', { variant: 'error', preventDuplicate: true });
    } else {
      console.log(getQueSetData);
      const updateQueSet = queSet.map((items, index) =>
        index === queCurrentIndexVal ? { ...items, ...getQueSetData } : items,
      );
      console.log(updateQueSet);
      setQueSet(updateQueSet);
      setOpenFinalConfirmation(true);
    }
  };

  const preUploadQuestionFn = async () => {
    try {
      setPreUploadQueDialog(true);
      const requestData = {
        subject_id: state.subjectID,
      };
      console.log(requestData);
      const resGetPreUploadQuestion = await axios.post(
        `${APIClient().API_BASE_URL}/api/getPreUploadQuestion`,
        requestData,
        APIClient().API_HEADERS,
      );
      console.log(resGetPreUploadQuestion);
      if (resGetPreUploadQuestion.data.success) {
        console.log(resGetPreUploadQuestion);
        setPreUploadQueSet(resGetPreUploadQuestion.data.message);
      } else {
        setPreUploadQueSet([]);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newTokens = await RefreshToken();
        console.log(newTokens);
        if (newTokens.success) {
          localStorage.setItem('accessToken', newTokens.newAccessToken);
          preUploadQuestionFn();
        } else {
          setPreUploadQueDialog(false);
          RefreshTokenSwalAlert(newTokens.error, navigate);
        }
      } else {
        setPreUploadQueDialog(false);
        RefreshTokenSwalAlert('catchError', navigate);
      }
    }
  };

  const previousQuestionFn = async () => {
    try {
      setPreviousQueDialog(true);
      const requestData = {
        subjectID: state.subjectID,
      };
      console.log(requestData);
      const resGetPrevQuestionSet = await axios.post(
        `${APIClient().API_BASE_URL}/api/getPrevQuestionSet`,
        requestData,
        APIClient().API_HEADERS,
      );
      console.log(resGetPrevQuestionSet);
      if (resGetPrevQuestionSet.data.success) {
        console.log(resGetPrevQuestionSet);
        setPreviousQueSet(resGetPrevQuestionSet.data.data);
      } else {
        setPreviousQueSet([]);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newTokens = await RefreshToken();
        console.log(newTokens);
        if (newTokens.success) {
          localStorage.setItem('accessToken', newTokens.newAccessToken);
          previousQuestionFn();
        } else {
          setPreviousQueDialog(false);
          RefreshTokenSwalAlert(newTokens.error, navigate);
        }
      } else {
        setPreviousQueDialog(false);
        RefreshTokenSwalAlert('catchError', navigate);
      }
    }
  };
  return (
    <>
      {queSet && (
        <Box mt={3}>
          <Stack mt={1}>
            <Stack
              direction={{ xs: 'column-reverse', md: 'row' }}
              gap={{ xs: 3, md: 0 }}
              justifyContent={'space-between'}
              alignItems={{ md: 'center' }}
            >
              <Typography
                fontWeight={'600'}
                color={Colors.globalColorThemeColor}
                sx={{ fontSize: 18, fontFamily: 'Poppins', fontWeight: 'bold' }}
              >
                Question Set
              </Typography>
              <Stack
                direction={{ sm: 'row' }}
                gap={2}
                sx={{
                  alignSelf: 'center',
                  display:
                    state.conditionCheck === 'view' || state.conditionCheck === 'edit'
                      ? 'none'
                      : 'inherit',
                }}
              >
                <Button
                  variant='contained'
                  onClick={preUploadQuestionFn}
                  disabled={saveEditValidation ? false : true}
                  startIcon={<CloudUploadIcon fontSize={'small'} />}
                  sx={{
                    bgcolor: saveEditValidation ? '#277B8C' : 'initial',
                    ':hover': { bgcolor: saveEditValidation ? '#277B8C' : 'initial' },
                    borderRadius: '6px',
                    fontSize: { xs: 9.5, sm: 10, md: 14 },
                    height: { xs: 32, md: 40 },
                    textTransform: 'capitalize',
                    fontFamily: 'Poppins',
                    justifyContent: 'flex-start',
                  }}
                >
                  Pre Uploaded Questions
                </Button>
                <Button
                  variant='contained'
                  disabled={saveEditValidation ? false : true}
                  onClick={previousQuestionFn}
                  startIcon={<DescriptionIcon fontSize={'small'} />}
                  sx={{
                    bgcolor: saveEditValidation ? '#496193' : 'initial',
                    ':hover': { bgcolor: saveEditValidation ? '#496193' : 'initial' },
                    borderRadius: '6px',
                    fontSize: { xs: 9.5, sm: 10, md: 14 },
                    height: { xs: 32, md: 40 },
                    textTransform: 'capitalize',
                    fontFamily: 'Poppins',
                    justifyContent: 'flex-start',
                  }}
                >
                  Previous Questions
                </Button>
                <Button
                  variant='contained'
                  disabled={saveEditValidation ? false : true}
                  onClick={() => setOpenBulkQue(true)}
                  startIcon={<ListAltIcon fontSize={'small'} />}
                  sx={{
                    bgcolor: Colors.globalColorThemeColor,
                    ':hover': { bgcolor: Colors.globalColorThemeColor },
                    borderRadius: '6px',
                    fontSize: { xs: 9.5, sm: 10, md: 14 },
                    height: { xs: 32, md: 40 },
                    fontFamily: 'Poppins',
                    textTransform: 'capitalize',
                    justifyContent: 'flex-start',
                  }}
                >
                  Bulk Questions Upload
                </Button>
              </Stack>
            </Stack>

            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
              gap={3}
              mt={3}
            >
              <IconButton
                onClick={() => preQueBtnJumpToQueFn('dec')}
                disabled={
                  state.conditionCheck === 'view' && queCurrentIndexVal !== 0
                    ? false
                    : queCurrentIndexVal === 0 || !saveEditValidation
                    ? true
                    : false
                }
                sx={{
                  alignSelf: 'flex-start',
                  p: 0.2,
                  bgcolor: Colors.globalColorThemeColor,
                  ':hover': { bgcolor: Colors.globalColorThemeColor },
                  color: 'white',
                  mt: 0.1,
                }}
              >
                <ArrowBackIcon fontSize='small' />
              </IconButton>
              <Stack
                direction={'row'}
                alignItems={'center'}
                justifyContent={'center'}
                gap={3}
                flexWrap={'wrap'}
              >
                {[...Array(queSet.length)].map((_, index) => (
                  <Button
                    key={index}
                    disabled={
                      state.conditionCheck === 'view'
                        ? false
                        : (greaterThan > index || queCurrentIndexVal === index) &&
                          saveEditValidation
                        ? false
                        : true
                    }
                    onClick={() => preQueBtnJumpToQueFn(index)}
                    sx={{
                      width: 'auto',
                      cursor: 'pointer',
                      pt: 1,
                      height: { md: 30 },
                      fontSize: { md: '0.875rem' },
                      border:
                        queCurrentIndexVal === index ? '1px solid #a385c6' : '1px solid #C8C8C8',
                      color: queCurrentIndexVal === index ? 'white' : '#000000DE',
                      fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                      fontWeight: 400,
                      bgcolor: queCurrentIndexVal === index ? '#a385c6' : 'initial',
                      ':hover': {
                        bgcolor: queCurrentIndexVal === index ? '#a385c6' : 'initial',
                      },
                      borderRadius: '4px',
                    }}
                  >
                    {index + 1}
                  </Button>
                ))}
              </Stack>
              <IconButton
                onClick={nextQueBtnFn}
                disabled={
                  state.conditionCheck === 'view' && queCurrentIndexVal + 1 !== queSet.length
                    ? false
                    : queCurrentIndexVal + 1 === queSet.length || !saveEditValidation
                    ? true
                    : false
                }
                sx={{
                  alignSelf: 'flex-end',
                  p: 0.2,
                  bgcolor: Colors.globalColorThemeColor,
                  ':hover': { bgcolor: Colors.globalColorThemeColor },
                  color: 'white',
                  mb: 1,
                }}
              >
                <ArrowForwardIcon fontSize='small' />
              </IconButton>
            </Stack>
            <Box mt={3}>
              <Stack direction={'row'} justifyContent={'space-between'}>
                <Typography sx={{ fontSize: 14, fontFamily: 'Poppins', fontWeight: 'bold' }}>
                  Question
                </Typography>
                <Typography>
                  {queCurrentIndexVal + 1} / {queSet.length}
                </Typography>
              </Stack>
              <TextField
                autoFocus
                disabled={
                  state.conditionCheck === 'view' ? true : saveEditValidation ? false : true
                }
                onChange={
                  state.medium === 'Tamil'
                    ? (e) => typeTamil(e, 0)
                    : (e) => setGetQueSetData((pre) => ({ ...pre, qn: e.target.value }))
                }
                onKeyPress={(e) => {
                  if (e.key === ' ') {
                    e.preventDefault();
                    setGetQueSetData((pre) => ({ ...pre, qn: `${qn} ` }));
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Backspace') {
                    e.preventDefault();
                    setGetQueSetData((pre) => ({ ...pre, qn: qn.slice(0, -1) }));
                  }
                }}
                value={qn}
                fullWidth
                rows={3}
                multiline
                sx={{ mt: 1 }}
              />
              <Typography sx={{ fontSize: 14, fontFamily: 'Poppins', fontWeight: 'bold', mt: 3 }}>
                Options
              </Typography>
              <Stack mt={1} gap={3}>
                {optionInputField.map((items, index) => (
                  <Stack direction={'row'} gap={1} alignItems={'center'} key={items}>
                    <Typography>{items.title}</Typography>
                    <TextField
                      disabled={
                        state.conditionCheck === 'view' ? true : saveEditValidation ? false : true
                      }
                      onChange={items.onChangeFn}
                      onKeyPress={state.medium === 'Tamil' ? items.onKeyPressFn : undefined}
                      onKeyDown={state.medium === 'Tamil' ? items.onKeyDownFn : undefined}
                      value={items.value}
                      size='small'
                      fullWidth
                    />
                    {index + 1 === parseInt(ans) ? (
                      <CheckCircleIcon
                        onClick={() =>
                          saveEditValidation &&
                          state.conditionCheck !== 'view' &&
                          setGetQueSetData((pre) => ({ ...pre, ans: index + 1 }))
                        }
                        sx={{ color: 'green', cursor: 'pointer' }}
                      />
                    ) : (
                      <CheckCircleOutlineIcon
                        onClick={() =>
                          saveEditValidation &&
                          state.conditionCheck !== 'view' &&
                          setGetQueSetData((pre) => ({ ...pre, ans: index + 1 }))
                        }
                        sx={{ cursor: 'pointer' }}
                      />
                    )}
                  </Stack>
                ))}
              </Stack>
            </Box>
          </Stack>

          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} mt={6}>
            <Stack
              direction={'row'}
              justifyContent={'flex-end'}
              alignItems={'center'}
              gap={{ xs: 5, md: 10 }}
              sx={{ width: '55%' }}
            >
              <Button
                onClick={() => preQueBtnJumpToQueFn('dec')}
                disabled={
                  state.conditionCheck === 'view' && queCurrentIndexVal !== 0
                    ? false
                    : queCurrentIndexVal === 0 || !saveEditValidation
                    ? true
                    : false
                }
                size='small'
                variant='contained'
                sx={{
                  bgcolor: '#40128B',
                  ':hover': { bgcolor: '#40128B' },
                  height: { xs: 40 },
                  width: 100,
                  borderRadius: '6px',
                  fontSize: 14,
                  textTransform: 'capitalize',
                  fontFamily: 'Poppins',
                }}
              >
                Previous
              </Button>
              <Button
                onClick={nextQueBtnFn}
                disabled={
                  state.conditionCheck === 'view' && queCurrentIndexVal + 1 !== queSet.length
                    ? false
                    : queCurrentIndexVal + 1 === queSet.length || !saveEditValidation
                    ? true
                    : false
                }
                size='small'
                variant='contained'
                sx={{
                  bgcolor: '#40128B',
                  ':hover': { bgcolor: '#40128B' },
                  height: { xs: 40 },
                  width: 100,
                  borderRadius: '6px',
                  fontSize: 14,
                  textTransform: 'capitalize',
                  fontFamily: 'Poppins',
                }}
              >
                Next
              </Button>
            </Stack>
            <Button
              onClick={sentDataFinishFn}
              disabled={
                queCurrentIndexVal + 1 === queSet.length && saveEditValidation ? false : true
              }
              size='small'
              variant='contained'
              sx={{
                bgcolor: '#004225',
                display: state.conditionCheck === 'view' ? 'none' : 'inherit',
                ':hover': { bgcolor: '#004225' },
                height: { xs: 40 },
                width: { xs: 120, sm: 200 },
                borderRadius: '6px',
                fontSize: 14,
                textTransform: 'capitalize',
                fontFamily: 'Poppins',
              }}
            >
              {state.conditionCheck === 'edit' ? 'Update' : 'Finish'}
            </Button>
          </Stack>
        </Box>
      )}
    </>
  );
}

export default QuestionSet;
