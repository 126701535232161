import { Stack, Typography } from '@mui/material';
import React from 'react';
import logo from '../pages/NavbarofTeachersPage/img/logo.png';

function GlobalLoading() {
  return (
    <>
      <Stack alignItems={'center'} justifyContent={'center'} gap={2} height={'100vh'}>
        <img src={logo} />
        <Typography variant='h6' sx={{ fontFamily: 'Poppins', fontWeight: 'bold' }}>
          Loading ...
        </Typography>
      </Stack>
    </>
  );
}

export default GlobalLoading;
