import { Button, Dialog, DialogContent, Divider, Slide, Stack, Typography } from '@mui/material';
import React, { Fragment } from 'react';
import { ScaleLoader } from 'react-spinners';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import Colors from '../../../utils/Colors';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import { useNavigate } from 'react-router-dom';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />;
});

function DialogsandMenus(props) {
  const navigate = useNavigate();
  const {
    loading,
    exitCheck,
    endTestDialogOpen,
    setEndTestDialogOpen,
    requestFullScreen,
    setExitCheck,
    confirmSubmissionFn,
    leaveFullScreenAlert,
    setLeaveFullScreenAlert,
    stuDetailsDialog,
    setStuDetailsDialog,
    studentProfileData,
    missingExam,
  } = props;
  const endTestContent =
    "Ending the test will submit your answers and you won't be able to make any changes. Please ensure you have completed all questions before proceeding.";

  return (
    <>
      {/* <Dialog
        open={loading}
        PaperProps={{
          style: {
            backgroundColor: 'white',
            width: '100px',
            boxShadow: 'none',
            borderRadius: '10px',
            backdropFilter: 'blur(5px)',
          },
        }}
      >
        <Stack alignItems={'center'} justifyContent={'center'} height={100}>
          <ScaleLoader color='#6B4696' height={45} width={5} />
        </Stack>
      </Dialog> */}
      <Dialog
        open={missingExam}
        TransitionComponent={Transition}
        TransitionProps={{ timeout: 1000 }}
        keepMounted
        aria-describedby='alert-dialog-slide-description'
        PaperProps={{
          sx: {
            backgroundColor: 'white',
            p: 3,
            borderRadius: '10px',
          },
        }}
      >
        <Stack alignItems={'center'} justifyContent={'center'}>
          <SentimentVeryDissatisfiedIcon sx={{ color: 'red', fontSize: 100 }} />
          <Typography variant='h6' sx={{ fontFamily: 'Poppins', mt: 2 }}>
            Time out, You are missing the exam
          </Typography>
          <Button
            variant='contained'
            onClick={() => navigate('/login')}
            sx={{
              mt: 2,
              width: 150,
              borderRadius: '5px',
              bgcolor: Colors.globalColorThemeColor,
              ':hover': { bgcolor: Colors.globalColorThemeColor },
            }}
          >
            ok
          </Button>
        </Stack>
      </Dialog>
      {exitCheck ? (
        <Fragment>
          <Dialog
            open={endTestDialogOpen}
            TransitionComponent={Transition}
            TransitionProps={{ timeout: 1000 }}
            keepMounted
            aria-describedby='alert-dialog-slide-description'
          >
            <Stack alignItems={'center'} sx={{ padding: '15px 15px' }}>
              <WarningAmberOutlinedIcon sx={{ fontSize: 100, color: '#FACEA8' }} />
              <Typography variant='h6' sx={{ fontWeight: 'bold', fontFamily: 'Poppins', mt: 2 }}>
                Are you sure you want to submit the test?
              </Typography>
              <DialogContent>
                <Typography sx={{ fontFamily: 'Poppins', textAlign: 'center' }}>
                  {endTestContent}
                </Typography>
              </DialogContent>
              <Stack direction={'row'} gap={5} justifyContent={'flex-end'} width={'100%'} mt={2}>
                <Button
                  variant='outlined'
                  onClick={() => {
                    setEndTestDialogOpen(false);
                    requestFullScreen();
                    setExitCheck(false);
                  }}
                  sx={{
                    textTransform: 'capitalize',
                    fontFamily: 'Poppins',
                    borderColor: Colors.globalColorThemeColor,
                    color: Colors.globalColorThemeColor,
                    ':hover': { borderColor: Colors.globalColorThemeColor },
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant='contained'
                  onClick={confirmSubmissionFn}
                  sx={{
                    textTransform: 'capitalize',
                    fontFamily: 'Poppins',
                    bgcolor: Colors.globalColorThemeColor,
                    ':hover': { bgcolor: Colors.globalColorThemeColor },
                  }}
                >
                  Confirm
                </Button>
              </Stack>
            </Stack>
          </Dialog>
          <Dialog
            open={stuDetailsDialog}
            TransitionComponent={Transition}
            TransitionProps={{ timeout: 1000 }}
            keepMounted
            aria-describedby='alert-dialog-slide-description'
            PaperProps={{
              style: {
                width: '370px',
                borderRadius: '10px', // Apply the blur effect
              },
            }}
          >
            <Stack alignItems={'center'} justifyContent={'center'} height={250}>
              <Stack sx={{ mt: '4px' }}>
                {Object.keys(studentProfileData).map((items, index) => (
                  <Stack justifyContent={'space-around'} sx={{ height: 50 }} key={items}>
                    <Stack direction={'row'} justifyContent={'space-between'} gap={3}>
                      <Typography variant='h6' noWrap sx={{ opacity: 0.5, fontFamily: 'Poppins' }}>
                        {items}
                      </Typography>
                      <Typography variant='h6' noWrap sx={{ fontFamily: 'Poppins' }}>
                        {studentProfileData[items]}
                      </Typography>
                    </Stack>
                    <Divider />
                  </Stack>
                ))}
              </Stack>
              <Typography
                variant='h6'
                sx={{ fontWeight: 'bold', fontFamily: 'Poppins', color: 'red' }}
              >
                {"Don't Leave Full Screen Mode"}
              </Typography>
              <Button
                variant='contained'
                autoFocus
                onClick={() => {
                  requestFullScreen();
                  setStuDetailsDialog(false);
                  setExitCheck(false);
                }}
                sx={{
                  mt: 1,
                  bgcolor: Colors.globalColorThemeColor,
                  width: 150,
                  borderRadius: '10px',
                  fontSize: 18,
                  mb: 1,
                  ':hover': { bgcolor: Colors.globalColorThemeColor },
                }}
              >
                Ok
              </Button>
            </Stack>
          </Dialog>
        </Fragment>
      ) : (
        <Dialog
          open={leaveFullScreenAlert}
          PaperProps={{
            style: {
              width: '360px',
              borderRadius: '10px', // Apply the blur effect
            },
          }}
        >
          <Stack alignItems={'center'} justifyContent={'center'} height={120}>
            <Typography
              variant='h6'
              sx={{ color: 'red', fontWeight: 'bold', fontFamily: 'Poppins' }}
            >
              {"Don't Leave Full Screen Mode"}
            </Typography>
            <Button
              variant='contained'
              autoFocus
              onClick={() => {
                requestFullScreen();
                setLeaveFullScreenAlert(false);
              }}
              sx={{
                mt: 2,
                bgcolor: Colors.globalColorThemeColor,
                ':hover': { bgcolor: Colors.globalColorThemeColor },
              }}
            >
              Ok
            </Button>
          </Stack>
        </Dialog>
      )}
    </>
  );
}

export default DialogsandMenus;
