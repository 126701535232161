import React, { useEffect, useRef, useState } from 'react';
import googleTransliterate from 'google-input-tool';
import { Menu, MenuItem, Skeleton, Stack, TextField } from '@mui/material';

const Thanglish = (props) => {
  const {
    searchTerm,
    setSearchTerm,
    anchorEl,
    setAnchorEl,
    examDetails,
    setExamDetails,
    textfieldValidation,
    getQueSetData,
    setGetQueSetData,
  } = props;
  const { test_name } = examDetails;
  const [resVal, setResVal] = useState([]);
  console.log(resVal);
  const [focusedIndex, setFocusedIndex] = useState(0);
  const open = Boolean(anchorEl);
  const textFieldRef = useRef(null);
  const focusedItemRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 6;
  const lastIndex = currentPage * recordsPerPage;
  console.log('lastIndex', lastIndex);
  const firstIndex = lastIndex - recordsPerPage;
  console.log('firstIndex', firstIndex);
  const records = resVal.slice(firstIndex, lastIndex);
  console.log('records', records);

  const handleClose = () => {
    setAnchorEl(null);
    setSearchTerm('');
    setCurrentPage(1);
    setFocusedIndex(0);
  };

  const returnToTextField = (items) => {
    if (textfieldValidation === 'test_name') {
      setExamDetails((prevExamDetails) => ({
        ...prevExamDetails,
        test_name: items ? `${test_name}${items}` : `${test_name}${records[focusedIndex]} `,
      }));
    } else if (textfieldValidation) {
      setGetQueSetData((pre) => ({
        ...pre,
        [textfieldValidation]: items
          ? `${getQueSetData[textfieldValidation]}${items}`
          : `${getQueSetData[textfieldValidation]}${records[focusedIndex]} `,
      }));
    }
  };

  //   const handleFirstSearch = (event) => {
  //     setAnchorEl(event.currentTarget);
  //     // const filterVal = searchTerm
  //     //   ? searchTerm.split(" ")
  //     //   : event.target.value.split(" ");
  //     // const sendData = filterVal[filterVal.length - 1];
  //     const getLastLetter = event.target.value;
  //     setSearchTerm(getLastLetter.charAt(getLastLetter.length - 1));
  //   };

  const handleSearch = (event) => {
    setCurrentPage(1);
    setFocusedIndex(0);
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    if (searchTerm === '') {
      handleClose();
    } else {
      let sourceText = searchTerm;
      let inputLanguage = 'ta-t-i0-und';
      let maxResult = 20;
      let request = new XMLHttpRequest();

      googleTransliterate(request, sourceText, inputLanguage, maxResult).then(function (response) {
        const updatedArr = response.map((innerArray) => innerArray[0]);
        const getLength = updatedArr.slice(firstIndex, lastIndex).length - 1;
        updatedArr.splice(getLength, 0, searchTerm);
        setResVal(updatedArr);
      });
    }
  }, [searchTerm]);

  useEffect(() => {
    if (anchorEl !== null) {
      setTimeout(() => {
        textFieldRef.current && textFieldRef.current.focus();
      }, 0);
    }
  }, [anchorEl]);

  useEffect(() => {
    if (focusedIndex !== null) {
      const focusedItem = focusedItemRef.current;

      if (focusedItem) {
        focusedItem.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
          inline: 'nearest',
        });
      }
    }
  }, [focusedIndex]);

  return (
    <Menu
      id='basic-menu'
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
      onKeyDown={(e) => {
        if (e.key === 'ArrowDown') {
          e.preventDefault();
          textFieldRef.current && textFieldRef.current.focus();
          if (records[focusedIndex] === resVal[resVal.length - 1]) {
            setCurrentPage(1);
            setFocusedIndex(0);
          } else if (resVal[lastIndex - 1] === records[focusedIndex]) {
            setCurrentPage(currentPage + 1);
            setFocusedIndex(0);
          } else {
            setFocusedIndex(records.length - 1 === focusedIndex ? 0 : focusedIndex + 1);
          }
        } else if (e.key === 'ArrowUp') {
          e.preventDefault();
          textFieldRef.current && textFieldRef.current.focus();
          if (records[0] === resVal[focusedIndex]) {
            return;
          } else if (focusedIndex === 0) {
            setCurrentPage(currentPage - 1);
            setFocusedIndex(5);
          } else {
            setFocusedIndex(focusedIndex === 0 ? records.length - 1 : focusedIndex - 1);
          }
        }
      }}
    >
      <TextField
        inputRef={textFieldRef}
        size={'small'}
        value={searchTerm}
        onKeyPress={(e) => {
          if (e.key === ' ' || e.key === 'Enter') {
            e.preventDefault();
            returnToTextField();
            handleClose();
            setSearchTerm('');
          }
        }}
        onKeyDown={(e) => {
          if (e.key === 'Backspace') {
            e.preventDefault();
            setCurrentPage(1);
            setFocusedIndex(0);
            setSearchTerm((prevValue) => prevValue.slice(0, -1));
          }
        }}
        onChange={handleSearch}
        sx={{
          border: 'none',
          '& .MuiOutlinedInput-input': {
            borderColor: 'transparent',
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'transparent',
            },
            '&:hover fieldset': {
              borderColor: 'transparent',
            },
            '&.Mui-focused fieldset': {
              borderColor: 'transparent',
            },
          },
        }}
      />
      {records.length !== 0 ? (
        records.map((items, index) => (
          <MenuItem
            ref={index === focusedIndex ? focusedItemRef : null}
            key={index}
            sx={{
              bgcolor: focusedIndex === index ? '#E0E0E0' : 'initial',
            }}
            onClick={() => {
              returnToTextField(items);
              handleClose();
              setSearchTerm('');
            }}
          >
            {`${index + 1}. ${items}`}
          </MenuItem>
        ))
      ) : (
        <Stack alignItems={'center'}>
          <Skeleton variant='rectangular' width={'95%'} height={50} sx={{ mt: 1 }} />
        </Stack>
      )}
    </Menu>
  );
};

export default Thanglish;
