import { Box, Button, Card, Divider, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import '@fontsource/poppins';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Studentpagenavbar from '../AssementPortal/components/ExamOverviewNavbar/ExamOverviewNavbar';
import './style.css';
import Colors from '../../utils/Colors';

function AssessmentSubmitPage() {
  const navigate = useNavigate();
  const [seconds, setSeconds] = useState(5);

  console.log(seconds);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setSeconds((prevSeconds) => {
        const stopTimer = prevSeconds - 1;
        if (stopTimer < 0) {
          clearInterval(intervalId);
          console.log('running');
          navigate('/login', { replace: true });
          localStorage.removeItem('accessToken');
          window.location.reload();
          return 0;
        }
        return stopTimer;
      });
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const studentProfileData = {
    'Student ID': 'Bell65464',
    'Student Name': `Ithris`,
    Class: '8',
  };

  const examNameSubjectName = [
    { title: 'Subject', value: 'தமிழ்' },
    { title: 'Assessment Topic', value: ' தமிழ் இன்பம்' },
  ];
  return (
    <>
      <Studentpagenavbar studentProfileData={studentProfileData} />
      <Stack
        alignItems={'center'}
        justifyContent={'center'}
        sx={{ height: { xs: '100vh', md: '90vh' } }}
      >
        <Stack justifyContent={'space-evenly'} height={'100%'} mt={10} sx={{ p: { xs: 2, sm: 0 } }}>
          <Stack
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-evenly'}
            gap={5}
            mt={0.5}
          >
            {examNameSubjectName.map((items, index) => (
              <Stack key={index}>
                <Typography
                  sx={{
                    fontFamily: 'Poppins',
                    color: 'grey',
                    fontSize: { xs: 13, sm: 15, md: 20 },
                  }}
                >
                  {items.title}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: 'Poppins',
                    fontSize: { xs: 18, sm: 20, md: 34 },
                    color: Colors.globalColorThemeColor,
                    fontWeight: 'bold',
                  }}
                >
                  {items.value}
                </Typography>
              </Stack>
            ))}
          </Stack>
          <Stack mt={0.5}>
            <Typography
              sx={{
                fontWeight: 'bold',
                textAlign: 'center',
                fontFamily: 'Poppins',
                fontSize: { xs: 15, sm: 20, md: 34 },
              }}
            >
              {'Your test has been submitted successfully !!!'}
            </Typography>
          </Stack>
          <Stack direction={'row'} gap={5} justifyContent={'center'} alignItems={'center'} mt={0.5}>
            <Typography
              sx={{
                textAlign: 'center',
                fontFamily: 'Poppins',
                fontSize: { xs: 14, sm: 18, md: 24 },
              }}
            >
              You will be exit automatically in {seconds}sec
            </Typography>
          </Stack>
          <Stack alignItems={'center'} mt={0.5}>
            <Button
              variant='contained'
              onClick={() => {
                navigate('/login', { replace: true });
                localStorage.removeItem('accessToken');
                window.location.reload();
              }}
              style={{
                backgroundColor: 'red',
                borderRadius: '8px',
                width: '180px',
                height: '50px',
              }}
            >
              Exit
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}

export default AssessmentSubmitPage;
