import { configureStore } from '@reduxjs/toolkit';
import studentReducer from './studentDataSlice';
import teacherProfileDataReducer from './teacherProfileDataSlice';

export default configureStore({
  reducer: {
    studentData: studentReducer,
    teacherProfileData: teacherProfileDataReducer,
  },
});
