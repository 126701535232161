import React from 'react';
import DialogContent from '@mui/material/DialogContent';
import { Box, Typography } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { yellow } from '@mui/material/colors';
function Selectstudentinformation(props) {
  const { backtoWholeStudentDetails, singleStudentDetails } = props;
  console.log('select', singleStudentDetails);
  const { first_name, section, medium, mob_no, email, guardian_name, guardian_contact } =
    singleStudentDetails;
  return (
    <Box>
      <Box
        sx={{
          marginTop: '15px',
          // width: { xs: '263px', sm: '478px' },
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            gap: '15px',
          }}
        >
          <KeyboardBackspaceIcon
            onClick={backtoWholeStudentDetails}
            style={{ cursor: 'pointer' }}
          />
          <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>Student Details </Typography>
        </Box>
        <Box
          sx={{
            gap: '22px',
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: 'white',
            flexWrap: 'wrap',
            marginTop: '25px',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
            <Typography
              sx={{
                fontSize: { xs: '14px', sm: '14px' },
                fontWeight: '400',
                color: '#808080',
              }}
            >
              Name{' '}
            </Typography>
            <Typography
              sx={{
                color: '#000000',
                fontWeight: 'bolder',
                fontSize: { xs: '15px', sm: '16px' },
              }}
            >
              {first_name}
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{ fontSize: { xs: '14px', sm: '14px' }, fontWeight: '400', color: '#808080' }}
            >
              Class
            </Typography>
            <Typography
              sx={{
                color: '#000000',
                fontSize: { xs: '15px', sm: '16px' },
                fontWeight: 'bolder',
              }}
            >
              {singleStudentDetails.class}
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{ fontSize: { xs: '14px', sm: '14px' }, fontWeight: '400', color: '#808080' }}
            >
              Section
            </Typography>
            <Typography
              sx={{
                color: '#000000',
                fontSize: { xs: '15px', sm: '16px' },
                fontWeight: 'bolder',
              }}
            >
              {section}
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{ fontSize: { xs: '14px', sm: '14px' }, fontWeight: '400', color: '#808080' }}
            >
              Medium
            </Typography>
            <Typography
              sx={{
                color: '#000000',
                fontSize: { xs: '15px', sm: '16px' },
                fontWeight: 'bolder',
              }}
            >
              {medium}
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{ fontSize: { xs: '14px', sm: '14px' }, fontWeight: '400', color: '#808080' }}
            >
              Mobile
            </Typography>
            <Typography
              sx={{
                color: '#000000',
                fontSize: { xs: '15px', sm: '16px' },
                fontWeight: 'bolder',
              }}
            >
              {mob_no}
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{ fontSize: { xs: '14px', sm: '14px' }, fontWeight: '400', color: '#808080' }}
            >
              Email
            </Typography>
            <Typography
              sx={{
                color: '#000000',
                fontSize: { xs: '15px', sm: '16px' },
                fontWeight: 'bolder',
              }}
            >
              {email}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ marginTop: '15px' }}>
          <Box>
            <Typography
              style={{
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: '#7b8bc4',
                color: '#ffffff',
                fontSize: '17px',
              }}
            >
              Guardian Details
            </Typography>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '15px',
                flexWrap: 'wrap',
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontSize: { xs: '14px', sm: '14px' },
                    fontWeight: '400',
                    color: '#808080',
                  }}
                >
                  Guardian Name
                </Typography>
                <Typography
                  sx={{
                    color: '#000000',
                    fontWeight: 'bolder',
                    fontSize: { xs: '15px', sm: '16px' },
                  }}
                >
                  {guardian_name}
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontSize: { xs: '14px', sm: '14px' },
                    fontWeight: '400',
                    color: '#808080',
                  }}
                >
                  Mobile
                </Typography>
                <Typography
                  sx={{
                    color: '#000000',
                    fontWeight: 'bolder',
                    fontSize: { xs: '15px', sm: '16px' },
                  }}
                >
                  {guardian_contact}
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontSize: { xs: '14px', sm: '14px' },
                    fontWeight: '400',
                    color: '#808080',
                  }}
                >
                  Email
                </Typography>
                <Typography
                  sx={{
                    color: '#000000',
                    fontWeight: 'bolder',
                    fontSize: { xs: '15px', sm: '16px' },
                  }}
                >
                  netcom@gmail.comscscsa
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Selectstudentinformation;
