export const content =
  'An assessment tool is a technique or method of evaluating information to determine how much a person knows and whether this knowledge aligns with the bigger picture of a theory or framework. Assessment methods differ based on context and purpose. For example, personality tests use your responses to discover character traits, and financial assessments measure how much you know about concepts like saving and investments.';
export const watchvideocontent =
  '    Watching our tutor’s teaching videos to understand the basic and core concepts';
export const takeassesmentcontent =
  'Take your assessments based on your interested subjects and topics as well. Here you can choose the difficulty levels of your questions.';
export const assignedvideos =
  'Teacher can able to assign the videos to the students who need to focus on their weaker subject and topics.';
export const improvescorecontent =
  'Improving your scoring after watching the suggested video by the teacher, and improve by watching more related videos and took the assessment often.';
