import {
  Avatar,
  Box,
  Button,
  Card,
  Divider,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import logo from './img/logo.png';
import LogoutIcon from '@mui/icons-material/Logout';
import Skeleton from '@mui/material/Skeleton';
import ProfilePic from './img/profilepic.jpg';
import '@fontsource/poppins';
import { useLocation, useNavigate } from 'react-router-dom';
import { ArrowDropDownIcon } from '@mui/x-date-pickers';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import {
  CurrentTeacherProfileData,
  changeTeacherProfileData,
} from '../../reduxStore/teacherProfileDataSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RemoveAccessToken } from '../../utils/APIClient';

function NavbarofTeachersPage() {
  const navigate = useNavigate();
  const teacherProfileData = useSelector(CurrentTeacherProfileData);
  const dispatch = useDispatch();
  console.log(teacherProfileData);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const stuDetails = {
    'Teacher ID': teacherProfileData.teacherID,
    'Teacher Name': teacherProfileData.first_name,
    Class: [...new Set(teacherProfileData.classDetails.map((e) => e.class))].join(',  '),
  };

  const logoutHandler = () => {
    RemoveAccessToken();
    dispatch(changeTeacherProfileData(''));
    navigate('/login');
  };
  return (
    <>
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Stack justifyContent={'space-between'} sx={{ width: { sm: '58%' } }}>
          <Box component={'img'} src={logo} sx={{ width: '50px', heigth: '50px' }} />
        </Stack>
        <Card
          onClick={handleOpenUserMenu}
          elevation={5}
          sx={{
            display: 'flex',
            alignItems: 'center',
            p: 1,
            borderRadius: '50px',
            border: '0.5px solid black',
            cursor: 'pointer',
          }}
        >
          <Stack direction={'row'} alignItems={'center'} gap={1}>
            <Avatar
              // src={ProfilePic}
              sx={{ bgcolor: teacherProfileData.color, width: '40px', height: '40px' }}
            >
              {teacherProfileData.first_name.at(0).toUpperCase()}
            </Avatar>
            <Typography sx={{ fontFamily: 'Poppins' }}>{teacherProfileData.first_name}</Typography>
            {anchorElUser ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </Stack>
        </Card>
      </Stack>

      <Menu
        sx={{ mt: '50px' }}
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <Stack sx={{ mt: '4px' }}>
          {Object.keys(stuDetails).map((items, index) => (
            <Stack justifyContent={'space-around'} sx={{ height: 40 }} key={index}>
              <Stack
                direction={'row'}
                justifyContent={'space-between'}
                gap={3}
                sx={{ margin: '0px 20px' }}
              >
                <Typography noWrap sx={{ opacity: 0.5, fontFamily: 'Poppins' }}>
                  {items}
                </Typography>
                <Typography noWrap sx={{ fontFamily: 'Poppins' }}>
                  {stuDetails[items]}
                </Typography>
              </Stack>
              <Divider />
            </Stack>
          ))}
          <Stack alignItems={'center'} mt={1} mb={0.5}>
            <Button
              onClick={logoutHandler}
              size='small'
              variant='contained'
              sx={{ width: '160px', bgcolor: 'red', ':hover': { bgcolor: 'red' } }}
            >
              {' '}
              <LogoutIcon fontSize='small' sx={{ mr: 0.5 }} /> Logout
            </Button>
          </Stack>
        </Stack>
      </Menu>
    </>
  );
}

export default NavbarofTeachersPage;
