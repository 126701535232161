import { Box, Divider, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import '@fontsource/poppins';
import { useLocation, useNavigate } from 'react-router-dom';
import ExamDetails from './components/ExamDetails';
import QuestionSet from './components/QuestionSet';
import DialogAndAlerts from './components/DialogAndAlerts';
import NavbarofTeachersPage from '../NavbarofTeachersPage';
import {
  CurrentTeacherProfileData,
  changeTeacherProfileData,
} from '../../reduxStore/teacherProfileDataSlice';
import { useDispatch, useSelector } from 'react-redux';
import GlobalLoading from '../../utils/GlobalLoading';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import dayjs from 'dayjs';
import {
  APIClient,
  RefreshToken,
  RefreshTokenSwalAlert,
  RemoveAccessToken,
} from '../../utils/APIClient';
import Thanglish from '../../utils/Thanglish';
import Swal from 'sweetalert2';

function QuestionUpload() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  console.log(state);
  const teacherProfileData = state.teacherProfileData;
  const [stopGlobalLoading, setStopGlobalLoading] = useState(false);
  const [backArrowButtonAlert, setBackArrowButtonAlert] = useState(false);

  //Tamil Typing State Values
  const [searchTerm, setSearchTerm] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [textfieldValidation, setTextfieldValidation] = useState('test_name');
  //Tamil Typing State Values

  const [examDetails, setExamDetails] = useState({
    test_name: '',
    total_number_of_questions: '',
    test_duration: '',
    startDateTime: '',
  });
  console.log(examDetails);

  const [getQueSetData, setGetQueSetData] = useState({
    qn: '',
    opt1: '',
    opt2: '',
    opt3: '',
    opt4: '',
    ans: '',
  });

  const [queSet, setQueSet] = useState();
  console.log(queSet);
  const [openBulkQue, setOpenBulkQue] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [openPreUploadQueDialog, setPreUploadQueDialog] = useState(false);
  const [preUploadQueSet, setPreUploadQueSet] = useState();
  const [openPreviousQueDialog, setPreviousQueDialog] = useState(false);
  const [previousQueSet, setPreviousQueSet] = useState();

  const [alertContent, setAlertContent] = useState(
    'You have increase total no of question values, so do you want previous questions',
  );

  const [queCurrentIndexVal, setQueCurrentIndexVal] = useState(0);
  const [saveEditValidation, setSaveEditValidation] = useState(false);

  let greaterThan = queSet && queSet.filter((obj) => Object.keys(obj).length > 1).length;

  const [checkAccess, setCheckAccess] = useState(0);

  const [openFinalConfirmation, setOpenFinalConfirmation] = useState(false);

  useEffect(() => {
    if (state.conditionCheck !== 'view') {
      window.addEventListener('beforeunload', alertUser);
    }
    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
  }, []);
  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = 'Are You Sure';
  };

  function reverseConvertDuration(formattedDuration) {
    const [hours, minutes] = formattedDuration.split(':');
    const currentDate = new Date();
    currentDate.setHours(parseInt(hours, 10));
    currentDate.setMinutes(parseInt(minutes, 10));
    return currentDate.toISOString();
  }

  const getQuestionAnswerData = async () => {
    try {
      const sentQuestionID = {
        questionSetID: state.questionSetID,
      };
      console.log(sentQuestionID);
      const response = await axios.post(
        `${APIClient().API_BASE_URL}/api/getQuestionAnswer`,
        sentQuestionID,
        APIClient().API_HEADERS,
      );
      console.log(response);
      const responseData = response.data;
      if (responseData.success) {
        const getExamDetails = responseData.data;
        console.log(getExamDetails);
        const getQueSet = responseData.data.questionSet;
        console.log(getQueSet);
        setExamDetails({
          test_name: getExamDetails.testName,
          total_number_of_questions: getExamDetails.questionsCount,
          test_duration: reverseConvertDuration(getExamDetails.duration),
          startDateTime: dayjs(getExamDetails.startDateTime),
        });
        const convertQnKey = getQueSet.map((question, index) => {
          const { ['qn' + (index + 1)]: qn, ...rest } = question;
          return { ...rest, qn };
        });
        setQueSet(convertQnKey);
        setGetQueSetData({
          qn: convertQnKey[0].qn,
          opt1: convertQnKey[0].opt1,
          opt2: convertQnKey[0].opt2,
          opt3: convertQnKey[0].opt3,
          opt4: convertQnKey[0].opt4,
          ans: convertQnKey[0].ans,
        });
        console.log(responseData);
        if (state.conditionCheck) {
          setSaveEditValidation(true);
          setStopGlobalLoading(true);
        } else {
          setStopGlobalLoading(true);
        }
      } else {
        navigate('/teacher-dashboard');
        enqueueSnackbar(responseData.error, { variant: 'error', preventDuplicate: true });
      }
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        const newTokens = await RefreshToken();
        console.log(newTokens);
        if (newTokens.success) {
          localStorage.setItem('accessToken', newTokens.newAccessToken);
          getQuestionAnswerData();
        } else {
          RefreshTokenSwalAlert(newTokens.error, navigate);
        }
      } else {
        RefreshTokenSwalAlert('catchError', navigate);
      }
    }
  };

  useEffect(() => {
    dispatch(changeTeacherProfileData(state.teacherProfileData));
    if (state.conditionCheck === 'view' || state.conditionCheck === 'edit') {
      console.log('view');
      getQuestionAnswerData();
    } else {
      setStopGlobalLoading(true);
    }
  }, []);
  return (
    <>
      {stopGlobalLoading ? (
        <Stack alignItems={'center'} mt={1} mb={5}>
          <Box width={'95%'}>
            <NavbarofTeachersPage />
            <ExamDetails
              state={state}
              queSet={queSet}
              setQueSet={setQueSet}
              queCurrentIndexVal={queCurrentIndexVal}
              getQueSetData={getQueSetData}
              saveEditValidation={saveEditValidation}
              setAlertContent={setAlertContent}
              setOpenAlert={setOpenAlert}
              setSaveEditValidation={setSaveEditValidation}
              examDetails={examDetails}
              setExamDetails={setExamDetails}
              setBackArrowButtonAlert={setBackArrowButtonAlert}
              setSearchTerm={setSearchTerm}
              setAnchorEl={setAnchorEl}
              setTextfieldValidation={setTextfieldValidation}
            />
            <Divider sx={{ mt: 3, bgcolor: 'grey' }} />
            <QuestionSet
              queSet={queSet}
              state={state}
              setQueSet={setQueSet}
              saveEditValidation={saveEditValidation}
              setOpenBulkQue={setOpenBulkQue}
              queCurrentIndexVal={queCurrentIndexVal}
              setQueCurrentIndexVal={setQueCurrentIndexVal}
              checkAccess={checkAccess}
              setCheckAccess={setCheckAccess}
              greaterThan={greaterThan}
              setOpenFinalConfirmation={setOpenFinalConfirmation}
              getQueSetData={getQueSetData}
              setGetQueSetData={setGetQueSetData}
              setPreUploadQueDialog={setPreUploadQueDialog}
              setPreviousQueDialog={setPreviousQueDialog}
              setPreUploadQueSet={setPreUploadQueSet}
              setPreviousQueSet={setPreviousQueSet}
              examDetails={examDetails}
              setSearchTerm={setSearchTerm}
              setAnchorEl={setAnchorEl}
              setTextfieldValidation={setTextfieldValidation}
            />
            <Thanglish
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              examDetails={examDetails}
              setExamDetails={setExamDetails}
              textfieldValidation={textfieldValidation}
              getQueSetData={getQueSetData}
              setGetQueSetData={setGetQueSetData}
            />
          </Box>
        </Stack>
      ) : (
        <GlobalLoading />
      )}

      <DialogAndAlerts
        state={state}
        open={open}
        openBulkQue={openBulkQue}
        setOpenBulkQue={setOpenBulkQue}
        queSet={queSet}
        setQueSet={setQueSet}
        setQueCurrentIndexVal={setQueCurrentIndexVal}
        setGetQueSetData={setGetQueSetData}
        setExamDetails={setExamDetails}
        openAlert={openAlert}
        setOpenAlert={setOpenAlert}
        alertContent={alertContent}
        setSaveEditValidation={setSaveEditValidation}
        examDetails={examDetails}
        openFinalConfirmation={openFinalConfirmation}
        setOpenFinalConfirmation={setOpenFinalConfirmation}
        backArrowButtonAlert={backArrowButtonAlert}
        setBackArrowButtonAlert={setBackArrowButtonAlert}
        openPreUploadQueDialog={openPreUploadQueDialog}
        setPreUploadQueDialog={setPreUploadQueDialog}
        openPreviousQueDialog={openPreviousQueDialog}
        setPreviousQueDialog={setPreviousQueDialog}
        previousQueSet={previousQueSet}
        preUploadQueSet={preUploadQueSet}
        setPreviousQueSet={setPreviousQueSet}
        setPreUploadQueSet={setPreUploadQueSet}
      />
    </>
  );
}

export default QuestionUpload;
