import Button from '@mui/material/Button';
import { fontSize } from '@mui/system';
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import './PageNotFoundStyle.css';
import { useNavigate } from 'react-router-dom';

function PageNotFound() {
  const navigate = useNavigate();
  const gotologinpage = () => {
    navigate('/login');
  };
  return (
    <>
      <Box>
        <Typography class='fourtypography'>404</Typography>
      </Box>

      <Box className='pagenotFoundMain'>
        <Box className='pagenotFoundSecondMain'>
          <Typography class='pagenotFoundTextDiv'>SORRY PAGE NOT FOUND</Typography>
          <Typography class='pagenotFoundsecTextDiv'>
            We’re sorry, the page you are looking for is OUT of the range of pages available on our
            site
          </Typography>
          <Button
            sx={{
              backgroundColor: 'yellow',
              color: 'black',
              '&:hover': { backgroundColor: 'yellow' },
            }}
            onClick={gotologinpage}
          >
            GO TO THE HOMEPAGE
          </Button>
        </Box>
      </Box>
    </>
  );
}

export default PageNotFound;
