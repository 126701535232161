import {
  Alert,
  Box,
  Button,
  Card,
  Checkbox,
  Dialog,
  DialogContent,
  IconButton,
  Skeleton,
  Slide,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import Colors from '../../../utils/Colors';
import DemoExcel from '../img/demoExcel.png';
import CloseIcon from '@mui/icons-material/Close';
import Swal from 'sweetalert2';
import * as xlsx from 'xlsx';
import Upload from '@mui/icons-material/Upload';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import HelpIcon from '@mui/icons-material/Help';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { preUploadQues } from '../../../utils/PreUploadQues';
import { APIClient, RefreshToken, RefreshTokenSwalAlert } from '../../../utils/APIClient';
import SearchIcon from '@mui/icons-material/Search';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />;
});

function DialogAndAlerts(props) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const inputExcelFormatErrorMsg = () =>
    enqueueSnackbar('Invalid excel sheet format', { variant: 'error', preventDuplicate: true });
  const {
    state,
    openBulkQue,
    setOpenBulkQue,
    queSet,
    setQueSet,
    setQueCurrentIndexVal,
    setGetQueSetData,
    setExamDetails,
    openAlert,
    setOpenAlert,
    alertContent,
    setSaveEditValidation,
    examDetails,
    openFinalConfirmation,
    setOpenFinalConfirmation,
    backArrowButtonAlert,
    setBackArrowButtonAlert,
    openPreUploadQueDialog,
    setPreUploadQueDialog,
    openPreviousQueDialog,
    setPreviousQueDialog,
    previousQueSet,
    setPreviousQueSet,
    preUploadQueSet,
    setPreUploadQueSet,
  } = props;
  const navigate = useNavigate();
  const alertTitle = 'Are you sure you want to modify data';
  const excelFormatCheck = ['Question', 'Option 1', 'Option 2', 'Option 3', 'Option 4', 'Answer'];
  const [excelDataJson, setExcelDataJson] = useState();
  const [searchPreUploadQuestion, setSearchPreUploadQuestion] = useState('');
  const [searchPreviousQuestion, setSearchPreviousQuestion] = useState('');
  const filteredPreUploadArray =
    preUploadQueSet &&
    preUploadQueSet.filter((e) =>
      e.testName.toLowerCase().includes(searchPreUploadQuestion.toLowerCase()),
    );
  const filteredPreviousArray =
    previousQueSet &&
    previousQueSet.filter((e) =>
      e.testName.toLowerCase().includes(searchPreviousQuestion.toLowerCase()),
    );
  const [checked, setChecked] = useState(false);
  const modifyDataConfirmtionFn = () => {
    if (checked) {
      const number = parseInt(examDetails.total_number_of_questions);
      setQueSet(Array(number).fill({}));
      setGetQueSetData({
        qn: '',
        opt1: '',
        opt2: '',
        opt3: '',
        opt4: '',
        ans: '',
      });
      setSaveEditValidation(true);
      setQueCurrentIndexVal(0);
      setOpenAlert(false);
    } else {
      if (examDetails.total_number_of_questions > queSet.length) {
        const remainingValue = parseInt(
          examDetails.total_number_of_questions - queSet.length.toString(),
        );
        const addExtraObjects = Array(remainingValue).fill({});
        setQueSet([...queSet, ...addExtraObjects]);
      } else {
        console.log(examDetails.total_number_of_questions);
        console.log(queSet);
        const reduceQueSet = queSet.slice(0, parseInt(examDetails.total_number_of_questions));
        console.log(reduceQueSet);
        setQueCurrentIndexVal(0);
        setQueSet(reduceQueSet);
        setGetQueSetData((pre) => ({ ...pre, qn: reduceQueSet[0].qn }));
        setGetQueSetData((pre) => ({ ...pre, opt1: reduceQueSet[0].opt1 }));
        setGetQueSetData((pre) => ({ ...pre, opt2: reduceQueSet[0].opt2 }));
        setGetQueSetData((pre) => ({ ...pre, opt3: reduceQueSet[0].opt3 }));
        setGetQueSetData((pre) => ({ ...pre, opt4: reduceQueSet[0].opt4 }));
        setGetQueSetData((pre) => ({ ...pre, ans: reduceQueSet[0].ans }));
      }

      setOpenAlert(false);
      setSaveEditValidation(true);
    }
    setChecked(false);
  };
  const excelDataConvertFn = () => {
    if (
      Object.keys(excelDataJson[0]).length !== 6 ||
      Object.keys(excelDataJson[0])
        .map((e) => excelFormatCheck.includes(e))
        .includes(false)
    ) {
      inputExcelFormatErrorMsg();
      return;
    }
    const convertToLowercase = excelDataJson.map((item) =>
      Object.keys(item).reduce((acc, key) => {
        acc[key.toLowerCase()] = item[key];
        return acc;
      }, {}),
    );
    const modifiedArray = convertToLowercase
      .map((item) => {
        const modifiedItem = {};
        for (const key in item) {
          if (key.includes('option')) {
            modifiedItem[key.replace(/\s+/g, '')] = item[key].trim();
          } else if (key === 'answer') {
            modifiedItem['ans'] = item[key];
          } else {
            modifiedItem[key] = item[key];
          }
        }
        return modifiedItem;
      })
      .map((item, index) => {
        const newItem = {};
        newItem['qn'] = item.question;
        newItem['opt1'] = item.option1;
        newItem['opt2'] = item.option2;
        newItem['opt3'] = item.option3;
        newItem['opt4'] = item.option4;
        newItem['ans'] = item.ans;
        return newItem;
      });
    console.log(modifiedArray);
    setQueSet(modifiedArray);
    setQueCurrentIndexVal(0);
    setGetQueSetData((pre) => ({ ...pre, qn: modifiedArray[0].qn }));
    setGetQueSetData((pre) => ({ ...pre, opt1: modifiedArray[0].opt1 }));
    setGetQueSetData((pre) => ({ ...pre, opt2: modifiedArray[0].opt2 }));
    setGetQueSetData((pre) => ({ ...pre, opt3: modifiedArray[0].opt3 }));
    setGetQueSetData((pre) => ({ ...pre, opt4: modifiedArray[0].opt4 }));
    setGetQueSetData((pre) => ({ ...pre, ans: modifiedArray[0].ans }));
    setExamDetails((prevExamDetails) => ({
      ...prevExamDetails,
      total_number_of_questions: modifiedArray.length,
    }));
    setOpenBulkQue(false);
  };

  const preUploadQuesFn = (getPreUploadQues) => {
    const { testName, questionSet } = getPreUploadQues;
    console.log(questionSet);
    const changeQnKey = questionSet.map((items, index) => {
      const addQn = {
        ...items,
        qn: items[`qn${index + 1}`],
      };
      delete addQn[`qn${index + 1}`];
      return addQn;
    });
    setQueSet(changeQnKey);
    setQueCurrentIndexVal(0);
    setGetQueSetData((pre) => ({
      ...pre,
      qn: changeQnKey[0].qn,
      opt1: changeQnKey[0].opt1,
      opt2: changeQnKey[0].opt2,
      opt3: changeQnKey[0].opt3,
      opt4: changeQnKey[0].opt4,
      ans: changeQnKey[0].ans,
    }));
    setExamDetails((prevExamDetails) => ({
      ...prevExamDetails,
      test_name: testName,
      total_number_of_questions: changeQnKey.length,
    }));
    setPreUploadQueDialog(false);
  };

  const previousQuestionFn = (getPreviousQue) => {
    const { testName, questionSet } = getPreviousQue;
    console.log(getPreviousQue);
    const changeQnKey = questionSet.map((items, index) => {
      const addQn = {
        ...items,
        qn: items[`qn${index + 1}`],
      };
      delete addQn[`qn${index + 1}`];
      return addQn;
    });
    console.log(changeQnKey);
    setQueSet(changeQnKey);
    setQueCurrentIndexVal(0);
    setGetQueSetData((pre) => ({
      ...pre,
      qn: changeQnKey[0].qn,
      opt1: changeQnKey[0].opt1,
      opt2: changeQnKey[0].opt2,
      opt3: changeQnKey[0].opt3,
      opt4: changeQnKey[0].opt4,
      ans: changeQnKey[0].ans,
    }));
    setExamDetails((prevExamDetails) => ({
      ...prevExamDetails,
      test_name: testName,
      total_number_of_questions: changeQnKey.length,
    }));
    setPreviousQueDialog(false);
  };

  const readExcel = async (e) => {
    const file = e.target.files[0];
    if (!file) {
      setExcelDataJson(null);
      return;
    }
    if (
      file.type === 'application/vnd.ms-excel' ||
      file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      console.log('enter');
      const data = await file.arrayBuffer(file);
      const excelfile = xlsx.read(data);
      const excelsheet = excelfile.Sheets[excelfile.SheetNames[0]];
      const exceljson = xlsx.utils.sheet_to_json(excelsheet);
      console.log(exceljson);
      setExcelDataJson(exceljson);
    } else {
      inputExcelFormatErrorMsg();
    }
  };

  const submitFn = async () => {
    try {
      setStatusofReponse('loading');
      const updatedArrayOfObjects = queSet.map((obj, index) => {
        const newKey = `qn${index + 1}`;
        const { qn, ...rest } = obj;
        return { ...rest, [newKey]: qn };
      });
      console.log(examDetails.test_duration);
      const durationConvert = new Date(examDetails.test_duration);
      console.log(durationConvert);
      const formattedDuration = `${durationConvert
        .getHours()
        .toString()
        .padStart(2, '0')}:${durationConvert.getMinutes().toString().padStart(2, '0')}:00`;
      console.log(formattedDuration);

      const requestData = {
        teacherID: state.teacherID,
        subjectID: state.subjectID,
        class: state.class,
        medium: state.medium.toLowerCase(),
        section: state.section,
        subject: state.subject,
        testName: examDetails.test_name,
        questionsCount: parseInt(examDetails.total_number_of_questions),
        duration: formattedDuration,
        startDateTime: examDetails.startDateTime,
        questionSet: updatedArrayOfObjects,
      };
      console.log(requestData);
      console.log(state);
      if (state.conditionCheck === 'edit') {
        requestData.questionSetID = state.questionSetID;
        console.log(requestData);
        const resUpdateQuestionAnswer = await axios.put(
          `${APIClient().API_BASE_URL}/api/updateQuestionAnswer`,
          requestData,
          APIClient().API_HEADERS,
        );
        console.log(resUpdateQuestionAnswer);
        if (resUpdateQuestionAnswer.data.success) {
          console.log(resUpdateQuestionAnswer);
          setStatusofReponse('success');
        } else {
          setStatusofReponse('initial');
          enqueueSnackbar(resUpdateQuestionAnswer.data.error, { variant: 'error' });
        }
      } else {
        console.log(requestData);
        const resAddQuestionAnswerSet = await axios.post(
          `${APIClient().API_BASE_URL}/api/addQuestionAnswerSet`,
          requestData,
          APIClient().API_HEADERS,
        );
        console.log(resAddQuestionAnswerSet);
        if (resAddQuestionAnswerSet.data.success) {
          setStatusofReponse('success');
        } else {
          setStatusofReponse('initial');
          enqueueSnackbar(resAddQuestionAnswerSet.data.error, { variant: 'error' });
        }
      }
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        const newTokens = await RefreshToken();
        console.log(newTokens);
        if (newTokens.success) {
          localStorage.setItem('accessToken', newTokens.newAccessToken);
          submitFn();
        } else {
          setOpenFinalConfirmation(false);
          RefreshTokenSwalAlert(newTokens.error, navigate);
        }
      } else {
        setOpenFinalConfirmation(false);
        RefreshTokenSwalAlert('catchError', navigate);
      }
    }
  };

  const [statusofResponse, setStatusofReponse] = useState('initial');
  const okButtonRef = useRef(null);

  const redirectTeacherDashboard = () => {
    navigate('/teacher-dashboard');
    setGetQueSetData({
      qn: '',
      opt1: '',
      opt2: '',
      opt3: '',
      opt4: '',
      ans: '',
    });
    setExamDetails({
      test_name: '',
      total_number_of_questions: '',
      test_duration: '',
      startDateTime: '',
    });
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      redirectTeacherDashboard();
    }
  };

  useEffect(() => {
    console.log(statusofResponse);
    if (statusofResponse === 'success' && okButtonRef.current) {
      okButtonRef.current.focus();
    }
  }, [statusofResponse]);
  return (
    <>
      <Dialog
        open={backArrowButtonAlert}
        TransitionComponent={Transition}
        TransitionProps={{ timeout: 1000 }}
        keepMounted
        aria-describedby='alert-dialog-slide-description'
        PaperProps={{ sx: { width: 500, borderRadius: '10px' } }}
      >
        <Stack alignItems={'center'} justifyContent={'space-evenly'} sx={{ height: 300 }}>
          <WarningAmberOutlinedIcon sx={{ fontSize: 100, color: '#FACEA8' }} />
          <Typography
            sx={{ fontFamily: 'Poppins', textAlign: 'center', fontWeight: 'bold', fontSize: 20 }}
          >
            Are you sure you want to leave?, maybe you have lose your changes
          </Typography>
          <Stack direction={'row'} gap={5}>
            <Button
              variant='outlined'
              onClick={() => setBackArrowButtonAlert(false)}
              sx={{
                width: 100,
                color: Colors.globalColorThemeColor,
                borderColor: Colors.globalColorThemeColor,
                ':hover': { borderColor: Colors.globalColorThemeColor },
              }}
            >
              Cancel
            </Button>
            <Button
              variant='contained'
              onClick={() => navigate('/teacher-dashboard')}
              sx={{
                width: 100,
                bgcolor: Colors.globalColorThemeColor,
                ':hover': { bgcolor: Colors.globalColorThemeColor },
              }}
            >
              Ok
            </Button>
          </Stack>
        </Stack>
      </Dialog>

      <Dialog
        open={openBulkQue}
        onClose={() => setOpenBulkQue(false)}
        TransitionComponent={Transition}
        TransitionProps={{ timeout: 1000 }}
        keepMounted
        aria-describedby='alert-dialog-slide-description'
        maxWidth='md'
        fullWidth
      >
        <Stack gap={2} justifyContent={'center'} alignItems={'center'} sx={{ px: 1, mb: 2, mt: 2 }}>
          <Typography variant='h5' sx={{ fontFamily: 'Poppins', fontWeight: 'bold' }}>
            Attention
          </Typography>
          <CloseIcon
            onClick={() => setOpenBulkQue(false)}
            sx={{ cursor: 'pointer', position: 'absolute', top: { xs: 20, md: 17 }, right: 15 }}
          />
          <Typography sx={{ fontFamily: 'Poppins', opacity: 10 }}>
            File Format Should be as per this,{' '}
            <Typography component={'span'} sx={{ fontWeight: 'bold' }}>
              {excelFormatCheck.join(', ')}
            </Typography>
          </Typography>
          <Box
            component={'img'}
            src={DemoExcel}
            onClick={() => {
              const newWindow = window.open('', '_blank');
              const img = newWindow.document.createElement('img');
              img.src = DemoExcel;
              img.style.maxWidth = '100%';
              newWindow.document.body.appendChild(img);
            }}
            sx={{ width: '100%', cursor: 'pointer' }}
          />
          <Typography
            sx={{
              fontSize: '20px',
              fontWeight: 'bold',
              textAlign: 'center',
            }}
          >
            Upload CSV File
          </Typography>
          <Stack direction={{ sm: 'row' }} alignItems={'center'} gap={2}>
            <input type='file' accept='.xlsx, .xls' onChange={(e) => readExcel(e)} />
            <Button
              onClick={excelDataConvertFn}
              disabled={excelDataJson ? false : true}
              startIcon={<Upload />}
              size='small'
              variant='contained'
              sx={{
                bgcolor: Colors.globalColorThemeColor,
                ':hover': { bgcolor: Colors.globalColorThemeColor },
                borderRadius: '5px',
              }}
            >
              Upload
            </Button>
          </Stack>
        </Stack>
      </Dialog>

      <Dialog
        open={openAlert}
        TransitionComponent={Transition}
        TransitionProps={{ timeout: 1000 }}
        keepMounted
        aria-describedby='alert-dialog-slide-description'
      >
        <Stack alignItems={'center'} sx={{ padding: '15px 15px' }}>
          <WarningAmberOutlinedIcon sx={{ fontSize: 100, color: '#FACEA8' }} />
          <Typography variant='h6' sx={{ fontWeight: 'bold', fontFamily: 'Poppins', mt: 2 }}>
            {alertTitle}
          </Typography>
          <DialogContent>
            <Typography sx={{ fontFamily: 'Poppins', textAlign: 'center' }}>
              {alertContent}
            </Typography>
          </DialogContent>
          <Stack
            direction={'row'}
            alignItems={'center'}
            gap={0}
            onClick={() => setChecked(!checked)}
            onKeyPress={(e) => e.key === 'Enter' && setChecked(!checked)}
            sx={{ cursor: 'pointer', userSelect: 'none' }}
          >
            <Checkbox checked={checked} inputProps={{ 'aria-label': 'controlled' }} />
            <Typography sx={{ fontFamily: 'Poppins' }}>I don`t want previous data</Typography>
          </Stack>
          <Stack direction={'row'} gap={5} justifyContent={'flex-end'} width={'100%'} mt={2}>
            <Button
              variant='outlined'
              onClick={() => {
                setSaveEditValidation(true);
                setExamDetails((prevExamDetails) => ({
                  ...prevExamDetails,
                  total_number_of_questions: queSet.length.toString(),
                }));
                setOpenAlert(false);
              }}
              sx={{
                textTransform: 'capitalize',
                fontFamily: 'Poppins',
                color: Colors.globalColorThemeColor,
                borderColor: Colors.globalColorThemeColor,
                ':hover': { borderColor: Colors.globalColorThemeColor },
              }}
            >
              Cancel
            </Button>
            <Button
              variant='contained'
              onClick={modifyDataConfirmtionFn}
              sx={{
                textTransform: 'capitalize',
                fontFamily: 'Poppins',
                bgcolor: Colors.globalColorThemeColor,
                ':hover': { bgcolor: Colors.globalColorThemeColor },
              }}
            >
              Confirm
            </Button>
          </Stack>
        </Stack>
      </Dialog>

      {/* PreUpload Question Dialog */}
      <Dialog
        open={openPreUploadQueDialog}
        TransitionComponent={Transition}
        TransitionProps={{ timeout: 1000 }}
        keepMounted
        aria-describedby='alert-dialog-slide-description'
        PaperProps={{ sx: { width: 500, borderRadius: '5px' } }}
      >
        <Stack
          gap={2}
          justifyContent={'center'}
          alignItems={'center'}
          sx={{ px: 1, mb: 2, pt: 2, pb: 2, bgcolor: '#277B8C', color: 'white' }}
        >
          <Typography
            sx={{ fontFamily: 'Poppins', fontWeight: 'bold', fontSize: { xs: 18, sm: 20 } }}
          >
            Pre Uploaded Questions
          </Typography>
          <CloseIcon
            onClick={() => {
              setPreUploadQueDialog(false);
              setPreUploadQueSet(null);
            }}
            sx={{
              cursor: 'pointer',
              position: 'absolute',
              top: { xs: 18, sm: 20 },
              right: { xs: 10, sm: 15 },
            }}
          />
        </Stack>
        <Stack alignItems={'center'}>
          <TextField
            size='small'
            value={searchPreUploadQuestion}
            onChange={(e) => setSearchPreUploadQuestion(e.target.value)}
            placeholder='Search Questions'
            sx={{
              width: { xs: '93%' },
              '& .MuiOutlinedInput-input': {
                fontSize: { sm: 12, lg: 15 },
                fontFamily: 'Poppins',
                pt: { xs: 0.8, md: 1.2 },
                pl: { sm: 1, md: 2 },
                pb: { xs: 0.8, md: 1.3 },
              },
              '& .MuiOutlinedInput-root': {
                pr: { sm: 0.5, md: 1 },
                '& fieldset': {
                  borderColor: '#277B8C',
                },
                '&:hover fieldset': {
                  borderColor: '#277B8C',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#277B8C',
                },
              },
            }}
            InputProps={{
              endAdornment: (
                <IconButton sx={{ p: 0 }}>
                  <SearchIcon />
                </IconButton>
              ),
            }}
          />
        </Stack>
        <Box
          sx={{
            maxHeight: '300px',
            overflowY: 'auto',
            '::-webkit-scrollbar': {
              width: '0.1em',
            },
            '::-webkit-scrollbar-thumb': {
              backgroundColor: 'transparent',
            },
            scrollbarWidth: 'thin',
            scrollbarColor: 'transparent transparent',
          }}
        >
          <Stack mb={3} alignItems={'center'} px={2}>
            {filteredPreUploadArray ? (
              filteredPreUploadArray.length === 0 ? (
                <Typography
                  sx={{
                    fontFamily: 'Poppins',
                    fontWeight: 'bold',
                    fontSize: { xs: 18, sm: 20 },
                    mt: 3,
                  }}
                >
                  No Data Found
                </Typography>
              ) : (
                filteredPreUploadArray.map((items, index) => (
                  <Card
                    elevation={5}
                    key={index}
                    sx={{ width: '95%', p: 0.8, border: '1px solid black', mt: 3 }}
                  >
                    <Stack
                      direction={'row'}
                      gap={0.5}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                    >
                      <Typography
                        sx={{
                          fontFamily: 'Poppins',
                          fontWeight: 300,
                          fontSize: { xs: 13, sm: 15 },
                          textAlign: 'start',
                        }}
                      >
                        {`${index + 1}. ${items.testName}`}
                      </Typography>
                      <Button
                        variant='contained'
                        size='small'
                        onClick={() => preUploadQuesFn(items)}
                        sx={{
                          bgcolor: '#277B8C',
                          ':hover': { bgcolor: '#277B8C' },
                        }}
                      >
                        Upload
                      </Button>
                    </Stack>
                  </Card>
                ))
              )
            ) : (
              <Skeleton variant='rectangular' width={'95%'} height={50} sx={{ mt: 3 }} />
            )}
          </Stack>
        </Box>
      </Dialog>

      {/* Previous Question Dialog */}
      <Dialog
        open={openPreviousQueDialog}
        TransitionComponent={Transition}
        TransitionProps={{ timeout: 1000 }}
        keepMounted
        aria-describedby='alert-dialog-slide-description'
        PaperProps={{ sx: { width: 500, borderRadius: '5px' } }}
      >
        <Stack
          gap={2}
          justifyContent={'center'}
          alignItems={'center'}
          sx={{ px: 1, mb: 2, pt: 2, pb: 2, bgcolor: '#496193', color: 'white' }}
        >
          <Typography
            sx={{ fontFamily: 'Poppins', fontWeight: 'bold', fontSize: { xs: 18, sm: 20 } }}
          >
            Previous Questions
          </Typography>
          <CloseIcon
            onClick={() => {
              setPreviousQueDialog(false);
              setPreviousQueSet(null);
            }}
            sx={{
              cursor: 'pointer',
              position: 'absolute',
              top: { xs: 18, sm: 20 },
              right: { xs: 10, sm: 15 },
            }}
          />
        </Stack>
        <Stack alignItems={'center'}>
          <TextField
            size='small'
            value={searchPreviousQuestion}
            onChange={(e) => setSearchPreviousQuestion(e.target.value)}
            placeholder='Search Questions'
            sx={{
              width: { xs: '97%' },
              '& .MuiOutlinedInput-input': {
                fontSize: { xs: 15 },
                fontFamily: 'Poppins',
                pt: { xs: 1.2 },
                pl: { sm: 2 },
                pb: { xs: 1.3 },
              },
              '& .MuiOutlinedInput-root': {
                pr: { sm: 0.5, md: 1.2 },
                '& fieldset': {
                  borderColor: '#496193',
                },
                '&:hover fieldset': {
                  borderColor: '#496193',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#496193',
                },
              },
            }}
            InputProps={{
              endAdornment: (
                <IconButton sx={{ p: 0 }}>
                  {searchPreviousQuestion ? (
                    <CloseIcon onClick={() => setSearchPreviousQuestion('')} />
                  ) : (
                    <SearchIcon />
                  )}
                </IconButton>
              ),
            }}
          />
        </Stack>
        <Box
          sx={{
            maxHeight: '300px',
            overflowY: 'auto',
            '::-webkit-scrollbar': {
              width: '0.1em',
            },
            '::-webkit-scrollbar-thumb': {
              backgroundColor: 'transparent',
            },
            scrollbarWidth: 'thin',
            scrollbarColor: 'transparent transparent',
          }}
        >
          <Stack mb={3} alignItems={'center'} justifyContent={'center'}>
            {filteredPreviousArray ? (
              filteredPreviousArray.length === 0 ? (
                <Typography
                  sx={{
                    fontFamily: 'Poppins',
                    fontWeight: 'bold',
                    fontSize: { xs: 18, sm: 20 },
                    mt: 3,
                  }}
                >
                  No Data Found
                </Typography>
              ) : (
                filteredPreviousArray.map((items, index) => (
                  <Card
                    elevation={5}
                    key={index}
                    sx={{ width: '92%', p: 0.8, border: '1px solid black', mt: 3 }}
                  >
                    <Stack
                      direction={'row'}
                      gap={0.5}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                    >
                      <Typography
                        sx={{
                          fontFamily: 'Poppins',
                          fontWeight: 300,
                          fontSize: { xs: 13, sm: 15 },
                          textAlign: 'start',
                        }}
                      >
                        {`${index + 1}. ${items.testName}`}
                      </Typography>
                      <Button
                        variant='contained'
                        size='small'
                        onClick={() => previousQuestionFn(items)}
                        sx={{
                          bgcolor: '#496193',
                          ':hover': { bgcolor: '#496193' },
                        }}
                      >
                        Upload
                      </Button>
                    </Stack>
                  </Card>
                ))
              )
            ) : (
              <Skeleton variant='rectangular' width={'95%'} height={50} sx={{ mt: 3 }} />
            )}
          </Stack>
        </Box>
      </Dialog>

      {/* final confirmation Dialog */}
      <Dialog
        open={openFinalConfirmation}
        TransitionComponent={Transition}
        TransitionProps={{ timeout: 1000 }}
        keepMounted
        aria-describedby='alert-dialog-slide-description'
        PaperProps={{ sx: { borderRadius: '10px' } }}
      >
        <Stack
          alignItems={'center'}
          sx={{
            padding:
              statusofResponse !== 'initial'
                ? { xs: '15px 25px', sm: '15px 25px' }
                : { xs: '15px 25px', sm: '15px 25px' },
          }}
        >
          {statusofResponse === 'loading' ? (
            <AutorenewIcon sx={{ fontSize: { xs: 70, sm: 100 }, color: 'black' }} />
          ) : statusofResponse === 'success' ? (
            <CheckCircleIcon sx={{ fontSize: { xs: 70, sm: 100 }, color: 'green' }} />
          ) : (
            <HelpIcon sx={{ fontSize: { xs: 70, sm: 100 }, color: '#FACEA8' }} />
          )}
          <Typography
            sx={{
              fontWeight: 'bold',
              textAlign: 'center',
              fontFamily: 'Poppins',
              mt: 2,
              fontSize: { xs: 18, sm: 25 },
            }}
          >
            {statusofResponse === 'success'
              ? state.conditionCheck === 'edit'
                ? 'Successfully Updated Question Set'
                : 'Successfully Stored Question Set'
              : statusofResponse === 'loading'
              ? "Please wait don't refresh the page..."
              : 'Are you sure complete all?'}
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Poppins',
              fontSize: { xs: 'initial' },
              textAlign: 'center',
              mt: 2,
              display:
                statusofResponse === 'success' || statusofResponse === 'loading'
                  ? 'none'
                  : 'initial',
            }}
          >
            Save your all works and you will be redirect teacher dashboard
          </Typography>
          {statusofResponse !== 'initial' ? (
            <Stack mt={{ xs: 3, sm: 5 }}>
              <Button
                disabled={statusofResponse === 'loading' ? true : false}
                variant='contained'
                onClick={redirectTeacherDashboard}
                onKeyPress={handleKeyPress}
                ref={okButtonRef}
                sx={{
                  display: statusofResponse === 'success' ? 'initial' : 'none',
                  textTransform: 'capitalize',
                  fontFamily: 'Poppins',
                  bgcolor: Colors.globalColorThemeColor,
                  ':hover': { bgcolor: Colors.globalColorThemeColor },
                  width: 100,
                  fontSize: 18,
                }}
              >
                OK
              </Button>
            </Stack>
          ) : (
            <Stack
              direction={'row'}
              gap={5}
              justifyContent={{ xs: 'center', sm: 'flex-end' }}
              width={'100%'}
              mt={{ xs: 3, sm: 5 }}
            >
              <Button
                variant='outlined'
                onClick={() => setOpenFinalConfirmation(false)}
                sx={{
                  textTransform: 'capitalize',
                  fontFamily: 'Poppins',
                  color: Colors.globalColorThemeColor,
                  borderColor: Colors.globalColorThemeColor,
                  ':hover': { borderColor: Colors.globalColorThemeColor },
                }}
              >
                Cancel
              </Button>
              <Button
                variant='contained'
                onClick={submitFn}
                sx={{
                  textTransform: 'capitalize',
                  fontFamily: 'Poppins',
                  bgcolor: Colors.globalColorThemeColor,
                  ':hover': { bgcolor: Colors.globalColorThemeColor },
                }}
              >
                Confirm
              </Button>
            </Stack>
          )}
        </Stack>
      </Dialog>
    </>
  );
}

export default DialogAndAlerts;
