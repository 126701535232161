import React from 'react';
import './style.css';
import Avatar from '@mui/material/Avatar';
import { Divider, Menu, MenuItem, Stack, Typography, Button, Card } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import '@fontsource/poppins';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

function StudentProfile(props) {
  const navigate = useNavigate();
  const {
    quizmt,
    examSessionProps,
    stuDetailsDialog,
    exitFullScreen,
    setStuDetailsDialog,
    studentProfileData,
    beforeUnloadListener,
  } = props;
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    if (examSessionProps) {
      exitFullScreen();
      setStuDetailsDialog(true);
    } else {
      setAnchorElUser(event.currentTarget);
    }
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <>
      <Card
        onClick={handleOpenUserMenu}
        elevation={5}
        sx={{
          display: 'flex',
          alignItems: 'center',
          p: 0.5,
          borderRadius: '50px',
          border: '0.5px solid black',
          cursor: 'pointer',
        }}
      >
        <Stack direction={'row'} alignItems={'center'} gap={{ xs: 0, sm: 2, md: 4, lg: 5 }}>
          <Avatar
            sx={{
              bgcolor: 'blue',
              width: { xs: 25, lg: 45 },
              height: { xs: 25, lg: 45 },
            }}
          >
            {studentProfileData['Student Name'].charAt(0).toUpperCase()}
          </Avatar>
          <Stack sx={{ display: { xs: 'none', sm: 'initial' } }}>
            <Typography
              sx={{ fontFamily: 'Poppins', fontWeight: 'bold', fontSize: { xs: 11, lg: 15 } }}
            >
              {studentProfileData['Student Name']}
            </Typography>
            <Typography sx={{ fontFamily: 'Poppins', fontSize: { xs: 10, lg: 12 }, opacity: 5 }}>
              {studentProfileData['Class']}th
            </Typography>
          </Stack>
          {anchorElUser || stuDetailsDialog ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </Stack>
      </Card>

      <Menu
        sx={{ mt: quizmt ? quizmt : '45px' }}
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <Stack sx={{ mt: '4px' }}>
          {Object.keys(studentProfileData).map((items, index) => (
            <Stack justifyContent={'space-around'} sx={{ height: 40 }} key={items}>
              <Stack
                direction={'row'}
                justifyContent={'space-between'}
                gap={3}
                sx={{ margin: '0px 20px' }}
              >
                <Typography noWrap sx={{ opacity: 0.5, fontFamily: 'Poppins' }}>
                  {items}
                </Typography>
                <Typography noWrap sx={{ fontFamily: 'Poppins' }}>
                  {studentProfileData[items]}
                </Typography>
              </Stack>
              <Divider />
            </Stack>
          ))}
          <Stack alignItems={'center'} mt={1} mb={0.5}>
            <Button
              onClick={() => {
                window.removeEventListener('beforeunload', beforeUnloadListener);
                navigate('/login', { replace: true });
                window.location.reload();
                localStorage.removeItem('accessToken');
              }}
              size='small'
              variant='contained'
              sx={{
                width: '160px',
                bgcolor: 'red',
                ':hover': { bgcolor: 'red' },
              }}
            >
              {' '}
              <LogoutIcon fontSize='small' sx={{ mr: 0.5 }} /> Logout
            </Button>
          </Stack>
        </Stack>
      </Menu>
    </>
  );
}
export default StudentProfile;
