import { Button, Grid, IconButton, Stack, TextField, Typography } from '@mui/material';
import { DateTimePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import Colors from '../../../utils/Colors';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import '@fontsource/poppins';
import { useSnackbar } from 'notistack';

function ExamDetails(props) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const emptyInputErrorMsg = () =>
    enqueueSnackbar('Please Fill All Texfields', { variant: 'error', preventDuplicate: true });
  const invalidInputErrorMsg = () =>
    enqueueSnackbar('Please enter valid date and time', {
      variant: 'error',
      preventDuplicate: true,
    });

  const {
    queSet,
    setQueSet,
    queCurrentIndexVal,
    getQueSetData,
    examDetails,
    setExamDetails,
    state,
    saveEditValidation,
    setAlertContent,
    setOpenAlert,
    setSaveEditValidation,
    setBackArrowButtonAlert,
    setAnchorEl,
    setSearchTerm,
    setTextfieldValidation,
  } = props;
  console.log(state);
  console.log(examDetails);
  const navigate = useNavigate();
  const currentDate = dayjs();

  const { test_name, total_number_of_questions, test_duration, startDateTime } = examDetails;

  const typeEnglish = (e) =>
    setExamDetails((prevExamDetails) => ({
      ...prevExamDetails,
      test_name: e.target.value,
    }));

  const typeTamil = (event) => {
    setTextfieldValidation('test_name');
    setAnchorEl(event.currentTarget);
    const getLastLetter = event.target.value;
    setSearchTerm(getLastLetter.charAt(getLastLetter.length - 1));
  };

  const inputFieldsWithTitle = [
    {
      title: 'Test Name',
      placeholder: 'Enter Test Topic',
      onChangeFn: state.medium === 'Tamil' ? typeTamil : typeEnglish,
      onKeyPressFn: (e) => {
        if (e.key === ' ') {
          e.preventDefault();
          setExamDetails((prevExamDetails) => ({
            ...prevExamDetails,
            test_name: `${test_name} `,
          }));
        }
      },
      onKeyDownFn: (e) => {
        if (e.key === 'Backspace') {
          e.preventDefault();
          setExamDetails((prevExamDetails) => ({
            ...prevExamDetails,
            test_name: test_name.slice(0, -1),
          }));
        }
      },
      value: test_name,
    },
    {
      title: 'Total Number of Questions',
      placeholder: 'Enter Question Count',
      onChangeFn: (e) =>
        setExamDetails((prevExamDetails) => ({
          ...prevExamDetails,
          total_number_of_questions: e.target.value,
        })),
      value: total_number_of_questions,
    },
    {
      title: 'Test Duration',
      onChangeFn: (e) =>
        setExamDetails((prevExamDetails) => ({
          ...prevExamDetails,
          test_duration: e,
        })),
      value: test_duration,
    },
    {
      title: 'Start Date & Time',
      onChangeFn: (e) =>
        setExamDetails((prevExamDetails) => ({
          ...prevExamDetails,
          startDateTime: e,
        })),
      value: startDateTime,
    },
  ];

  const saveBtnFn = () => {
    if (total_number_of_questions === '') {
      emptyInputErrorMsg();
      return;
    } else if (total_number_of_questions === '0') {
      emptyInputErrorMsg();
      return;
    } else if (queSet && total_number_of_questions > queSet.length) {
      setAlertContent(
        'You have increase total no of question values, so do you want previous questions',
      );
      setOpenAlert(true);
      return;
    } else if (queSet && total_number_of_questions < queSet.length) {
      setAlertContent(
        `You have reduce total no of question values, maybe you have lose last ${
          queSet.length.toString() - total_number_of_questions
        } datas, do you want previous questions`,
      );
      setOpenAlert(true);
      return;
    }
    const { startDateTime, ...newObject } = examDetails;
    const dateObject = new Date(newObject.test_duration);
    const hours = dateObject.getUTCHours();
    const minutes = dateObject.getUTCMinutes();
    const format = 'DD/MM/YYYY HH:mm:ss';
    if (Object.values(newObject).includes('') === true || (hours === 18 && minutes === 30)) {
      emptyInputErrorMsg();
    } else if (startDateTime === '' || startDateTime.isValid() === false) {
      invalidInputErrorMsg();
    } else if (dayjs(startDateTime, format).isBefore(dayjs(currentDate, format))) {
      invalidInputErrorMsg();
    } else {
      if (queSet) {
        setSaveEditValidation(true);
        return;
      } else {
        const number = parseInt(total_number_of_questions);
        setQueSet(Array(number).fill({}));
        setSaveEditValidation(true);
        console.log(examDetails);
      }
    }
  };

  const editBtnFn = () => {
    console.log(getQueSetData);
    const updateQueSet = queSet.map((items, index) =>
      index === queCurrentIndexVal ? { ...items, ...getQueSetData } : items,
    );
    console.log(updateQueSet);
    setQueSet(updateQueSet);
    setSaveEditValidation(false);
  };
  return (
    <>
      <Stack
        direction={{ sm: 'row' }}
        justifyContent={'space-between'}
        alignItems={{ sm: 'center' }}
        mt={2}
      >
        <Stack direction={'row'} gap={{ xs: 1, md: 3 }} alignItems={'center'}>
          <ArrowBackIcon
            onClick={() => {
              if (state.conditionCheck !== 'view') {
                if (queSet) {
                  setBackArrowButtonAlert(true);
                } else {
                  navigate('/teacher-dashboard');
                }
              } else {
                navigate('/teacher-dashboard');
              }
            }}
            sx={{
              fontSize: 20,
              color: 'black',
              cursor: 'pointer',
            }}
          />
          <Typography sx={{ fontSize: 18, fontFamily: 'Poppins', fontWeight: 'bold' }}>
            Upload Questions
          </Typography>
        </Stack>
        <Stack
          direction={'row'}
          gap={{ xs: 0.7, sm: 2, md: 3 }}
          justifyContent={{ xs: 'space-around', sm: 'initial' }}
          alignItems={'center'}
          sx={{ width: { xs: '100%', sm: 'initial' }, mt: { xs: 2, sm: 0 } }}
        >
          <Typography sx={{ fontSize: { xs: 16, sm: 18 }, fontFamily: 'Poppins' }}>
            Subject {''}
            <Typography
              component={'span'}
              sx={{
                fontSize: { xs: 16, sm: 18 },
                fontFamily: 'Poppins',
                fontWeight: 'bold',
                color: Colors.globalColorThemeColor,
              }}
            >
              - {state.subject}
            </Typography>
          </Typography>
          <Typography sx={{ fontSize: { xs: 16, sm: 18 }, fontFamily: 'Poppins' }}>
            Class {''}
            <Typography
              component={'span'}
              sx={{
                fontSize: { xs: 16, sm: 18 },
                fontFamily: 'Poppins',
                fontWeight: 'bold',
                color: Colors.globalColorThemeColor,
              }}
            >
              - {state.class}th Standard
            </Typography>
          </Typography>
        </Stack>
      </Stack>
      <Stack mt={3}>
        <Grid container spacing={2} alignItems={'flex-end'}>
          {inputFieldsWithTitle.map((items, index) => (
            <Grid item xs={6} sm={4} lg key={index}>
              <Stack>
                <Typography sx={{ mb: 1, fontSize: { xs: 12, sm: 14 }, fontFamily: 'Poppins' }}>
                  {items.title}
                </Typography>
                {(index === 0 || index === 1) && (
                  <TextField
                    value={items.value}
                    disabled={
                      state.conditionCheck === 'view' ? true : saveEditValidation ? true : false
                    }
                    autoFocus={index === 0}
                    placeholder={items.placeholder}
                    type={index === 1 ? 'number' : 'text'}
                    onChange={items.onChangeFn}
                    onKeyPress={
                      state.medium === 'Tamil' && index === 0 ? items.onKeyPressFn : undefined
                    }
                    onKeyDown={
                      state.medium === 'Tamil' && index === 0 ? items.onKeyDownFn : undefined
                    }
                    onInput={
                      index === 1
                        ? (e) => (e.target.value = e.target.value.slice(0, 2))
                        : (e) => e.target.value
                    }
                    size='small'
                  />
                )}
                {index === 2 && (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      value={dayjs(items.value)}
                      disabled={
                        state.conditionCheck === 'view' ? true : saveEditValidation ? true : false
                      }
                      ampm={false}
                      onChange={items.onChangeFn}
                      views={['hours', 'minutes']}
                      format='HH:mm'
                      slotProps={{
                        textField: {
                          size: 'small',
                          error: false,
                        },
                      }}
                    />
                  </LocalizationProvider>
                )}
                {index === 3 && (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      value={dayjs(items.value)}
                      disabled={
                        state.conditionCheck === 'view' ? true : saveEditValidation ? true : false
                      }
                      onChange={items.onChangeFn}
                      format='DD/MM/YYYY hh:mm A'
                      disablePast
                      slotProps={{
                        textField: {
                          size: 'small',
                          error:
                            items.value === ''
                              ? false
                              : dayjs(items.value, 'DD/MM/YYYY').isBefore(currentDate, 'day')
                              ? true
                              : false,
                        },
                      }}
                    />
                  </LocalizationProvider>
                )}
              </Stack>
            </Grid>
          ))}
          <Grid
            item
            xs={12}
            sm={4}
            lg
            sx={{ display: state.conditionCheck === 'view' ? 'none' : 'initial' }}
          >
            <Stack
              direction={'row'}
              gap={{ xs: 2, lg: 4 }}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Button
                onClick={saveBtnFn}
                fullWidth
                size='large'
                variant='contained'
                disabled={saveEditValidation}
                sx={{
                  textTransform: 'capitalize',
                  borderRadius: '6px',
                  fontSize: 14,
                  fontFamily: 'Poppins',
                  bgcolor: Colors.globalColorThemeColor,
                  ':hover': { bgcolor: Colors.globalColorThemeColor },
                }}
                startIcon={<SaveIcon />}
              >
                Save
              </Button>
              <Button
                disabled={saveEditValidation ? false : true}
                onClick={editBtnFn}
                fullWidth
                size='large'
                variant='contained'
                sx={{
                  textTransform: 'capitalize',
                  borderRadius: '6px',
                  fontSize: 14,
                  fontFamily: 'Poppins',
                  bgcolor: Colors.editBtnColor,
                  ':hover': { bgcolor: Colors.editBtnColor },
                }}
                startIcon={<EditIcon />}
              >
                Edit
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </>
  );
}

export default ExamDetails;
