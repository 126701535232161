const Colors = {
  // "globalColorThemeColor": "#FFBB37",

  globalColorThemeColor: '#6B4696',
  editBtnColor: '#E86A33',
  endtestBtnColor: '#F33636',
  clearBtnColor: '#FFB015',
};

export default Colors;
