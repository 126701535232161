import FirstRankStudent from '../TeachersDashboard/img/samplestuimg1.svg';
import SecondRankStudent from '../TeachersDashboard/img/samplestuimg2.svg';
import ThirdRankStudent from '../TeachersDashboard/img/samplestuimg3.svg';
import FirstRankLogo from '../TeachersDashboard/img/firstranklogo.svg';
import SecondRankLogo from '../TeachersDashboard/img/secondranklogo.svg';
import ThirdRankLogo from '../TeachersDashboard/img/thirdranklogo.svg';

export const RecentlyCompletedData = [
  {
    studentRankings: [
      {
        img: 'FirstRankStudent',
        name: 'Robertdsf',
        logo: FirstRankLogo,
      },
      {
        img: 'SecondRankStudent',
        name: 'Robertsdf',
        logo: SecondRankLogo,
      },
      {
        img: 'ThirdRankStudent',
        name: 'Robertsdf',
        logo: ThirdRankLogo,
      },
    ],
    testDetails: [
      {
        title: 'Subject',
        content: 'English',
      },
      {
        title: 'Date',
        content: '12/02/2023',
      },
      {
        title: 'Time',
        content: '12:00 PM',
      },
      {
        title: 'Class',
        content: '8th',
      },
      {
        title: 'Test Name',
        content: 'Term 1',
      },
    ],
  },
  {
    studentRankings: [
      {
        img: 'FourthRankStudent',
        name: 'Alice',
        logo: FirstRankLogo,
      },
      {
        img: 'FifthRankStudent',
        name: 'Bob',
        logo: SecondRankLogo,
      },
      {
        img: 'SixthRankStudent',
        name: 'Charlie',
        logo: ThirdRankLogo,
      },
    ],
    testDetails: [
      {
        title: 'Subject',
        content: 'Mathematics',
      },
      {
        title: 'Date',
        content: '14/02/2023',
      },
      {
        title: 'Time',
        content: '2:00 PM',
      },
      {
        title: 'Class',
        content: '7th',
      },
      {
        title: 'Test Name',
        content: 'Term 2',
      },
    ],
  },
  {
    studentRankings: [
      {
        img: 'SeventhRankStudent',
        name: 'David',
        logo: FirstRankLogo,
      },
      {
        img: 'EighthRankStudent',
        name: 'Eva',
        logo: SecondRankLogo,
      },
      {
        img: 'NinthRankStudent',
        name: 'Frank',
        logo: ThirdRankLogo,
      },
    ],
    testDetails: [
      {
        title: 'Subject',
        content: 'History',
      },
      {
        title: 'Date',
        content: '16/02/2023',
      },
      {
        title: 'Time',
        content: '3:00 PM',
      },
      {
        title: 'Class',
        content: '9th',
      },
      {
        title: 'Test Name',
        content: 'Term 3',
      },
    ],
  },
  {
    studentRankings: [
      {
        img: 'TenthRankStudent',
        name: 'George',
        logo: FirstRankLogo,
      },
      {
        img: 'EleventhRankStudent',
        name: 'Hannah',
        logo: SecondRankLogo,
      },
      {
        img: 'TwelfthRankStudent',
        name: 'Ian',
        logo: ThirdRankLogo,
      },
    ],
    testDetails: [
      {
        title: 'Subject',
        content: 'Physics',
      },
      {
        title: 'Date',
        content: '18/02/2023',
      },
      {
        title: 'Time',
        content: '4:00 PM',
      },
      {
        title: 'Class',
        content: '10th',
      },
      {
        title: 'Test Name',
        content: 'Term 4',
      },
    ],
  },
  {
    studentRankings: [
      {
        img: 'ThirteenthRankStudent',
        name: 'Jill',
        logo: FirstRankLogo,
      },
      {
        img: 'FourteenthRankStudent',
        name: 'Kevin',
        logo: SecondRankLogo,
      },
      {
        img: 'FifteenthRankStudent',
        name: 'Lana',
        logo: ThirdRankLogo,
      },
    ],
    testDetails: [
      {
        title: 'Subject',
        content: 'Chemistry',
      },
      {
        title: 'Date',
        content: '20/02/2023',
      },
      {
        title: 'Time',
        content: '5:00 PM',
      },
      {
        title: 'Class',
        content: '11th',
      },
      {
        title: 'Test Name',
        content: 'Term 5',
      },
    ],
  },
  {
    studentRankings: [
      {
        img: 'SixteenthRankStudent',
        name: 'Mike',
        logo: FirstRankLogo,
      },
      {
        img: 'SeventeenthRankStudent',
        name: 'Nina',
        logo: SecondRankLogo,
      },
      {
        img: 'EighteenthRankStudent',
        name: 'Oscar',
        logo: ThirdRankLogo,
      },
    ],
    testDetails: [
      {
        title: 'Subject',
        content: 'Biology',
      },
      {
        title: 'Date',
        content: '22/02/2023',
      },
      {
        title: 'Time',
        content: '1:00 PM',
      },
      {
        title: 'Class',
        content: '12th',
      },
      {
        title: 'Test Name',
        content: 'Term 6',
      },
    ],
  },
  {
    studentRankings: [
      {
        img: 'NineteenthRankStudent',
        name: 'Patty',
        logo: FirstRankLogo,
      },
      {
        img: 'TwentiethRankStudent',
        name: 'Quincy',
        logo: SecondRankLogo,
      },
      {
        img: 'TwentyFirstRankStudent',
        name: 'Rita',
        logo: ThirdRankLogo,
      },
    ],
    testDetails: [
      {
        title: 'Subject',
        content: 'Geography',
      },
      {
        title: 'Date',
        content: '24/02/2023',
      },
      {
        title: 'Time',
        content: '10:00 AM',
      },
      {
        title: 'Class',
        content: '6th',
      },
      {
        title: 'Test Name',
        content: 'Term 7',
      },
    ],
  },
];

export const UpcmoingAssessmentData = [
  {
    testName: 'EnglishTest',
    noofquestion: 50,
    schedule: '12-10-2023 12.30pm',
  },
  {
    testName: 'MathTest',
    noofquestion: 40,
    schedule: '13-11-2023 10.30am',
  },
  {
    testName: 'HistoryTest',
    noofquestion: 45,
    schedule: '14-10-2023 2.30pm',
  },
  {
    testName: 'ScienceTest',
    noofquestion: 55,
    schedule: '15-11-2023 3.30pm',
  },
  {
    testName: 'GeographyTest',
    noofquestion: 35,
    schedule: '16-10-2023 12.30pm',
  },
  {
    testName: 'PhysicsTest',
    noofquestion: 60,
    schedule: '17-11-2023 4.30pm',
  },
  {
    testName: 'ChemistryTest',
    noofquestion: 50,
    schedule: '18-11-2023 11.30am',
  },
  {
    testName: 'BiologyTest',
    noofquestion: 52,
    schedule: '19-12-2023 2.00pm',
  },
  {
    testName: 'LiteratureTest',
    noofquestion: 48,
    schedule: '20-11-2023 1.30pm',
  },
  {
    testName: 'MusicTest',
    noofquestion: 30,
    schedule: '21-08-2023 10.00am',
  },
  {
    testName: 'ArtTest',
    noofquestion: 25,
    schedule: '22-10-2023 9.30am',
  },
  {
    testName: 'PhysicalEducationTest',
    noofquestion: 40,
    schedule: '12-10-2023 3.00pm',
  },
  {
    testName: 'ComputerScienceTest',
    noofquestion: 55,
    schedule: '24-08-2023 12.00pm',
  },
  {
    testName: 'CivicEducationTest',
    noofquestion: 47,
    schedule: '25-08-2023 2.15pm',
  },
];

export const examHistroyData = [
  {
    testName:
      'EnglsiTest The translation only takes a few seconds and allows up to 500 characters to be ',
    schedule: '12/08/2023-12.30pm',
    noofquestion: 50,
    noofstudent: 30,
    attendees: 23,
  },
  {
    testName:
      'EnglsiTest The translation only takes a few seconds and allows up to 500 characters to be',
    schedule: '12/08/2023-12.30pm',
    noofquestion: 50,
    noofstudent: 30,
    attendees: 23,
  },
  {
    testName:
      'EnglsiTest The translation only takes a few seconds and allows up to 500 characters to be',
    schedule: '12/08/2023-12.30pm',
    noofquestion: 50,
    noofstudent: 30,
    attendees: 23,
  },
  {
    testName:
      'EnglsiTest The translation only takes a few seconds and allows up to 500 characters to be',
    schedule: '12/08/2023-12.30pm',
    noofquestion: 50,
    noofstudent: 30,
    attendees: 23,
  },
  {
    testName:
      'EnglsiTest The translation only takes a few seconds and allows up to 500 characters to be',
    schedule: '12/08/2023-12.30pm',
    noofquestion: 50,
    noofstudent: 30,
    attendees: 23,
  },
  {
    testName:
      'EnglsiTest The translation only takes a few seconds and allows up to 500 characters to be',
    schedule: '12/08/2023-12.30pm',
    noofquestion: 50,
    noofstudent: 30,
    attendees: 23,
  },
  {
    testName:
      'EnglsiTest The translation only takes a few seconds and allows up to 500 characters to be',
    schedule: '12/08/2023-12.30pm',
    noofquestion: 50,
    noofstudent: 30,
    attendees: 23,
  },
  {
    testName:
      'EnglsiTest The translation only takes a few seconds and allows up to 500 characters to be',
    schedule: '12/08/2023-12.30pm',
    noofquestion: 50,
    noofstudent: 30,
    attendees: 23,
  },
  {
    testName:
      'EnglsiTest The translation only takes a few seconds and allows up to 500 characters to be',
    schedule: '12/08/2023-12.30pm',
    noofquestion: 50,
    noofstudent: 30,
    attendees: 23,
  },
  {
    testName:
      'EnglsiTest The translation only takes a few seconds and allows up to 500 characters to be',
    schedule: '12/08/2023-12.30pm',
    noofquestion: 50,
    noofstudent: 30,
    attendees: 23,
  },
  {
    testName:
      'EnglsiTest The translation only takes a few seconds and allows up to 500 characters to be',
    schedule: '12/08/2023-12.30pm',
    noofquestion: 50,
    noofstudent: 30,
    attendees: 23,
  },

  {
    testName:
      'EnglsiTest The translation only takes a few seconds and allows up to 500 characters to be',
    schedule: '12/08/2023-12.30pm',
    noofquestion: 50,
    noofstudent: 30,
    attendees: 23,
  },
  {
    testName:
      'EnglsiTest The translation only takes a few seconds and allows up to 500 characters to be',
    schedule: '12/08/2023-12.30pm',
    noofquestion: 50,
    noofstudent: 30,
    attendees: 23,
  },
  {
    testName:
      'EnglsiTest The translation only takes a few seconds and allows up to 500 characters to be',
    schedule: '12/08/2023-12.30pm',
    noofquestion: 50,
    noofstudent: 30,
    attendees: 23,
  },
  {
    testName:
      'EnglsiTest The translation only takes a few seconds and allows up to 500 characters to be',
    schedule: '12/08/2023-12.30pm',
    noofquestion: 50,
    noofstudent: 30,
    attendees: 23,
  },
  {
    testName:
      'EnglsiTest The translation only takes a few seconds and allows up to 500 characters to be',
    schedule: '12/08/2023-12.30pm',
    noofquestion: 50,
    noofstudent: 30,
    attendees: 23,
  },
  {
    testName:
      'Maths The translation only takes a few seconds and allows up to 500 characters to be',
    schedule: '12/08/2023-12.30pm',
    noofquestion: 50,
    noofstudent: 30,
    attendees: 23,
  },
  {
    testName:
      'Tamil The translation only takes a few seconds and allows up to 500 characters to be',
    schedule: '12/08/2023-12.30pm',
    noofquestion: 50,
    noofstudent: 30,
    attendees: 23,
  },
  {
    testName:
      'EnglsiTest The translation only takes a few seconds and allows up to 500 characters to be',
    schedule: '12/08/2023-12.30pm',
    noofquestion: 50,
    noofstudent: 30,
    attendees: 23,
  },
  {
    testName:
      'EnglsiTest The translation only takes a few seconds and allows up to 500 characters to be',
    schedule: '12/08/2023-12.30pm',
    noofquestion: 50,
    noofstudent: 30,
    attendees: 23,
  },
  {
    testName:
      'EnglsiTest The translation only takes a few seconds and allows up to 500 characters to be',
    schedule: '12/08/2023-12.30pm',
    noofquestion: 50,
    noofstudent: 30,
    attendees: 23,
  },
  {
    testName:
      'EnglsiTest The translation only takes a few seconds and allows up to 500 characters to be',
    schedule: '12/08/2023-12.30pm',
    noofquestion: 50,
    noofstudent: 30,
    attendees: 23,
  },
  {
    testName:
      'EnglsiTest The translation only takes a few seconds and allows up to 500 characters to be',
    schedule: '12/08/2023-12.30pm',
    noofquestion: 50,
    noofstudent: 30,
    attendees: 23,
  },

  {
    testName:
      'EnglsiTest The translation only takes a few seconds and allows up to 500 characters to be',
    schedule: '12/08/2023-12.30pm',
    noofquestion: 50,
    noofstudent: 30,
    attendees: 23,
  },
  {
    testName:
      'EnglsiTest The translation only takes a few seconds and allows up to 500 characters to be',
    schedule: '12/08/2023-12.30pm',
    noofquestion: 50,
    noofstudent: 30,
    attendees: 23,
  },
  {
    testName:
      'EnglsiTest The translation only takes a few seconds and allows up to 500 characters to be',
    schedule: '12/08/2023-12.30pm',
    noofquestion: 50,
    noofstudent: 30,
    attendees: 23,
  },
  {
    testName:
      'EnglsiTest The translation only takes a few seconds and allows up to 500 characters to be',
    schedule: '12/08/2023-12.30pm',
    noofquestion: 50,
    noofstudent: 30,
    attendees: 23,
  },
  {
    testName:
      'socialScience The translation only takes a few seconds and allows up to 500 characters to be',
    schedule: '12/08/2023-12.30pm',
    noofquestion: 50,
    noofstudent: 30,
    attendees: 23,
  },
];
