import React, { useEffect, useState } from 'react';
import NetcomLogo from '../LoginPage/Images/netcom.png';
import GirlImage from '../LoginPage/Images/girl.png';
import AssessmentLogo from '../LoginPage/Images/assesslogo.png';
import TNLogo from '../LoginPage/Images/TamilNadu_Logo.png';
import CorporationLogo from '../LoginPage/Images/tvlcorporation.png';
import { Alert, Slide, Snackbar, TextField, Typography, Card, styled, Box } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import axios from 'axios';
import Colors from '../../utils/Colors';
import { enqueueSnackbar } from 'notistack';
import { APIClient } from '../../utils/APIClient';

const Root = styled(Box)({
  padding: '20px',
  display: 'flex',
  flexDirection: 'column',
  // justifyContent: 'space-between',
  height: '100vh',
  boxSizing: 'border-box',
});

const TopImgs = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  zIndex: 1,
});
const BodyBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '20px 60px',
  flexGrow: 1,
  '@media (max-width: 1100px)': {
    justifyContent: 'center',
    padding: '0px 60px',
    marginTop: '-50px',
    flexGrow: 0,
  },
  '@media (max-width: 480px)': {
    justifyContent: 'center',
    padding: '0px 60px',
    marginTop: '30px',
    flexGrow: 0,
  },
});
const MainImage = styled(Box)({
  display: 'block',
  flexBasis: 1,
  '@media (max-width: 1100px)': {
    display: 'none',
  },
});

const FooterBox = styled(Box)({
  display: 'none',
  justifyContent: 'space-between',
});

export const LoginBtn = styled('div')(({ loading }) => ({
  backgroundColor: loading ? 'white' : Colors.globalColorThemeColor,
  cursor: 'pointer',
  marginTop: '25px',
  borderRadius: '5px',
  fontSize: '18px',
  fontWeight: 'medium',
  height: '40px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: '-5px',
  opacity: '2',
  color: loading ? Colors.globalColorThemeColor : 'white',
  border: loading ? `1px solid ${Colors.globalColorThemeColor}` : 'initial',
  boxShadow: '2px 3px 4px rgba(0, 0, 0, 0.2)',
}));

const StyledCard = styled(Card)({
  display: 'flex',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  flexDirection: 'column',
  padding: '30px 20px',
  height: '100%',
  boxSizing: 'border-box',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
  width: '500px',
  '@media (max-width: 1100px)': {
    padding: '5px 20px',
    width: '400px',
    height: '400px',
  },
  '@media (max-width: 480px)': {
    padding: '5px 20px',
    width: '350px',
    height: '400px',
  },
});

const Title = styled(Typography)({
  fontSize: '40px',
  fontStyle: 'italic',
  color: '#7958a0',
  fontWeight: 600,
  '@media (max-width: 1100px)': {
    fontSize: '30px',
  },
});

const TabBox = styled(Box)({
  display: 'flex',
  margin: '30px 0px 30px 0px',
  gap: '25px',
  width: '100%',
  '@media (max-width: 1100px)': {
    fontSize: '30px',
    margin: '0px 0px 10px 0px',
  },
});

const TabBtn = styled(Box)(({ theme }) => ({
  fontSize: '20px',
  fontWeight: 500,
  width: '100%',
  height: '40px',
  backgroundColor: 'white',
  borderRadius: '6px',
  border: '1px solid black',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  '@media (max-width: 1100px)': {
    fontSize: '14px',
    height: '30px',
  },
}));

function Login() {
  const navigate = useNavigate();
  const [usernameerrormsg, setusernameerrormsg] = useState(false);
  const [usernameerror, setusernameerror] = useState('');
  const [Passworderrormsg, setPassworderrormsg] = useState(false);
  const [Passworderror, setPassworderror] = useState('');
  const [email, setEmail] = useState('');
  const [pass, setPass] = useState('');
  const [roll, setRoll] = useState('');
  const [date, setDate] = useState('');
  const [openvalid, setOpenvalid] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  // const { username, password } = useSelector((state) => state.teacherData);
  // const dispatch = useDispatch();

  const stuLogin = () => {
    if (loading) {
      return;
    }
    if (date === '') {
      setOpen(true);
    } else {
      setLoading(true);
      const reqData = {
        studentRegNo: roll,
        password: date,
        role: 'student',
      };
      axios
        .post(`${APIClient().API_BASE_URL}/api/login`, reqData)
        .then((response) => {
          console.log(response);
          if (response.data.success === true) {
            console.log(response);
            navigate('/assessment-portal', { state: response.data });
          } else {
            enqueueSnackbar(response.data.error, { variant: 'error', preventDuplicate: true });
            setLoading(false);
          }
        })
        .catch((error) => {
          enqueueSnackbar('Server Down', { variant: 'error', preventDuplicate: true });
          setLoading(false);
        });
    }
    history.pushState(null, null, location.href);
    window.onpopstate = function () {
      history.go(1);
    };
  };
  const changeEmail = (e) => {
    setEmail(e.target.value);
    setusernameerror(false);
    setusernameerrormsg(false);
  };
  const passwordChangeForTeacher = (e) => {
    setPass(e.target.value);
    setPassworderror(false);
    setPassworderrormsg(false);
  };

  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

  const teaLogin = () => {
    if (loading) {
      return;
    }
    if (email === '') {
      setusernameerrormsg('Please Enter Email');
      setusernameerror(true);
    } else if (!emailRegex.test(email)) {
      setusernameerrormsg('Please Enter Valid Mail');
      setusernameerror(true);
    } else if (pass === '') {
      setPassworderrormsg('Please Enter Password');
      setPassworderror(true);
    } else {
      setLoading(true);
      var datatosend = {
        email: email,
        password: pass,
        role: 'teacher',
      };
      axios
        .post(`${APIClient().API_BASE_URL}/api/login`, datatosend)
        .then((res) => {
          console.log(res);
          if (res.data.success) {
            localStorage.setItem('accessToken', res.data.accessToken);
            localStorage.setItem('refreshToken', res.data.refreshToken);
            navigate('/teacher-dashboard');
          } else {
            enqueueSnackbar(res.data.error, { variant: 'error', preventDuplicate: true });
            setLoading(false);
          }
        })
        .catch((error) => {
          enqueueSnackbar('Server Down', { variant: 'error', preventDuplicate: true });
          setLoading(false);
        });
    }
  };
  const [student, setStudent] = useState(true);
  const [teacher, setTeacher] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const handleStudent = () => {
    setTeacher(false);
    setStudent(true);
  };
  const handleTeacher = () => {
    setStudent(false);
    setTeacher(true);
  };

  useEffect(() => {
    if (localStorage.getItem('accessToken')) {
      navigate('/teacher-dashboard');
    }
  }, []);

  const style = {
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#6B4696',
      },
    },
  };
  return (
    <Root>
      <TopImgs>
        <img id='logo' src={AssessmentLogo} style={{ height: '50px', width: '50px' }} />
        <img id='logo' src={NetcomLogo} style={{ height: '50px', width: '50px' }} />
      </TopImgs>

      <BodyBox>
        <MainImage>
          <img id='girl' style={{ height: '500px' }} src={GirlImage} />
        </MainImage>
        <Box sx={{ height: '100%' }}>
          <StyledCard elevation={3}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Typography style={{ fontSize: '25px', fontWeight: 600 }}>Welcome to our</Typography>
              <Title>Assessment Tool</Title>
              {/* <Typography style={{ fontSize: '25px', fontWeight: 600 }}>Login</Typography> */}
            </Box>
            <Box sx={{ width: '100%' }}>
              <TabBox>
                <TabBtn
                  style={{
                    backgroundColor: student ? '#6B4696' : 'white',
                    borderColor: student ? '#6B4696' : null,
                    color: student ? 'white' : 'black',
                  }}
                  onClick={handleStudent}
                >
                  Student
                </TabBtn>
                <TabBtn
                  style={{
                    backgroundColor: teacher ? '#6B4696' : 'white',
                    borderColor: teacher ? '#6B4696' : null,
                    color: student ? 'black' : 'white',
                  }}
                  onClick={handleTeacher}
                >
                  Teacher
                </TabBtn>
              </TabBox>
              <Box
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {student && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-around',
                      width: '100%',
                      gap: '20px',
                    }}
                  >
                    <Box>
                      <Typography> Roll Number</Typography>
                      <TextField
                        autoFocus
                        onChange={(e) => setRoll(e.target.value)}
                        onKeyPress={(e) => e.key === 'Enter' && stuLogin()}
                        value={roll}
                        sx={style}
                        style={{
                          width: '100%',
                          borderRadius: '8px',
                        }}
                        size={'small'}
                      />
                    </Box>

                    <Box>
                      <Typography>Date Of Birth</Typography>
                      <TextField
                        sx={style}
                        type='date'
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                        onKeyPress={(e) => e.key === 'Enter' && stuLogin()}
                        style={{
                          width: '100%',
                          borderRadius: '8px',
                        }}
                        size={'small'}
                      />
                    </Box>
                  </div>
                )}
                {teacher && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-around',
                      width: '100%',
                      gap: '20px',
                    }}
                  >
                    <Box>
                      <Typography> Email</Typography>
                      <TextField
                        autoFocus
                        sx={style}
                        onChange={changeEmail}
                        onKeyPress={(e) => e.key === 'Enter' && teaLogin()}
                        value={email}
                        style={{
                          borderRadius: '8px',
                          width: '100%',
                        }}
                        error={usernameerror}
                        helperText={usernameerrormsg}
                        size={'small'}
                      />
                    </Box>
                    <Box>
                      <Typography>Password</Typography>

                      <TextField
                        sx={style}
                        error={Passworderror}
                        helperText={Passworderrormsg}
                        value={pass}
                        type={showPassword ? 'text' : 'password'}
                        onInput={(e) => (e.target.value = e.target.value.slice(0, 15))}
                        onChange={passwordChangeForTeacher}
                        onKeyPress={(e) => e.key === 'Enter' && teaLogin()}
                        style={{
                          borderRadius: '8px',
                          width: '100%',
                        }}
                        size={'small'}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>
                              <IconButton
                                aria-label='toggle password visibility'
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  </div>
                )}
                <LoginBtn
                  loading={loading}
                  variant='contained'
                  onClick={student ? stuLogin : teaLogin}
                >
                  {loading ? 'Logging in...' : 'Log in'}
                </LoginBtn>
              </Box>
            </Box>
            <Box>
              <Typography sx={{ color: 'grey' }}>Powered by Netcom Computers Pvt. Ltd.</Typography>
            </Box>
          </StyledCard>
        </Box>
      </BodyBox>

      <FooterBox>
        <img id='logo' src={TNLogo} style={{ height: '50px', width: '50px' }} />
        <img id='logo' src={CorporationLogo} style={{ height: '50px', width: '50px' }} />
      </FooterBox>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openvalid}
        autoHideDuration={4000}
        onClose={() => setOpenvalid(false)}
      >
        <Slide direction='down' in={openvalid}>
          <Alert severity='error' sx={{ width: '100%', borderRadius: '50px' }}>
            Enter 6 digit number
          </Alert>
        </Slide>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={open}
        autoHideDuration={4000}
        onClose={() => setOpen(false)}
        sx={{ width: '20%' }}
      >
        <Slide direction='down' in={open}>
          <Alert
            severity='error'
            sx={{ width: '100%', borderRadius: '10px', display: 'flex', justifyContent: 'center' }}
          >
            Fields not be empty
          </Alert>
        </Slide>
      </Snackbar>
    </Root>
  );
}
export default Login;
