import React from 'react';
import { AppBar } from '@mui/material';
import './style.css';
import logo from './img/Group 2969.png';
import Studentdetail from '../StudentProfile/StudentProfile';
import Colors from '../../../../utils/Colors';

function ExamOverviewNavbar(props) {
  const { studentProfileData, beforeUnloadListener } = props;
  return (
    <div>
      <AppBar>
        <nav className='studentpageNav' style={{ '--bgcolor': Colors.globalColorThemeColor }}>
          <div className='studentpageLogo'>
            <img src={logo} className='assmentlogo' />
          </div>
          <div className='navbarrightSidediv'>
            <Studentdetail
              studentProfileData={studentProfileData}
              beforeUnloadListener={beforeUnloadListener}
            />
          </div>
        </nav>
      </AppBar>
    </div>
  );
}
export default ExamOverviewNavbar;
